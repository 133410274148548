var  thearea=[
  {
      children: [
          {
              children: [
                  {
                      text: "瑶海区",
                      value: "11201101"
                  },
                  {
                      text: "庐阳区",
                      value: "11201102"
                  },
                  {
                      text: "蜀山区",
                      value: "11201103"
                  },
                  {
                      text: "包河区",
                      value: "11201104"
                  },
                  {
                      text: "长丰县",
                      value: "11201201"
                  },
                  {
                      text: "肥东县",
                      value: "11201202"
                  },
                  {
                      text: "肥西县",
                      value: "11201203"
                  },
                  {
                      text: "庐江县",
                      value: "11201204"
                  },
                  {
                      text: "巢湖市",
                      value: "11201205"
                  }
              ],
              text: "合肥市",
              value: "11201000"
          },
          {
              children: [
                  {
                      text: "镜湖区",
                      value: "11202101"
                  },
                  {
                      text: "弋江区",
                      value: "11202102"
                  },
                  {
                      text: "鸠江区",
                      value: "11202103"
                  },
                  {
                      text: "三山区",
                      value: "11202104"
                  },
                  {
                      text: "芜湖县",
                      value: "11202201"
                  },
                  {
                      text: "繁昌县",
                      value: "11202202"
                  },
                  {
                      text: "南陵县",
                      value: "11202203"
                  },
                  {
                      text: "无为市",
                      value: "11202204"
                  }
              ],
              text: "芜湖市",
              value: "11202000"
          },
          {
              children: [
                  {
                      text: "龙子湖区",
                      value: "11203101"
                  },
                  {
                      text: "蚌山区",
                      value: "11203102"
                  },
                  {
                      text: "禹会区",
                      value: "11203103"
                  },
                  {
                      text: "淮上区",
                      value: "11203104"
                  },
                  {
                      text: "怀远县",
                      value: "11203201"
                  },
                  {
                      text: "五河县",
                      value: "11203202"
                  },
                  {
                      text: "固镇县",
                      value: "11203203"
                  }
              ],
              text: "蚌埠市",
              value: "11203000"
          },
          {
              children: [
                  {
                      text: "大通区",
                      value: "11204101"
                  },
                  {
                      text: "田家庵区",
                      value: "11204102"
                  },
                  {
                      text: "谢家集区",
                      value: "11204103"
                  },
                  {
                      text: "八公山区",
                      value: "11204104"
                  },
                  {
                      text: "潘集区",
                      value: "11204105"
                  },
                  {
                      text: "凤台县",
                      value: "11204201"
                  },
                  {
                      text: "寿县",
                      value: "11204202"
                  }
              ],
              text: "淮南市",
              value: "11204000"
          },
          {
              children: [
                  {
                      text: "花山区",
                      value: "11205102"
                  },
                  {
                      text: "雨山区",
                      value: "11205103"
                  },
                  {
                      text: "当涂县",
                      value: "11205201"
                  },
                  {
                      text: "博望区",
                      value: "11205202"
                  },
                  {
                      text: "含山县",
                      value: "11205203"
                  },
                  {
                      text: "和县",
                      value: "11205204"
                  }
              ],
              text: "马鞍山市",
              value: "11205000"
          },
          {
              children: [
                  {
                      text: "杜集区",
                      value: "11206101"
                  },
                  {
                      text: "相山区",
                      value: "11206102"
                  },
                  {
                      text: "烈山区",
                      value: "11206103"
                  },
                  {
                      text: "濉溪县",
                      value: "11206201"
                  }
              ],
              text: "淮北市",
              value: "11206000"
          },
          {
              children: [
                  {
                      text: "铜官区",
                      value: "11207101"
                  },
                  {
                      text: "狮子山区",
                      value: "11207102"
                  },
                  {
                      text: "郊区",
                      value: "11207103"
                  },
                  {
                      text: "义安区",
                      value: "11207104"
                  },
                  {
                      text: "铜陵县",
                      value: "11207201"
                  },
                  {
                      text: "枞阳县",
                      value: "11207202"
                  }
              ],
              text: "铜陵市",
              value: "11207000"
          },
          {
              children: [
                  {
                      text: "迎江区",
                      value: "11208101"
                  },
                  {
                      text: "大观区",
                      value: "11208102"
                  },
                  {
                      text: "宜秀区",
                      value: "11208103"
                  },
                  {
                      text: "怀宁县",
                      value: "11208201"
                  },
                  {
                      text: "枞阳县",
                      value: "11208202"
                  },
                  {
                      text: "潜山县",
                      value: "11208203"
                  },
                  {
                      text: "太湖县",
                      value: "11208204"
                  },
                  {
                      text: "宿松县",
                      value: "11208205"
                  },
                  {
                      text: "望江县",
                      value: "11208206"
                  },
                  {
                      text: "岳西县",
                      value: "11208207"
                  },
                  {
                      text: "桐城市",
                      value: "11208301"
                  }
              ],
              text: "安庆市",
              value: "11208000"
          },
          {
              children: [
                  {
                      text: "屯溪区",
                      value: "11209101"
                  },
                  {
                      text: "黄山区",
                      value: "11209102"
                  },
                  {
                      text: "徽州区",
                      value: "11209103"
                  },
                  {
                      text: "歙县",
                      value: "11209201"
                  },
                  {
                      text: "休宁县",
                      value: "11209202"
                  },
                  {
                      text: "黟县",
                      value: "11209203"
                  },
                  {
                      text: "祁门县",
                      value: "11209204"
                  }
              ],
              text: "黄山市",
              value: "11209000"
          },
          {
              children: [
                  {
                      text: "琅琊区",
                      value: "11210101"
                  },
                  {
                      text: "南谯区",
                      value: "11210102"
                  },
                  {
                      text: "来安县",
                      value: "11210201"
                  },
                  {
                      text: "全椒县",
                      value: "11210202"
                  },
                  {
                      text: "定远县",
                      value: "11210203"
                  },
                  {
                      text: "凤阳县",
                      value: "11210204"
                  },
                  {
                      text: "天长市",
                      value: "11210301"
                  },
                  {
                      text: "明光市",
                      value: "11210302"
                  }
              ],
              text: "滁州市",
              value: "11210000"
          },
          {
              children: [
                  {
                      text: "颍州区",
                      value: "11211101"
                  },
                  {
                      text: "颍东区",
                      value: "11211102"
                  },
                  {
                      text: "颍泉区",
                      value: "11211103"
                  },
                  {
                      text: "临泉县",
                      value: "11211201"
                  },
                  {
                      text: "太和县",
                      value: "11211202"
                  },
                  {
                      text: "阜南县",
                      value: "11211203"
                  },
                  {
                      text: "颍上县",
                      value: "11211204"
                  },
                  {
                      text: "界首市",
                      value: "11211301"
                  }
              ],
              text: "阜阳市",
              value: "11211000"
          },
          {
              children: [
                  {
                      text: "埇桥区",
                      value: "11212101"
                  },
                  {
                      text: "砀山县",
                      value: "11212201"
                  },
                  {
                      text: "萧县",
                      value: "11212202"
                  },
                  {
                      text: "灵璧县",
                      value: "11212203"
                  },
                  {
                      text: "泗县",
                      value: "11212204"
                  }
              ],
              text: "宿州市",
              value: "11212000"
          },
          {
              children: [
                  {
                      text: "金安区",
                      value: "11214101"
                  },
                  {
                      text: "裕安区",
                      value: "11214102"
                  },
                  {
                      text: "叶集区",
                      value: "11214103"
                  },
                  {
                      text: "寿县",
                      value: "11214201"
                  },
                  {
                      text: "霍邱县",
                      value: "11214202"
                  },
                  {
                      text: "舒城县",
                      value: "11214203"
                  },
                  {
                      text: "金寨县",
                      value: "11214204"
                  },
                  {
                      text: "霍山县",
                      value: "11214205"
                  }
              ],
              text: "六安市",
              value: "11214000"
          },
          {
              children: [
                  {
                      text: "谯城区",
                      value: "11215101"
                  },
                  {
                      text: "涡阳县",
                      value: "11215201"
                  },
                  {
                      text: "蒙城县",
                      value: "11215202"
                  },
                  {
                      text: "利辛县",
                      value: "11215203"
                  }
              ],
              text: "亳州市",
              value: "11215000"
          },
          {
              children: [
                  {
                      text: "贵池区",
                      value: "11216101"
                  },
                  {
                      text: "东至县",
                      value: "11216201"
                  },
                  {
                      text: "石台县",
                      value: "11216202"
                  },
                  {
                      text: "青阳县",
                      value: "11216203"
                  }
              ],
              text: "池州市",
              value: "11216000"
          },
          {
              children: [
                  {
                      text: "宣州区",
                      value: "11217101"
                  },
                  {
                      text: "郎溪县",
                      value: "11217201"
                  },
                  {
                      text: "广德市",
                      value: "11217202"
                  },
                  {
                      text: "泾县",
                      value: "11217203"
                  },
                  {
                      text: "绩溪县",
                      value: "11217204"
                  },
                  {
                      text: "旌德县",
                      value: "11217205"
                  },
                  {
                      text: "宁国市",
                      value: "11217301"
                  }
              ],
              text: "宣城市",
              value: "11217000"
          }
      ],
      text: "安徽省",
      value: "11200000"
  },
  {
      children: [
          {
              text: "大堂区",
              value: "13401000"
          },
          {
              text: "风顺堂区",
              value: "13402000"
          },
          {
              text: "花地玛堂区",
              value: "13403000"
          },
          {
              text: "花王堂区",
              value: "13404000"
          },
          {
              text: "嘉模堂区",
              value: "13405000"
          },
          {
              text: "路凼填海区",
              value: "13406000"
          },
          {
              text: "圣方济各堂区",
              value: "13407000"
          },
          {
              text: "望德堂区",
              value: "13408000"
          }
      ],
      text: "澳门特别行政区",
      value: "13400000"
  },
  {
      children: [
          {
              children: [
                  {
                      text: "东城区",
                      value: "10101101"
                  },
                  {
                      text: "西城区",
                      value: "10101102"
                  },
                  {
                      text: "朝阳区",
                      value: "10101105"
                  },
                  {
                      text: "丰台区",
                      value: "10101106"
                  },
                  {
                      text: "石景山区",
                      value: "10101107"
                  },
                  {
                      text: "海淀区",
                      value: "10101108"
                  },
                  {
                      text: "门头沟区",
                      value: "10101109"
                  },
                  {
                      text: "房山区",
                      value: "10101110"
                  },
                  {
                      text: "通州区",
                      value: "10101111"
                  },
                  {
                      text: "顺义区",
                      value: "10101112"
                  },
                  {
                      text: "昌平区",
                      value: "10101113"
                  },
                  {
                      text: "大兴区",
                      value: "10101114"
                  },
                  {
                      text: "怀柔区",
                      value: "10101115"
                  },
                  {
                      text: "平谷区",
                      value: "10101116"
                  },
                  {
                      text: "密云区",
                      value: "10101117"
                  },
                  {
                      text: "延庆区",
                      value: "10101118"
                  }
              ],
              text: "北京市",
              value: "10000000"
          }
      ],
      text: "北京市",
      value: "10100000"
  },
  {
      children: [
          {
              children: [
                  {
                      text: "万州区",
                      value: "12201101"
                  },
                  {
                      text: "涪陵区",
                      value: "12201102"
                  },
                  {
                      text: "渝中区",
                      value: "12201103"
                  },
                  {
                      text: "大渡口区",
                      value: "12201104"
                  },
                  {
                      text: "江北区",
                      value: "12201105"
                  },
                  {
                      text: "沙坪坝区",
                      value: "12201106"
                  },
                  {
                      text: "九龙坡区",
                      value: "12201107"
                  },
                  {
                      text: "南岸区",
                      value: "12201108"
                  },
                  {
                      text: "北碚区",
                      value: "12201109"
                  },
                  {
                      text: "渝北区",
                      value: "12201112"
                  },
                  {
                      text: "巴南区",
                      value: "12201113"
                  },
                  {
                      text: "黔江区",
                      value: "12201114"
                  },
                  {
                      text: "长寿区",
                      value: "12201115"
                  },
                  {
                      text: "江津区",
                      value: "12201116"
                  },
                  {
                      text: "合川区",
                      value: "12201117"
                  },
                  {
                      text: "永川区",
                      value: "12201118"
                  },
                  {
                      text: "南川区",
                      value: "12201119"
                  },
                  {
                      text: "綦江区",
                      value: "12201201"
                  },
                  {
                      text: "潼南区",
                      value: "12201202"
                  },
                  {
                      text: "铜梁区",
                      value: "12201203"
                  },
                  {
                      text: "大足区",
                      value: "12201204"
                  },
                  {
                      text: "荣昌区",
                      value: "12201205"
                  },
                  {
                      text: "璧山区",
                      value: "12201206"
                  },
                  {
                      text: "梁平区",
                      value: "12201207"
                  },
                  {
                      text: "城口县",
                      value: "12201208"
                  },
                  {
                      text: "丰都县",
                      value: "12201209"
                  },
                  {
                      text: "垫江县",
                      value: "12201210"
                  },
                  {
                      text: "武隆区",
                      value: "12201211"
                  },
                  {
                      text: "忠县",
                      value: "12201212"
                  },
                  {
                      text: "开县",
                      value: "12201213"
                  },
                  {
                      text: "云阳县",
                      value: "12201214"
                  },
                  {
                      text: "奉节县",
                      value: "12201215"
                  },
                  {
                      text: "巫山县",
                      value: "12201216"
                  },
                  {
                      text: "巫溪县",
                      value: "12201217"
                  },
                  {
                      text: "石柱土家族自治县",
                      value: "12201218"
                  },
                  {
                      text: "秀山土家族苗族自治县",
                      value: "12201219"
                  },
                  {
                      text: "酉阳土家族苗族自治县",
                      value: "12201220"
                  },
                  {
                      text: "彭水苗族土家族自治县",
                      value: "12201221"
                  },
                  {
                      text: "开州区",
                      value: "12201222"
                  }
              ],
              text: "重庆市",
              value: "10000000"
          }
      ],
      text: "重庆市",
      value: "12200000"
  },
  {
      children: [
          {
              children: [
                  {
                      text: "鼓楼区",
                      value: "11301101"
                  },
                  {
                      text: "台江区",
                      value: "11301102"
                  },
                  {
                      text: "仓山区",
                      value: "11301103"
                  },
                  {
                      text: "马尾区",
                      value: "11301104"
                  },
                  {
                      text: "晋安区",
                      value: "11301105"
                  },
                  {
                      text: "闽侯县",
                      value: "11301201"
                  },
                  {
                      text: "连江县",
                      value: "11301202"
                  },
                  {
                      text: "罗源县",
                      value: "11301203"
                  },
                  {
                      text: "闽清县",
                      value: "11301204"
                  },
                  {
                      text: "永泰县",
                      value: "11301205"
                  },
                  {
                      text: "平潭县",
                      value: "11301206"
                  },
                  {
                      text: "福清市",
                      value: "11301301"
                  },
                  {
                      text: "长乐区",
                      value: "11301302"
                  }
              ],
              text: "福州市",
              value: "11301000"
          },
          {
              children: [
                  {
                      text: "思明区",
                      value: "11302101"
                  },
                  {
                      text: "海沧区",
                      value: "11302102"
                  },
                  {
                      text: "湖里区",
                      value: "11302103"
                  },
                  {
                      text: "集美区",
                      value: "11302104"
                  },
                  {
                      text: "同安区",
                      value: "11302105"
                  },
                  {
                      text: "翔安区",
                      value: "11302106"
                  }
              ],
              text: "厦门市",
              value: "11302000"
          },
          {
              children: [
                  {
                      text: "城厢区",
                      value: "11303101"
                  },
                  {
                      text: "涵江区",
                      value: "11303102"
                  },
                  {
                      text: "荔城区",
                      value: "11303103"
                  },
                  {
                      text: "秀屿区",
                      value: "11303104"
                  },
                  {
                      text: "仙游县",
                      value: "11303201"
                  }
              ],
              text: "莆田市",
              value: "11303000"
          },
          {
              children: [
                  {
                      text: "梅列区",
                      value: "11304101"
                  },
                  {
                      text: "三元区",
                      value: "11304102"
                  },
                  {
                      text: "明溪县",
                      value: "11304201"
                  },
                  {
                      text: "清流县",
                      value: "11304202"
                  },
                  {
                      text: "宁化县",
                      value: "11304203"
                  },
                  {
                      text: "大田县",
                      value: "11304204"
                  },
                  {
                      text: "尤溪县",
                      value: "11304205"
                  },
                  {
                      text: "沙县",
                      value: "11304206"
                  },
                  {
                      text: "将乐县",
                      value: "11304207"
                  },
                  {
                      text: "泰宁县",
                      value: "11304208"
                  },
                  {
                      text: "建宁县",
                      value: "11304209"
                  },
                  {
                      text: "永安市",
                      value: "11304301"
                  }
              ],
              text: "三明市",
              value: "11304000"
          },
          {
              children: [
                  {
                      text: "鲤城区",
                      value: "11305101"
                  },
                  {
                      text: "丰泽区",
                      value: "11305102"
                  },
                  {
                      text: "洛江区",
                      value: "11305103"
                  },
                  {
                      text: "泉港区",
                      value: "11305104"
                  },
                  {
                      text: "惠安县",
                      value: "11305201"
                  },
                  {
                      text: "安溪县",
                      value: "11305202"
                  },
                  {
                      text: "永春县",
                      value: "11305203"
                  },
                  {
                      text: "德化县",
                      value: "11305204"
                  },
                  {
                      text: "金门县",
                      value: "11305205"
                  },
                  {
                      text: "石狮市",
                      value: "11305301"
                  },
                  {
                      text: "晋江市",
                      value: "11305302"
                  },
                  {
                      text: "南安市",
                      value: "11305303"
                  }
              ],
              text: "泉州市",
              value: "11305000"
          },
          {
              children: [
                  {
                      text: "芗城区",
                      value: "11306101"
                  },
                  {
                      text: "龙文区",
                      value: "11306102"
                  },
                  {
                      text: "云霄县",
                      value: "11306201"
                  },
                  {
                      text: "漳浦县",
                      value: "11306202"
                  },
                  {
                      text: "诏安县",
                      value: "11306203"
                  },
                  {
                      text: "长泰县",
                      value: "11306204"
                  },
                  {
                      text: "东山县",
                      value: "11306205"
                  },
                  {
                      text: "南靖县",
                      value: "11306206"
                  },
                  {
                      text: "平和县",
                      value: "11306207"
                  },
                  {
                      text: "华安县",
                      value: "11306208"
                  },
                  {
                      text: "龙海市",
                      value: "11306301"
                  }
              ],
              text: "漳州市",
              value: "11306000"
          },
          {
              children: [
                  {
                      text: "延平区",
                      value: "11307101"
                  },
                  {
                      text: "顺昌县",
                      value: "11307201"
                  },
                  {
                      text: "浦城县",
                      value: "11307202"
                  },
                  {
                      text: "光泽县",
                      value: "11307203"
                  },
                  {
                      text: "松溪县",
                      value: "11307204"
                  },
                  {
                      text: "政和县",
                      value: "11307205"
                  },
                  {
                      text: "邵武市",
                      value: "11307301"
                  },
                  {
                      text: "武夷山市",
                      value: "11307302"
                  },
                  {
                      text: "建瓯市",
                      value: "11307303"
                  },
                  {
                      text: "建阳区",
                      value: "11307304"
                  }
              ],
              text: "南平市",
              value: "11307000"
          },
          {
              children: [
                  {
                      text: "新罗区",
                      value: "11308101"
                  },
                  {
                      text: "长汀县",
                      value: "11308201"
                  },
                  {
                      text: "永定区",
                      value: "11308202"
                  },
                  {
                      text: "上杭县",
                      value: "11308203"
                  },
                  {
                      text: "武平县",
                      value: "11308204"
                  },
                  {
                      text: "连城县",
                      value: "11308205"
                  },
                  {
                      text: "漳平市",
                      value: "11308301"
                  }
              ],
              text: "龙岩市",
              value: "11308000"
          },
          {
              children: [
                  {
                      text: "蕉城区",
                      value: "11309101"
                  },
                  {
                      text: "霞浦县",
                      value: "11309201"
                  },
                  {
                      text: "古田县",
                      value: "11309202"
                  },
                  {
                      text: "屏南县",
                      value: "11309203"
                  },
                  {
                      text: "寿宁县",
                      value: "11309204"
                  },
                  {
                      text: "周宁县",
                      value: "11309205"
                  },
                  {
                      text: "柘荣县",
                      value: "11309206"
                  },
                  {
                      text: "福安市",
                      value: "11309301"
                  },
                  {
                      text: "福鼎市",
                      value: "11309302"
                  }
              ],
              text: "宁德市",
              value: "11309000"
          }
      ],
      text: "福建省",
      value: "11300000"
  },
  {
      children: [
          {
              children: [
                  {
                      text: "荔湾区",
                      value: "11901101"
                  },
                  {
                      text: "越秀区",
                      value: "11901102"
                  },
                  {
                      text: "海珠区",
                      value: "11901103"
                  },
                  {
                      text: "天河区",
                      value: "11901104"
                  },
                  {
                      text: "白云区",
                      value: "11901105"
                  },
                  {
                      text: "黄埔区",
                      value: "11901106"
                  },
                  {
                      text: "番禺区",
                      value: "11901107"
                  },
                  {
                      text: "花都区",
                      value: "11901108"
                  },
                  {
                      text: "南沙区",
                      value: "11901109"
                  },
                  {
                      text: "增城区",
                      value: "11901301"
                  },
                  {
                      text: "从化区",
                      value: "11901302"
                  }
              ],
              text: "广州市",
              value: "11901000"
          },
          {
              children: [
                  {
                      text: "武江区",
                      value: "11902101"
                  },
                  {
                      text: "浈江区",
                      value: "11902102"
                  },
                  {
                      text: "曲江区",
                      value: "11902103"
                  },
                  {
                      text: "始兴县",
                      value: "11902201"
                  },
                  {
                      text: "仁化县",
                      value: "11902202"
                  },
                  {
                      text: "翁源县",
                      value: "11902203"
                  },
                  {
                      text: "乳源瑶族自治县",
                      value: "11902204"
                  },
                  {
                      text: "新丰县",
                      value: "11902205"
                  },
                  {
                      text: "乐昌市",
                      value: "11902301"
                  },
                  {
                      text: "南雄市",
                      value: "11902302"
                  }
              ],
              text: "韶关市",
              value: "11902000"
          },
          {
              children: [
                  {
                      text: "罗湖区",
                      value: "11903101"
                  },
                  {
                      text: "福田区",
                      value: "11903102"
                  },
                  {
                      text: "南山区",
                      value: "11903103"
                  },
                  {
                      text: "宝安区",
                      value: "11903104"
                  },
                  {
                      text: "龙岗区",
                      value: "11903105"
                  },
                  {
                      text: "盐田区",
                      value: "11903106"
                  },
                  {
                      text: "龙华区",
                      value: "11903107"
                  },
                  {
                      text: "光明区",
                      value: "11903108"
                  },
                  {
                      text: "坪山区",
                      value: "11909304"
                  }
              ],
              text: "深圳市",
              value: "11903000"
          },
          {
              children: [
                  {
                      text: "香洲区",
                      value: "11904101"
                  },
                  {
                      text: "斗门区",
                      value: "11904102"
                  },
                  {
                      text: "金湾区",
                      value: "11904103"
                  }
              ],
              text: "珠海市",
              value: "11904000"
          },
          {
              children: [
                  {
                      text: "龙湖区",
                      value: "11905101"
                  },
                  {
                      text: "金平区",
                      value: "11905102"
                  },
                  {
                      text: "濠江区",
                      value: "11905103"
                  },
                  {
                      text: "潮阳区",
                      value: "11905104"
                  },
                  {
                      text: "潮南区",
                      value: "11905105"
                  },
                  {
                      text: "澄海区",
                      value: "11905107"
                  },
                  {
                      text: "南澳县",
                      value: "11905201"
                  }
              ],
              text: "汕头市",
              value: "11905000"
          },
          {
              children: [
                  {
                      text: "禅城区",
                      value: "11906101"
                  },
                  {
                      text: "南海区",
                      value: "11906102"
                  },
                  {
                      text: "顺德区",
                      value: "11906103"
                  },
                  {
                      text: "三水区",
                      value: "11906104"
                  },
                  {
                      text: "高明区",
                      value: "11906105"
                  }
              ],
              text: "佛山市",
              value: "11906000"
          },
          {
              children: [
                  {
                      text: "蓬江区",
                      value: "11907101"
                  },
                  {
                      text: "江海区",
                      value: "11907102"
                  },
                  {
                      text: "新会区",
                      value: "11907103"
                  },
                  {
                      text: "台山市",
                      value: "11907301"
                  },
                  {
                      text: "开平市",
                      value: "11907302"
                  },
                  {
                      text: "鹤山市",
                      value: "11907303"
                  },
                  {
                      text: "恩平市",
                      value: "11907304"
                  }
              ],
              text: "江门市",
              value: "11907000"
          },
          {
              children: [
                  {
                      text: "赤坎区",
                      value: "11908101"
                  },
                  {
                      text: "霞山区",
                      value: "11908102"
                  },
                  {
                      text: "坡头区",
                      value: "11908103"
                  },
                  {
                      text: "麻章区",
                      value: "11908104"
                  },
                  {
                      text: "遂溪县",
                      value: "11908201"
                  },
                  {
                      text: "徐闻县",
                      value: "11908202"
                  },
                  {
                      text: "廉江市",
                      value: "11908301"
                  },
                  {
                      text: "雷州市",
                      value: "11908302"
                  },
                  {
                      text: "吴川市",
                      value: "11908303"
                  }
              ],
              text: "湛江市",
              value: "11908000"
          },
          {
              children: [
                  {
                      text: "茂南区",
                      value: "11909101"
                  },
                  {
                      text: "电白区",
                      value: "11909103"
                  },
                  {
                      text: "高州市",
                      value: "11909301"
                  },
                  {
                      text: "化州市",
                      value: "11909302"
                  },
                  {
                      text: "信宜市",
                      value: "11909303"
                  }
              ],
              text: "茂名市",
              value: "11909000"
          },
          {
              children: [
                  {
                      text: "端州区",
                      value: "11910101"
                  },
                  {
                      text: "鼎湖区",
                      value: "11910102"
                  },
                  {
                      text: "广宁县",
                      value: "11910201"
                  },
                  {
                      text: "怀集县",
                      value: "11910202"
                  },
                  {
                      text: "封开县",
                      value: "11910203"
                  },
                  {
                      text: "德庆县",
                      value: "11910204"
                  },
                  {
                      text: "高要区",
                      value: "11910301"
                  },
                  {
                      text: "四会市",
                      value: "11910302"
                  }
              ],
              text: "肇庆市",
              value: "11910000"
          },
          {
              children: [
                  {
                      text: "惠城区",
                      value: "11911101"
                  },
                  {
                      text: "惠阳区",
                      value: "11911102"
                  },
                  {
                      text: "博罗县",
                      value: "11911201"
                  },
                  {
                      text: "惠东县",
                      value: "11911202"
                  },
                  {
                      text: "龙门县",
                      value: "11911203"
                  }
              ],
              text: "惠州市",
              value: "11911000"
          },
          {
              children: [
                  {
                      text: "梅江区",
                      value: "11912101"
                  },
                  {
                      text: "梅县区",
                      value: "11912201"
                  },
                  {
                      text: "大埔县",
                      value: "11912202"
                  },
                  {
                      text: "丰顺县",
                      value: "11912203"
                  },
                  {
                      text: "五华县",
                      value: "11912204"
                  },
                  {
                      text: "平远县",
                      value: "11912205"
                  },
                  {
                      text: "蕉岭县",
                      value: "11912206"
                  },
                  {
                      text: "兴宁市",
                      value: "11912301"
                  }
              ],
              text: "梅州市",
              value: "11912000"
          },
          {
              children: [
                  {
                      text: "城区",
                      value: "11913101"
                  },
                  {
                      text: "海丰县",
                      value: "11913201"
                  },
                  {
                      text: "陆河县",
                      value: "11913202"
                  },
                  {
                      text: "陆丰市",
                      value: "11913301"
                  }
              ],
              text: "汕尾市",
              value: "11913000"
          },
          {
              children: [
                  {
                      text: "源城区",
                      value: "11914101"
                  },
                  {
                      text: "紫金县",
                      value: "11914201"
                  },
                  {
                      text: "龙川县",
                      value: "11914202"
                  },
                  {
                      text: "连平县",
                      value: "11914203"
                  },
                  {
                      text: "和平县",
                      value: "11914204"
                  },
                  {
                      text: "东源县",
                      value: "11914205"
                  }
              ],
              text: "河源市",
              value: "11914000"
          },
          {
              children: [
                  {
                      text: "江城区",
                      value: "11915101"
                  },
                  {
                      text: "阳西县",
                      value: "11915201"
                  },
                  {
                      text: "阳东区",
                      value: "11915202"
                  },
                  {
                      text: "阳春市",
                      value: "11915301"
                  }
              ],
              text: "阳江市",
              value: "11915000"
          },
          {
              children: [
                  {
                      text: "清城区",
                      value: "11916101"
                  },
                  {
                      text: "佛冈县",
                      value: "11916201"
                  },
                  {
                      text: "阳山县",
                      value: "11916202"
                  },
                  {
                      text: "连山壮族瑶族自治县",
                      value: "11916203"
                  },
                  {
                      text: "连南瑶族自治县",
                      value: "11916204"
                  },
                  {
                      text: "清新区",
                      value: "11916205"
                  },
                  {
                      text: "英德市",
                      value: "11916301"
                  },
                  {
                      text: "连州市",
                      value: "11916302"
                  }
              ],
              text: "清远市",
              value: "11916000"
          },
          {
              children: [
                  {
                      text: "东宝路",
                      value: "11917101"
                  },
                  {
                      text: "花园新村",
                      value: "11917102"
                  },
                  {
                      text: "三元里",
                      value: "11917103"
                  }
              ],
              text: "东莞市",
              value: "11917000"
          },
          {
              children: [
                  {
                      text: "竹苑",
                      value: "11918101"
                  },
                  {
                      text: "湖滨北路",
                      value: "11918102"
                  }
              ],
              text: "中山市",
              value: "11918000"
          },
          {
              children: [
                  {
                      text: "湘桥区",
                      value: "11919101"
                  },
                  {
                      text: "潮安区",
                      value: "11919201"
                  },
                  {
                      text: "饶平县",
                      value: "11919202"
                  }
              ],
              text: "潮州市",
              value: "11919000"
          },
          {
              children: [
                  {
                      text: "榕城区",
                      value: "11920101"
                  },
                  {
                      text: "揭东区",
                      value: "11920201"
                  },
                  {
                      text: "揭西县",
                      value: "11920202"
                  },
                  {
                      text: "惠来县",
                      value: "11920203"
                  },
                  {
                      text: "普宁市",
                      value: "11920301"
                  }
              ],
              text: "揭阳市",
              value: "11920000"
          },
          {
              children: [
                  {
                      text: "云城区",
                      value: "11921101"
                  },
                  {
                      text: "新兴县",
                      value: "11921201"
                  },
                  {
                      text: "郁南县",
                      value: "11921202"
                  },
                  {
                      text: "云安区",
                      value: "11921203"
                  },
                  {
                      text: "罗定市",
                      value: "11921301"
                  }
              ],
              text: "云浮市",
              value: "11921000"
          }
      ],
      text: "广东省",
      value: "11900000"
  },
  {
      children: [
          {
              children: [
                  {
                      text: "城关区",
                      value: "12801101"
                  },
                  {
                      text: "七里河区",
                      value: "12801102"
                  },
                  {
                      text: "西固区",
                      value: "12801103"
                  },
                  {
                      text: "安宁区",
                      value: "12801104"
                  },
                  {
                      text: "红古区",
                      value: "12801105"
                  },
                  {
                      text: "永登县",
                      value: "12801201"
                  },
                  {
                      text: "皋兰县",
                      value: "12801202"
                  },
                  {
                      text: "榆中县",
                      value: "12801203"
                  }
              ],
              text: "兰州市",
              value: "12801000"
          },
          {
              text: "嘉峪关市",
              value: "12802000"
          },
          {
              children: [
                  {
                      text: "金川区",
                      value: "12803101"
                  },
                  {
                      text: "永昌县",
                      value: "12803201"
                  }
              ],
              text: "金昌市",
              value: "12803000"
          },
          {
              children: [
                  {
                      text: "白银区",
                      value: "12804101"
                  },
                  {
                      text: "平川区",
                      value: "12804102"
                  },
                  {
                      text: "靖远县",
                      value: "12804201"
                  },
                  {
                      text: "会宁县",
                      value: "12804202"
                  },
                  {
                      text: "景泰县",
                      value: "12804203"
                  }
              ],
              text: "白银市",
              value: "12804000"
          },
          {
              children: [
                  {
                      text: "秦州区",
                      value: "12805101"
                  },
                  {
                      text: "麦积区",
                      value: "12805102"
                  },
                  {
                      text: "清水县",
                      value: "12805201"
                  },
                  {
                      text: "秦安县",
                      value: "12805202"
                  },
                  {
                      text: "甘谷县",
                      value: "12805203"
                  },
                  {
                      text: "武山县",
                      value: "12805204"
                  },
                  {
                      text: "张家川回族自治县",
                      value: "12805205"
                  }
              ],
              text: "天水市",
              value: "12805000"
          },
          {
              children: [
                  {
                      text: "凉州区",
                      value: "12806101"
                  },
                  {
                      text: "民勤县",
                      value: "12806201"
                  },
                  {
                      text: "古浪县",
                      value: "12806202"
                  },
                  {
                      text: "天祝藏族自治县",
                      value: "12806203"
                  }
              ],
              text: "武威市",
              value: "12806000"
          },
          {
              children: [
                  {
                      text: "甘州区",
                      value: "12807101"
                  },
                  {
                      text: "肃南裕固族自治县",
                      value: "12807201"
                  },
                  {
                      text: "民乐县",
                      value: "12807202"
                  },
                  {
                      text: "临泽县",
                      value: "12807203"
                  },
                  {
                      text: "高台县",
                      value: "12807204"
                  },
                  {
                      text: "山丹县",
                      value: "12807205"
                  }
              ],
              text: "张掖市",
              value: "12807000"
          },
          {
              children: [
                  {
                      text: "崆峒区",
                      value: "12808101"
                  },
                  {
                      text: "泾川县",
                      value: "12808201"
                  },
                  {
                      text: "灵台县",
                      value: "12808202"
                  },
                  {
                      text: "崇信县",
                      value: "12808203"
                  },
                  {
                      text: "华亭市",
                      value: "12808204"
                  },
                  {
                      text: "庄浪县",
                      value: "12808205"
                  },
                  {
                      text: "静宁县",
                      value: "12808206"
                  }
              ],
              text: "平凉市",
              value: "12808000"
          },
          {
              children: [
                  {
                      text: "肃州区",
                      value: "12809101"
                  },
                  {
                      text: "金塔县",
                      value: "12809201"
                  },
                  {
                      text: "瓜州县",
                      value: "12809202"
                  },
                  {
                      text: "肃北蒙古族自治县",
                      value: "12809203"
                  },
                  {
                      text: "阿克塞哈萨克族自治县",
                      value: "12809204"
                  },
                  {
                      text: "玉门市",
                      value: "12809301"
                  },
                  {
                      text: "敦煌市",
                      value: "12809302"
                  }
              ],
              text: "酒泉市",
              value: "12809000"
          },
          {
              children: [
                  {
                      text: "西峰区",
                      value: "12810101"
                  },
                  {
                      text: "庆城县",
                      value: "12810201"
                  },
                  {
                      text: "环县",
                      value: "12810202"
                  },
                  {
                      text: "华池县",
                      value: "12810203"
                  },
                  {
                      text: "合水县",
                      value: "12810204"
                  },
                  {
                      text: "正宁县",
                      value: "12810205"
                  },
                  {
                      text: "宁县",
                      value: "12810206"
                  },
                  {
                      text: "镇原县",
                      value: "12810207"
                  }
              ],
              text: "庆阳市",
              value: "12810000"
          },
          {
              children: [
                  {
                      text: "安定区",
                      value: "12811101"
                  },
                  {
                      text: "通渭县",
                      value: "12811201"
                  },
                  {
                      text: "陇西县",
                      value: "12811202"
                  },
                  {
                      text: "渭源县",
                      value: "12811203"
                  },
                  {
                      text: "临洮县",
                      value: "12811204"
                  },
                  {
                      text: "漳县",
                      value: "12811205"
                  },
                  {
                      text: "岷县",
                      value: "12811206"
                  }
              ],
              text: "定西市",
              value: "12811000"
          },
          {
              children: [
                  {
                      text: "武都区",
                      value: "12812101"
                  },
                  {
                      text: "成县",
                      value: "12812201"
                  },
                  {
                      text: "文县",
                      value: "12812202"
                  },
                  {
                      text: "宕昌县",
                      value: "12812203"
                  },
                  {
                      text: "康县",
                      value: "12812204"
                  },
                  {
                      text: "西和县",
                      value: "12812205"
                  },
                  {
                      text: "礼县",
                      value: "12812206"
                  },
                  {
                      text: "徽县",
                      value: "12812207"
                  },
                  {
                      text: "两当县",
                      value: "12812208"
                  }
              ],
              text: "陇南市",
              value: "12812000"
          },
          {
              children: [
                  {
                      text: "临夏县",
                      value: "12813201"
                  },
                  {
                      text: "康乐县",
                      value: "12813202"
                  },
                  {
                      text: "永靖县",
                      value: "12813203"
                  },
                  {
                      text: "广河县",
                      value: "12813204"
                  },
                  {
                      text: "和政县",
                      value: "12813205"
                  },
                  {
                      text: "东乡族自治县",
                      value: "12813206"
                  },
                  {
                      text: "积石山保安族东乡族撒拉族自治县",
                      value: "12813207"
                  },
                  {
                      text: "临夏市",
                      value: "12813301"
                  }
              ],
              text: "临夏回族自治州",
              value: "12813000"
          },
          {
              children: [
                  {
                      text: "临潭县",
                      value: "12814201"
                  },
                  {
                      text: "卓尼县",
                      value: "12814202"
                  },
                  {
                      text: "舟曲县",
                      value: "12814203"
                  },
                  {
                      text: "迭部县",
                      value: "12814204"
                  },
                  {
                      text: "玛曲县",
                      value: "12814205"
                  },
                  {
                      text: "碌曲县",
                      value: "12814206"
                  },
                  {
                      text: "夏河县",
                      value: "12814207"
                  },
                  {
                      text: "合作市",
                      value: "12814301"
                  }
              ],
              text: "甘南藏族自治州",
              value: "12814000"
          }
      ],
      text: "甘肃省",
      value: "12800000"
  },
  {
      children: [
          {
              children: [
                  {
                      text: "兴宁区",
                      value: "12001101"
                  },
                  {
                      text: "青秀区",
                      value: "12001102"
                  },
                  {
                      text: "江南区",
                      value: "12001103"
                  },
                  {
                      text: "西乡塘区",
                      value: "12001104"
                  },
                  {
                      text: "良庆区",
                      value: "12001105"
                  },
                  {
                      text: "邕宁区",
                      value: "12001106"
                  },
                  {
                      text: "武鸣区",
                      value: "12001201"
                  },
                  {
                      text: "隆安县",
                      value: "12001202"
                  },
                  {
                      text: "马山县",
                      value: "12001203"
                  },
                  {
                      text: "上林县",
                      value: "12001204"
                  },
                  {
                      text: "宾阳县",
                      value: "12001205"
                  },
                  {
                      text: "横县",
                      value: "12001206"
                  }
              ],
              text: "南宁市",
              value: "12001000"
          },
          {
              children: [
                  {
                      text: "城中区",
                      value: "12002101"
                  },
                  {
                      text: "鱼峰区",
                      value: "12002102"
                  },
                  {
                      text: "柳南区",
                      value: "12002103"
                  },
                  {
                      text: "柳北区",
                      value: "12002104"
                  },
                  {
                      text: "柳江区",
                      value: "12002105"
                  },
                  {
                      text: "柳城县",
                      value: "12002202"
                  },
                  {
                      text: "鹿寨县",
                      value: "12002203"
                  },
                  {
                      text: "融安县",
                      value: "12002204"
                  },
                  {
                      text: "融水苗族自治县",
                      value: "12002205"
                  },
                  {
                      text: "三江侗族自治县",
                      value: "12002206"
                  }
              ],
              text: "柳州市",
              value: "12002000"
          },
          {
              children: [
                  {
                      text: "秀峰区",
                      value: "12003101"
                  },
                  {
                      text: "叠彩区",
                      value: "12003102"
                  },
                  {
                      text: "象山区",
                      value: "12003103"
                  },
                  {
                      text: "七星区",
                      value: "12003104"
                  },
                  {
                      text: "雁山区",
                      value: "12003105"
                  },
                  {
                      text: "阳朔县",
                      value: "12003201"
                  },
                  {
                      text: "临桂区",
                      value: "12003202"
                  },
                  {
                      text: "灵川县",
                      value: "12003203"
                  },
                  {
                      text: "全州县",
                      value: "12003204"
                  },
                  {
                      text: "兴安县",
                      value: "12003205"
                  },
                  {
                      text: "永福县",
                      value: "12003206"
                  },
                  {
                      text: "灌阳县",
                      value: "12003207"
                  },
                  {
                      text: "龙胜各族自治县",
                      value: "12003208"
                  },
                  {
                      text: "资源县",
                      value: "12003209"
                  },
                  {
                      text: "平乐县",
                      value: "12003210"
                  },
                  {
                      text: "荔浦市",
                      value: "12003211"
                  },
                  {
                      text: "恭城瑶族自治县",
                      value: "12003212"
                  }
              ],
              text: "桂林市",
              value: "12003000"
          },
          {
              children: [
                  {
                      text: "万秀区",
                      value: "12004101"
                  },
                  {
                      text: "龙圩区",
                      value: "12004102"
                  },
                  {
                      text: "长洲区",
                      value: "12004103"
                  },
                  {
                      text: "苍梧县",
                      value: "12004201"
                  },
                  {
                      text: "藤县",
                      value: "12004202"
                  },
                  {
                      text: "蒙山县",
                      value: "12004203"
                  },
                  {
                      text: "岑溪市",
                      value: "12004301"
                  }
              ],
              text: "梧州市",
              value: "12004000"
          },
          {
              children: [
                  {
                      text: "海城区",
                      value: "12005101"
                  },
                  {
                      text: "银海区",
                      value: "12005102"
                  },
                  {
                      text: "铁山港区",
                      value: "12005103"
                  },
                  {
                      text: "合浦县",
                      value: "12005201"
                  }
              ],
              text: "北海市",
              value: "12005000"
          },
          {
              children: [
                  {
                      text: "港口区",
                      value: "12006101"
                  },
                  {
                      text: "防城区",
                      value: "12006102"
                  },
                  {
                      text: "上思县",
                      value: "12006201"
                  },
                  {
                      text: "东兴市",
                      value: "12006301"
                  }
              ],
              text: "防城港市",
              value: "12006000"
          },
          {
              children: [
                  {
                      text: "钦南区",
                      value: "12007101"
                  },
                  {
                      text: "钦北区",
                      value: "12007102"
                  },
                  {
                      text: "灵山县",
                      value: "12007201"
                  },
                  {
                      text: "浦北县",
                      value: "12007202"
                  }
              ],
              text: "钦州市",
              value: "12007000"
          },
          {
              children: [
                  {
                      text: "港北区",
                      value: "12008101"
                  },
                  {
                      text: "港南区",
                      value: "12008102"
                  },
                  {
                      text: "覃塘区",
                      value: "12008103"
                  },
                  {
                      text: "平南县",
                      value: "12008201"
                  },
                  {
                      text: "桂平市",
                      value: "12008301"
                  }
              ],
              text: "贵港市",
              value: "12008000"
          },
          {
              children: [
                  {
                      text: "玉州区",
                      value: "12009101"
                  },
                  {
                      text: "福绵区",
                      value: "12009102"
                  },
                  {
                      text: "容县",
                      value: "12009201"
                  },
                  {
                      text: "陆川县",
                      value: "12009202"
                  },
                  {
                      text: "博白县",
                      value: "12009203"
                  },
                  {
                      text: "兴业县",
                      value: "12009204"
                  },
                  {
                      text: "北流市",
                      value: "12009301"
                  }
              ],
              text: "玉林市",
              value: "12009000"
          },
          {
              children: [
                  {
                      text: "右江区",
                      value: "12010101"
                  },
                  {
                      text: "田阳县",
                      value: "12010201"
                  },
                  {
                      text: "田东县",
                      value: "12010202"
                  },
                  {
                      text: "平果县",
                      value: "12010203"
                  },
                  {
                      text: "德保县",
                      value: "12010204"
                  },
                  {
                      text: "那坡县",
                      value: "12010206"
                  },
                  {
                      text: "凌云县",
                      value: "12010207"
                  },
                  {
                      text: "乐业县",
                      value: "12010208"
                  },
                  {
                      text: "田林县",
                      value: "12010209"
                  },
                  {
                      text: "西林县",
                      value: "12010210"
                  },
                  {
                      text: "隆林各族自治县",
                      value: "12010211"
                  },
                  {
                      text: "靖西市",
                      value: "12010301"
                  }
              ],
              text: "百色市",
              value: "12010000"
          },
          {
              children: [
                  {
                      text: "八步区",
                      value: "12011101"
                  },
                  {
                      text: "昭平县",
                      value: "12011201"
                  },
                  {
                      text: "钟山县",
                      value: "12011202"
                  },
                  {
                      text: "富川瑶族自治县",
                      value: "12011203"
                  }
              ],
              text: "贺州市",
              value: "12011000"
          },
          {
              children: [
                  {
                      text: "金城江区",
                      value: "12012101"
                  },
                  {
                      text: "南丹县",
                      value: "12012201"
                  },
                  {
                      text: "天峨县",
                      value: "12012202"
                  },
                  {
                      text: "凤山县",
                      value: "12012203"
                  },
                  {
                      text: "东兰县",
                      value: "12012204"
                  },
                  {
                      text: "罗城仫佬族自治县",
                      value: "12012205"
                  },
                  {
                      text: "环江毛南族自治县",
                      value: "12012206"
                  },
                  {
                      text: "巴马瑶族自治县",
                      value: "12012207"
                  },
                  {
                      text: "都安瑶族自治县",
                      value: "12012208"
                  },
                  {
                      text: "大化瑶族自治县",
                      value: "12012209"
                  },
                  {
                      text: "宜州市",
                      value: "12012301"
                  }
              ],
              text: "河池市",
              value: "12012000"
          },
          {
              children: [
                  {
                      text: "兴宾区",
                      value: "12013101"
                  },
                  {
                      text: "忻城县",
                      value: "12013201"
                  },
                  {
                      text: "象州县",
                      value: "12013202"
                  },
                  {
                      text: "武宣县",
                      value: "12013203"
                  },
                  {
                      text: "金秀瑶族自治县",
                      value: "12013204"
                  },
                  {
                      text: "合山市",
                      value: "12013301"
                  }
              ],
              text: "来宾市",
              value: "12013000"
          },
          {
              children: [
                  {
                      text: "江州区",
                      value: "12014101"
                  },
                  {
                      text: "扶绥县",
                      value: "12014201"
                  },
                  {
                      text: "宁明县",
                      value: "12014202"
                  },
                  {
                      text: "龙州县",
                      value: "12014203"
                  },
                  {
                      text: "大新县",
                      value: "12014204"
                  },
                  {
                      text: "天等县",
                      value: "12014205"
                  },
                  {
                      text: "凭祥市",
                      value: "12014301"
                  }
              ],
              text: "崇左市",
              value: "12014000"
          }
      ],
      text: "广西壮族自治区",
      value: "12000000"
  },
  {
      children: [
          {
              children: [
                  {
                      text: "南明区",
                      value: "12401101"
                  },
                  {
                      text: "云岩区",
                      value: "12401102"
                  },
                  {
                      text: "花溪区",
                      value: "12401103"
                  },
                  {
                      text: "乌当区",
                      value: "12401104"
                  },
                  {
                      text: "白云区",
                      value: "12401105"
                  },
                  {
                      text: "观山湖区",
                      value: "12401106"
                  },
                  {
                      text: "开阳县",
                      value: "12401201"
                  },
                  {
                      text: "息烽县",
                      value: "12401202"
                  },
                  {
                      text: "修文县",
                      value: "12401203"
                  },
                  {
                      text: "清镇市",
                      value: "12401301"
                  }
              ],
              text: "贵阳市",
              value: "12401000"
          },
          {
              children: [
                  {
                      text: "钟山区",
                      value: "12402101"
                  },
                  {
                      text: "六枝特区",
                      value: "12402102"
                  },
                  {
                      text: "水城县",
                      value: "12402201"
                  },
                  {
                      text: "盘州市",
                      value: "12402202"
                  }
              ],
              text: "六盘水市",
              value: "12402000"
          },
          {
              children: [
                  {
                      text: "红花岗区",
                      value: "12403101"
                  },
                  {
                      text: "汇川区",
                      value: "12403102"
                  },
                  {
                      text: "播州区",
                      value: "12403103"
                  },
                  {
                      text: "遵义县",
                      value: "12403201"
                  },
                  {
                      text: "桐梓县",
                      value: "12403202"
                  },
                  {
                      text: "绥阳县",
                      value: "12403203"
                  },
                  {
                      text: "正安县",
                      value: "12403204"
                  },
                  {
                      text: "道真仡佬族苗族自治县",
                      value: "12403205"
                  },
                  {
                      text: "务川仡佬族苗族自治县",
                      value: "12403206"
                  },
                  {
                      text: "凤冈县",
                      value: "12403207"
                  },
                  {
                      text: "湄潭县",
                      value: "12403208"
                  },
                  {
                      text: "余庆县",
                      value: "12403209"
                  },
                  {
                      text: "习水县",
                      value: "12403210"
                  },
                  {
                      text: "赤水市",
                      value: "12403301"
                  },
                  {
                      text: "仁怀市",
                      value: "12403302"
                  }
              ],
              text: "遵义市",
              value: "12403000"
          },
          {
              children: [
                  {
                      text: "西秀区",
                      value: "12404101"
                  },
                  {
                      text: "平坝区",
                      value: "12404201"
                  },
                  {
                      text: "普定县",
                      value: "12404202"
                  },
                  {
                      text: "镇宁布依族苗族自治县",
                      value: "12404203"
                  },
                  {
                      text: "关岭布依族苗族自治县",
                      value: "12404204"
                  },
                  {
                      text: "紫云苗族布依族自治县",
                      value: "12404205"
                  }
              ],
              text: "安顺市",
              value: "12404000"
          },
          {
              children: [
                  {
                      text: "江口县",
                      value: "12405201"
                  },
                  {
                      text: "玉屏侗族自治县",
                      value: "12405202"
                  },
                  {
                      text: "石阡县",
                      value: "12405203"
                  },
                  {
                      text: "思南县",
                      value: "12405204"
                  },
                  {
                      text: "印江土家族苗族自治县",
                      value: "12405205"
                  },
                  {
                      text: "德江县",
                      value: "12405206"
                  },
                  {
                      text: "沿河土家族自治县",
                      value: "12405207"
                  },
                  {
                      text: "松桃苗族自治县",
                      value: "12405208"
                  },
                  {
                      text: "万山区",
                      value: "12405209"
                  },
                  {
                      text: "碧江区",
                      value: "12405301"
                  }
              ],
              text: "铜仁市",
              value: "12405000"
          },
          {
              children: [
                  {
                      text: "兴仁市",
                      value: "12406101"
                  },
                  {
                      text: "普安县",
                      value: "12406102"
                  },
                  {
                      text: "晴隆县",
                      value: "12406103"
                  },
                  {
                      text: "贞丰县",
                      value: "12406104"
                  },
                  {
                      text: "望谟县",
                      value: "12406105"
                  },
                  {
                      text: "册亨县",
                      value: "12406106"
                  },
                  {
                      text: "安龙县",
                      value: "12406107"
                  },
                  {
                      text: "兴义市",
                      value: "12406301"
                  }
              ],
              text: "黔西南布依族苗族自治州",
              value: "12406000"
          },
          {
              children: [
                  {
                      text: "大方县",
                      value: "12407201"
                  },
                  {
                      text: "黔西县",
                      value: "12407202"
                  },
                  {
                      text: "金沙县",
                      value: "12407203"
                  },
                  {
                      text: "织金县",
                      value: "12407204"
                  },
                  {
                      text: "纳雍县",
                      value: "12407205"
                  },
                  {
                      text: "威宁彝族回族苗族自治县",
                      value: "12407206"
                  },
                  {
                      text: "赫章县",
                      value: "12407207"
                  },
                  {
                      text: "七星关区",
                      value: "12407301"
                  }
              ],
              text: "毕节市",
              value: "12407000"
          },
          {
              children: [
                  {
                      text: "黄平县",
                      value: "12408101"
                  },
                  {
                      text: "施秉县",
                      value: "12408102"
                  },
                  {
                      text: "三穗县",
                      value: "12408103"
                  },
                  {
                      text: "镇远县",
                      value: "12408104"
                  },
                  {
                      text: "岑巩县",
                      value: "12408105"
                  },
                  {
                      text: "天柱县",
                      value: "12408106"
                  },
                  {
                      text: "锦屏县",
                      value: "12408107"
                  },
                  {
                      text: "剑河县",
                      value: "12408108"
                  },
                  {
                      text: "台江县",
                      value: "12408109"
                  },
                  {
                      text: "黎平县",
                      value: "12408110"
                  },
                  {
                      text: "榕江县",
                      value: "12408111"
                  },
                  {
                      text: "从江县",
                      value: "12408112"
                  },
                  {
                      text: "雷山县",
                      value: "12408113"
                  },
                  {
                      text: "麻江县",
                      value: "12408114"
                  },
                  {
                      text: "丹寨县",
                      value: "12408115"
                  },
                  {
                      text: "凯里市",
                      value: "12408301"
                  }
              ],
              text: "黔东南苗族侗族自治州",
              value: "12408000"
          },
          {
              children: [
                  {
                      text: "荔波县",
                      value: "12409201"
                  },
                  {
                      text: "贵定县",
                      value: "12409202"
                  },
                  {
                      text: "瓮安县",
                      value: "12409203"
                  },
                  {
                      text: "独山县",
                      value: "12409204"
                  },
                  {
                      text: "平塘县",
                      value: "12409205"
                  },
                  {
                      text: "罗甸县",
                      value: "12409206"
                  },
                  {
                      text: "长顺县",
                      value: "12409207"
                  },
                  {
                      text: "龙里县",
                      value: "12409208"
                  },
                  {
                      text: "惠水县",
                      value: "12409209"
                  },
                  {
                      text: "三都水族自治县",
                      value: "12409210"
                  },
                  {
                      text: "都匀市",
                      value: "12409301"
                  },
                  {
                      text: "福泉市",
                      value: "12409302"
                  }
              ],
              text: "黔南布依族苗族自治州",
              value: "12409000"
          }
      ],
      text: "贵州省",
      value: "12400000"
  },
  {
      children: [
          {
              children: [
                  {
                      text: "长安区",
                      value: "10301101"
                  },
                  {
                      text: "桥西区",
                      value: "10301103"
                  },
                  {
                      text: "新华区",
                      value: "10301104"
                  },
                  {
                      text: "井陉矿区",
                      value: "10301105"
                  },
                  {
                      text: "裕华区",
                      value: "10301106"
                  },
                  {
                      text: "井陉县",
                      value: "10301201"
                  },
                  {
                      text: "正定县",
                      value: "10301202"
                  },
                  {
                      text: "栾城区",
                      value: "10301203"
                  },
                  {
                      text: "行唐县",
                      value: "10301204"
                  },
                  {
                      text: "灵寿县",
                      value: "10301205"
                  },
                  {
                      text: "高邑县",
                      value: "10301206"
                  },
                  {
                      text: "深泽县",
                      value: "10301207"
                  },
                  {
                      text: "赞皇县",
                      value: "10301208"
                  },
                  {
                      text: "无极县",
                      value: "10301209"
                  },
                  {
                      text: "平山县",
                      value: "10301210"
                  },
                  {
                      text: "元氏县",
                      value: "10301211"
                  },
                  {
                      text: "赵县",
                      value: "10301212"
                  },
                  {
                      text: "辛集市",
                      value: "10301301"
                  },
                  {
                      text: "藁城区",
                      value: "10301302"
                  },
                  {
                      text: "晋州市",
                      value: "10301303"
                  },
                  {
                      text: "新乐市",
                      value: "10301304"
                  },
                  {
                      text: "鹿泉区",
                      value: "10301305"
                  }
              ],
              text: "石家庄市",
              value: "10301000"
          },
          {
              children: [
                  {
                      text: "路南区",
                      value: "10302101"
                  },
                  {
                      text: "路北区",
                      value: "10302102"
                  },
                  {
                      text: "古冶区",
                      value: "10302103"
                  },
                  {
                      text: "开平区",
                      value: "10302104"
                  },
                  {
                      text: "丰南区",
                      value: "10302105"
                  },
                  {
                      text: "丰润区",
                      value: "10302106"
                  },
                  {
                      text: "滦州市",
                      value: "10302201"
                  },
                  {
                      text: "滦南县",
                      value: "10302202"
                  },
                  {
                      text: "乐亭县",
                      value: "10302203"
                  },
                  {
                      text: "迁西县",
                      value: "10302204"
                  },
                  {
                      text: "玉田县",
                      value: "10302205"
                  },
                  {
                      text: "曹妃甸区",
                      value: "10302206"
                  },
                  {
                      text: "遵化市",
                      value: "10302301"
                  },
                  {
                      text: "迁安市",
                      value: "10302302"
                  }
              ],
              text: "唐山市",
              value: "10302000"
          },
          {
              children: [
                  {
                      text: "海港区",
                      value: "10303101"
                  },
                  {
                      text: "山海关区",
                      value: "10303102"
                  },
                  {
                      text: "北戴河区",
                      value: "10303103"
                  },
                  {
                      text: "抚宁区",
                      value: "10303104"
                  },
                  {
                      text: "青龙满族自治县",
                      value: "10303201"
                  },
                  {
                      text: "昌黎县",
                      value: "10303202"
                  },
                  {
                      text: "卢龙县",
                      value: "10303204"
                  }
              ],
              text: "秦皇岛市",
              value: "10303000"
          },
          {
              children: [
                  {
                      text: "邯山区",
                      value: "10304101"
                  },
                  {
                      text: "丛台区",
                      value: "10304102"
                  },
                  {
                      text: "复兴区",
                      value: "10304103"
                  },
                  {
                      text: "峰峰矿区",
                      value: "10304104"
                  },
                  {
                      text: "邯郸县",
                      value: "10304201"
                  },
                  {
                      text: "临漳县",
                      value: "10304202"
                  },
                  {
                      text: "成安县",
                      value: "10304203"
                  },
                  {
                      text: "大名县",
                      value: "10304204"
                  },
                  {
                      text: "涉县",
                      value: "10304205"
                  },
                  {
                      text: "磁县",
                      value: "10304206"
                  },
                  {
                      text: "肥乡区",
                      value: "10304207"
                  },
                  {
                      text: "永年区",
                      value: "10304208"
                  },
                  {
                      text: "邱县",
                      value: "10304209"
                  },
                  {
                      text: "鸡泽县",
                      value: "10304210"
                  },
                  {
                      text: "广平县",
                      value: "10304211"
                  },
                  {
                      text: "馆陶县",
                      value: "10304212"
                  },
                  {
                      text: "魏县",
                      value: "10304213"
                  },
                  {
                      text: "曲周县",
                      value: "10304214"
                  },
                  {
                      text: "武安市",
                      value: "10304301"
                  }
              ],
              text: "邯郸市",
              value: "10304000"
          },
          {
              children: [
                  {
                      text: "襄都区",
                      value: "10305101"
                  },
                  {
                      text: "信都区",
                      value: "10305102"
                  },
                  {
                      text: "临城县",
                      value: "10305202"
                  },
                  {
                      text: "内丘县",
                      value: "10305203"
                  },
                  {
                      text: "柏乡县",
                      value: "10305204"
                  },
                  {
                      text: "隆尧县",
                      value: "10305205"
                  },
                  {
                      text: "任泽区",
                      value: "10305206"
                  },
                  {
                      text: "南和区",
                      value: "10305207"
                  },
                  {
                      text: "宁晋县",
                      value: "10305208"
                  },
                  {
                      text: "巨鹿县",
                      value: "10305209"
                  },
                  {
                      text: "新河县",
                      value: "10305210"
                  },
                  {
                      text: "广宗县",
                      value: "10305211"
                  },
                  {
                      text: "平乡县",
                      value: "10305212"
                  },
                  {
                      text: "威县",
                      value: "10305213"
                  },
                  {
                      text: "清河县",
                      value: "10305214"
                  },
                  {
                      text: "临西县",
                      value: "10305215"
                  },
                  {
                      text: "南宫市",
                      value: "10305301"
                  },
                  {
                      text: "沙河市",
                      value: "10305302"
                  }
              ],
              text: "邢台市",
              value: "10305000"
          },
          {
              children: [
                  {
                      text: "竞秀区",
                      value: "10306101"
                  },
                  {
                      text: "莲池区",
                      value: "10306102"
                  },
                  {
                      text: "满城区",
                      value: "10306201"
                  },
                  {
                      text: "清苑区",
                      value: "10306202"
                  },
                  {
                      text: "涞水县",
                      value: "10306203"
                  },
                  {
                      text: "阜平县",
                      value: "10306204"
                  },
                  {
                      text: "徐水区",
                      value: "10306205"
                  },
                  {
                      text: "定兴县",
                      value: "10306206"
                  },
                  {
                      text: "唐县",
                      value: "10306207"
                  },
                  {
                      text: "高阳县",
                      value: "10306208"
                  },
                  {
                      text: "容城县",
                      value: "10306209"
                  },
                  {
                      text: "涞源县",
                      value: "10306210"
                  },
                  {
                      text: "望都县",
                      value: "10306211"
                  },
                  {
                      text: "安新县",
                      value: "10306212"
                  },
                  {
                      text: "易县",
                      value: "10306213"
                  },
                  {
                      text: "曲阳县",
                      value: "10306214"
                  },
                  {
                      text: "蠡县",
                      value: "10306215"
                  },
                  {
                      text: "顺平县",
                      value: "10306216"
                  },
                  {
                      text: "博野县",
                      value: "10306217"
                  },
                  {
                      text: "雄县",
                      value: "10306218"
                  },
                  {
                      text: "涿州市",
                      value: "10306301"
                  },
                  {
                      text: "定州市",
                      value: "10306302"
                  },
                  {
                      text: "安国市",
                      value: "10306303"
                  },
                  {
                      text: "高碑店市",
                      value: "10306304"
                  }
              ],
              text: "保定市",
              value: "10306000"
          },
          {
              children: [
                  {
                      text: "桥东区",
                      value: "10307101"
                  },
                  {
                      text: "桥西区",
                      value: "10307102"
                  },
                  {
                      text: "宣化区",
                      value: "10307103"
                  },
                  {
                      text: "下花园区",
                      value: "10307104"
                  },
                  {
                      text: "崇礼区",
                      value: "10307105"
                  },
                  {
                      text: "万全区",
                      value: "10307106"
                  },
                  {
                      text: "宣化县",
                      value: "10307201"
                  },
                  {
                      text: "张北县",
                      value: "10307202"
                  },
                  {
                      text: "康保县",
                      value: "10307203"
                  },
                  {
                      text: "沽源县",
                      value: "10307204"
                  },
                  {
                      text: "尚义县",
                      value: "10307205"
                  },
                  {
                      text: "蔚县",
                      value: "10307206"
                  },
                  {
                      text: "阳原县",
                      value: "10307207"
                  },
                  {
                      text: "怀安县",
                      value: "10307208"
                  },
                  {
                      text: "怀来县",
                      value: "10307210"
                  },
                  {
                      text: "涿鹿县",
                      value: "10307211"
                  },
                  {
                      text: "赤城县",
                      value: "10307212"
                  }
              ],
              text: "张家口市",
              value: "10307000"
          },
          {
              children: [
                  {
                      text: "双桥区",
                      value: "10308101"
                  },
                  {
                      text: "双滦区",
                      value: "10308102"
                  },
                  {
                      text: "鹰手营子矿区",
                      value: "10308103"
                  },
                  {
                      text: "承德县",
                      value: "10308201"
                  },
                  {
                      text: "兴隆县",
                      value: "10308202"
                  },
                  {
                      text: "平泉市",
                      value: "10308203"
                  },
                  {
                      text: "滦平县",
                      value: "10308204"
                  },
                  {
                      text: "隆化县",
                      value: "10308205"
                  },
                  {
                      text: "丰宁满族自治县",
                      value: "10308206"
                  },
                  {
                      text: "宽城满族自治县",
                      value: "10308207"
                  },
                  {
                      text: "围场满族蒙古族自治县",
                      value: "10308208"
                  }
              ],
              text: "承德市",
              value: "10308000"
          },
          {
              children: [
                  {
                      text: "新华区",
                      value: "10309101"
                  },
                  {
                      text: "运河区",
                      value: "10309102"
                  },
                  {
                      text: "沧县",
                      value: "10309201"
                  },
                  {
                      text: "青县",
                      value: "10309202"
                  },
                  {
                      text: "东光县",
                      value: "10309203"
                  },
                  {
                      text: "海兴县",
                      value: "10309204"
                  },
                  {
                      text: "盐山县",
                      value: "10309205"
                  },
                  {
                      text: "肃宁县",
                      value: "10309206"
                  },
                  {
                      text: "南皮县",
                      value: "10309207"
                  },
                  {
                      text: "吴桥县",
                      value: "10309208"
                  },
                  {
                      text: "献县",
                      value: "10309209"
                  },
                  {
                      text: "孟村回族自治县",
                      value: "10309210"
                  },
                  {
                      text: "泊头市",
                      value: "10309301"
                  },
                  {
                      text: "任丘市",
                      value: "10309302"
                  },
                  {
                      text: "黄骅市",
                      value: "10309303"
                  },
                  {
                      text: "河间市",
                      value: "10309304"
                  }
              ],
              text: "沧州市",
              value: "10309000"
          },
          {
              children: [
                  {
                      text: "安次区",
                      value: "10310101"
                  },
                  {
                      text: "广阳区",
                      value: "10310102"
                  },
                  {
                      text: "固安县",
                      value: "10310201"
                  },
                  {
                      text: "永清县",
                      value: "10310202"
                  },
                  {
                      text: "香河县",
                      value: "10310203"
                  },
                  {
                      text: "大城县",
                      value: "10310204"
                  },
                  {
                      text: "文安县",
                      value: "10310205"
                  },
                  {
                      text: "大厂回族自治县",
                      value: "10310206"
                  },
                  {
                      text: "霸州市",
                      value: "10310301"
                  },
                  {
                      text: "三河市",
                      value: "10310302"
                  }
              ],
              text: "廊坊市",
              value: "10310000"
          },
          {
              children: [
                  {
                      text: "桃城区",
                      value: "10311101"
                  },
                  {
                      text: "枣强县",
                      value: "10311201"
                  },
                  {
                      text: "武邑县",
                      value: "10311202"
                  },
                  {
                      text: "武强县",
                      value: "10311203"
                  },
                  {
                      text: "饶阳县",
                      value: "10311204"
                  },
                  {
                      text: "安平县",
                      value: "10311205"
                  },
                  {
                      text: "故城县",
                      value: "10311206"
                  },
                  {
                      text: "景县",
                      value: "10311207"
                  },
                  {
                      text: "阜城县",
                      value: "10311208"
                  },
                  {
                      text: "冀州区",
                      value: "10311301"
                  },
                  {
                      text: "深州市",
                      value: "10311302"
                  }
              ],
              text: "衡水市",
              value: "10311000"
          }
      ],
      text: "河北省",
      value: "10300000"
  },
  {
      children: [
          {
              children: [
                  {
                      text: "江岸区",
                      value: "11701101"
                  },
                  {
                      text: "江汉区",
                      value: "11701102"
                  },
                  {
                      text: "硚口区",
                      value: "11701103"
                  },
                  {
                      text: "汉阳区",
                      value: "11701104"
                  },
                  {
                      text: "武昌区",
                      value: "11701105"
                  },
                  {
                      text: "青山区",
                      value: "11701106"
                  },
                  {
                      text: "洪山区",
                      value: "11701107"
                  },
                  {
                      text: "东西湖区",
                      value: "11701108"
                  },
                  {
                      text: "汉南区",
                      value: "11701109"
                  },
                  {
                      text: "蔡甸区",
                      value: "11701110"
                  },
                  {
                      text: "江夏区",
                      value: "11701111"
                  },
                  {
                      text: "黄陂区",
                      value: "11701113"
                  },
                  {
                      text: "新洲区",
                      value: "11701114"
                  }
              ],
              text: "武汉市",
              value: "11701000"
          },
          {
              children: [
                  {
                      text: "黄石港区",
                      value: "11702101"
                  },
                  {
                      text: "西塞山区",
                      value: "11702102"
                  },
                  {
                      text: "下陆区",
                      value: "11702103"
                  },
                  {
                      text: "铁山区",
                      value: "11702104"
                  },
                  {
                      text: "阳新县",
                      value: "11702201"
                  },
                  {
                      text: "大冶市",
                      value: "11702301"
                  }
              ],
              text: "黄石市",
              value: "11702000"
          },
          {
              children: [
                  {
                      text: "茅箭区",
                      value: "11703101"
                  },
                  {
                      text: "张湾区",
                      value: "11703102"
                  },
                  {
                      text: "郧阳区",
                      value: "11703201"
                  },
                  {
                      text: "郧西县",
                      value: "11703202"
                  },
                  {
                      text: "竹山县",
                      value: "11703203"
                  },
                  {
                      text: "竹溪县",
                      value: "11703204"
                  },
                  {
                      text: "房县",
                      value: "11703205"
                  },
                  {
                      text: "丹江口市",
                      value: "11703301"
                  }
              ],
              text: "十堰市",
              value: "11703000"
          },
          {
              children: [
                  {
                      text: "西陵区",
                      value: "11704101"
                  },
                  {
                      text: "伍家岗区",
                      value: "11704102"
                  },
                  {
                      text: "点军区",
                      value: "11704103"
                  },
                  {
                      text: "猇亭区",
                      value: "11704104"
                  },
                  {
                      text: "夷陵区",
                      value: "11704105"
                  },
                  {
                      text: "远安县",
                      value: "11704201"
                  },
                  {
                      text: "兴山县",
                      value: "11704202"
                  },
                  {
                      text: "秭归县",
                      value: "11704203"
                  },
                  {
                      text: "长阳土家族自治县",
                      value: "11704204"
                  },
                  {
                      text: "五峰土家族自治县",
                      value: "11704205"
                  },
                  {
                      text: "宜都市",
                      value: "11704301"
                  },
                  {
                      text: "当阳市",
                      value: "11704302"
                  },
                  {
                      text: "枝江市",
                      value: "11704303"
                  }
              ],
              text: "宜昌市",
              value: "11704000"
          },
          {
              children: [
                  {
                      text: "襄城区",
                      value: "11705101"
                  },
                  {
                      text: "樊城区",
                      value: "11705102"
                  },
                  {
                      text: "襄州区",
                      value: "11705103"
                  },
                  {
                      text: "南漳县",
                      value: "11705201"
                  },
                  {
                      text: "谷城县",
                      value: "11705202"
                  },
                  {
                      text: "保康县",
                      value: "11705203"
                  },
                  {
                      text: "老河口市",
                      value: "11705301"
                  },
                  {
                      text: "枣阳市",
                      value: "11705302"
                  },
                  {
                      text: "宜城市",
                      value: "11705303"
                  }
              ],
              text: "襄阳市",
              value: "11705000"
          },
          {
              children: [
                  {
                      text: "梁子湖区",
                      value: "11706101"
                  },
                  {
                      text: "华容区",
                      value: "11706102"
                  },
                  {
                      text: "鄂城区",
                      value: "11706103"
                  }
              ],
              text: "鄂州市",
              value: "11706000"
          },
          {
              children: [
                  {
                      text: "东宝区",
                      value: "11707101"
                  },
                  {
                      text: "掇刀区",
                      value: "11707102"
                  },
                  {
                      text: "京山市",
                      value: "11707201"
                  },
                  {
                      text: "沙洋县",
                      value: "11707202"
                  },
                  {
                      text: "钟祥市",
                      value: "11707301"
                  }
              ],
              text: "荆门市",
              value: "11707000"
          },
          {
              children: [
                  {
                      text: "孝南区",
                      value: "11708101"
                  },
                  {
                      text: "孝昌县",
                      value: "11708201"
                  },
                  {
                      text: "大悟县",
                      value: "11708202"
                  },
                  {
                      text: "云梦县",
                      value: "11708203"
                  },
                  {
                      text: "应城市",
                      value: "11708301"
                  },
                  {
                      text: "安陆市",
                      value: "11708302"
                  },
                  {
                      text: "汉川市",
                      value: "11708303"
                  }
              ],
              text: "孝感市",
              value: "11708000"
          },
          {
              children: [
                  {
                      text: "沙市区",
                      value: "11709101"
                  },
                  {
                      text: "荆州区",
                      value: "11709102"
                  },
                  {
                      text: "公安县",
                      value: "11709201"
                  },
                  {
                      text: "监利县",
                      value: "11709202"
                  },
                  {
                      text: "江陵县",
                      value: "11709203"
                  },
                  {
                      text: "石首市",
                      value: "11709301"
                  },
                  {
                      text: "洪湖市",
                      value: "11709302"
                  },
                  {
                      text: "松滋市",
                      value: "11709303"
                  }
              ],
              text: "荆州市",
              value: "11709000"
          },
          {
              children: [
                  {
                      text: "黄州区",
                      value: "11710101"
                  },
                  {
                      text: "团风县",
                      value: "11710201"
                  },
                  {
                      text: "红安县",
                      value: "11710202"
                  },
                  {
                      text: "罗田县",
                      value: "11710203"
                  },
                  {
                      text: "英山县",
                      value: "11710204"
                  },
                  {
                      text: "浠水县",
                      value: "11710205"
                  },
                  {
                      text: "蕲春县",
                      value: "11710206"
                  },
                  {
                      text: "黄梅县",
                      value: "11710207"
                  },
                  {
                      text: "麻城市",
                      value: "11710301"
                  },
                  {
                      text: "武穴市",
                      value: "11710302"
                  }
              ],
              text: "黄冈市",
              value: "11710000"
          },
          {
              children: [
                  {
                      text: "咸安区",
                      value: "11711101"
                  },
                  {
                      text: "嘉鱼县",
                      value: "11711201"
                  },
                  {
                      text: "通城县",
                      value: "11711202"
                  },
                  {
                      text: "崇阳县",
                      value: "11711203"
                  },
                  {
                      text: "通山县",
                      value: "11711204"
                  },
                  {
                      text: "赤壁市",
                      value: "11711301"
                  }
              ],
              text: "咸宁市",
              value: "11711000"
          },
          {
              children: [
                  {
                      text: "曾都区",
                      value: "11712101"
                  },
                  {
                      text: "广水市",
                      value: "11712301"
                  },
                  {
                      text: "随县",
                      value: "11712302"
                  }
              ],
              text: "随州市",
              value: "11712000"
          },
          {
              children: [
                  {
                      text: "建始县",
                      value: "11713201"
                  },
                  {
                      text: "巴东县",
                      value: "11713202"
                  },
                  {
                      text: "宣恩县",
                      value: "11713203"
                  },
                  {
                      text: "咸丰县",
                      value: "11713205"
                  },
                  {
                      text: "来凤县",
                      value: "11713206"
                  },
                  {
                      text: "鹤峰县",
                      value: "11713207"
                  },
                  {
                      text: "恩施市",
                      value: "11713301"
                  },
                  {
                      text: "利川市",
                      value: "11713302"
                  }
              ],
              text: "恩施土家族苗族自治州",
              value: "11713000"
          },
          {
              text: "仙桃市",
              value: "11714000"
          },
          {
              text: "潜江市",
              value: "11715000"
          },
          {
              text: "天门市",
              value: "11716000"
          },
          {
              text: "神农架林区",
              value: "11717000"
          }
      ],
      text: "湖北省",
      value: "11700000"
  },
  {
      children: [
          {
              children: [
                  {
                      text: "道里区",
                      value: "10801101"
                  },
                  {
                      text: "南岗区",
                      value: "10801102"
                  },
                  {
                      text: "道外区",
                      value: "10801103"
                  },
                  {
                      text: "平房区",
                      value: "10801104"
                  },
                  {
                      text: "松北区",
                      value: "10801105"
                  },
                  {
                      text: "香坊区",
                      value: "10801106"
                  },
                  {
                      text: "呼兰区",
                      value: "10801107"
                  },
                  {
                      text: "阿城区",
                      value: "10801108"
                  },
                  {
                      text: "依兰县",
                      value: "10801201"
                  },
                  {
                      text: "方正县",
                      value: "10801202"
                  },
                  {
                      text: "宾县",
                      value: "10801203"
                  },
                  {
                      text: "巴彦县",
                      value: "10801204"
                  },
                  {
                      text: "木兰县",
                      value: "10801205"
                  },
                  {
                      text: "通河县",
                      value: "10801206"
                  },
                  {
                      text: "延寿县",
                      value: "10801207"
                  },
                  {
                      text: "双城区",
                      value: "10801301"
                  },
                  {
                      text: "尚志市",
                      value: "10801302"
                  },
                  {
                      text: "五常市",
                      value: "10801303"
                  }
              ],
              text: "哈尔滨市",
              value: "10801000"
          },
          {
              children: [
                  {
                      text: "龙沙区",
                      value: "10802101"
                  },
                  {
                      text: "建华区",
                      value: "10802102"
                  },
                  {
                      text: "铁锋区",
                      value: "10802103"
                  },
                  {
                      text: "昂昂溪区",
                      value: "10802104"
                  },
                  {
                      text: "富拉尔基区",
                      value: "10802105"
                  },
                  {
                      text: "碾子山区",
                      value: "10802106"
                  },
                  {
                      text: "梅里斯达斡尔族区",
                      value: "10802107"
                  },
                  {
                      text: "龙江县",
                      value: "10802201"
                  },
                  {
                      text: "依安县",
                      value: "10802202"
                  },
                  {
                      text: "泰来县",
                      value: "10802203"
                  },
                  {
                      text: "甘南县",
                      value: "10802204"
                  },
                  {
                      text: "富裕县",
                      value: "10802205"
                  },
                  {
                      text: "克山县",
                      value: "10802206"
                  },
                  {
                      text: "克东县",
                      value: "10802207"
                  },
                  {
                      text: "拜泉县",
                      value: "10802208"
                  },
                  {
                      text: "讷河市",
                      value: "10802301"
                  }
              ],
              text: "齐齐哈尔市",
              value: "10802000"
          },
          {
              children: [
                  {
                      text: "鸡冠区",
                      value: "10803101"
                  },
                  {
                      text: "恒山区",
                      value: "10803102"
                  },
                  {
                      text: "滴道区",
                      value: "10803103"
                  },
                  {
                      text: "梨树区",
                      value: "10803104"
                  },
                  {
                      text: "城子河区",
                      value: "10803105"
                  },
                  {
                      text: "麻山区",
                      value: "10803106"
                  },
                  {
                      text: "鸡东县",
                      value: "10803201"
                  },
                  {
                      text: "虎林市",
                      value: "10803301"
                  },
                  {
                      text: "密山市",
                      value: "10803302"
                  }
              ],
              text: "鸡西市",
              value: "10803000"
          },
          {
              children: [
                  {
                      text: "向阳区",
                      value: "10804101"
                  },
                  {
                      text: "工农区",
                      value: "10804102"
                  },
                  {
                      text: "南山区",
                      value: "10804103"
                  },
                  {
                      text: "兴安区",
                      value: "10804104"
                  },
                  {
                      text: "东山区",
                      value: "10804105"
                  },
                  {
                      text: "兴山区",
                      value: "10804106"
                  },
                  {
                      text: "萝北县",
                      value: "10804201"
                  },
                  {
                      text: "绥滨县",
                      value: "10804202"
                  }
              ],
              text: "鹤岗市",
              value: "10804000"
          },
          {
              children: [
                  {
                      text: "尖山区",
                      value: "10805101"
                  },
                  {
                      text: "岭东区",
                      value: "10805102"
                  },
                  {
                      text: "四方台区",
                      value: "10805103"
                  },
                  {
                      text: "宝山区",
                      value: "10805104"
                  },
                  {
                      text: "集贤县",
                      value: "10805201"
                  },
                  {
                      text: "友谊县",
                      value: "10805202"
                  },
                  {
                      text: "宝清县",
                      value: "10805203"
                  },
                  {
                      text: "饶河县",
                      value: "10805204"
                  }
              ],
              text: "双鸭山市",
              value: "10805000"
          },
          {
              children: [
                  {
                      text: "萨尔图区",
                      value: "10806101"
                  },
                  {
                      text: "龙凤区",
                      value: "10806102"
                  },
                  {
                      text: "让胡路区",
                      value: "10806103"
                  },
                  {
                      text: "红岗区",
                      value: "10806104"
                  },
                  {
                      text: "大同区",
                      value: "10806105"
                  },
                  {
                      text: "肇州县",
                      value: "10806201"
                  },
                  {
                      text: "肇源县",
                      value: "10806202"
                  },
                  {
                      text: "林甸县",
                      value: "10806203"
                  },
                  {
                      text: "杜尔伯特蒙古族自治县",
                      value: "10806204"
                  }
              ],
              text: "大庆市",
              value: "10806000"
          },
          {
              children: [
                  {
                      text: "伊春区",
                      value: "10807101"
                  },
                  {
                      text: "南岔县",
                      value: "10807102"
                  },
                  {
                      text: "友好区",
                      value: "10807103"
                  },
                  {
                      text: "西林区",
                      value: "10807104"
                  },
                  {
                      text: "翠峦区",
                      value: "10807105"
                  },
                  {
                      text: "新青区",
                      value: "10807106"
                  },
                  {
                      text: "美溪区",
                      value: "10807107"
                  },
                  {
                      text: "金山屯区",
                      value: "10807108"
                  },
                  {
                      text: "五营区",
                      value: "10807109"
                  },
                  {
                      text: "乌马河区",
                      value: "10807110"
                  },
                  {
                      text: "汤河县",
                      value: "10807111"
                  },
                  {
                      text: "带岭区",
                      value: "10807112"
                  },
                  {
                      text: "乌伊岭区",
                      value: "10807113"
                  },
                  {
                      text: "红星区",
                      value: "10807114"
                  },
                  {
                      text: "上甘岭区",
                      value: "10807115"
                  },
                  {
                      text: "嘉荫县",
                      value: "10807201"
                  },
                  {
                      text: "铁力市",
                      value: "10807301"
                  },
                  {
                      text: "丰林县",
                      value: "10807302"
                  },
                  {
                      text: "大箐山县",
                      value: "10807303"
                  }
              ],
              text: "伊春市",
              value: "10807000"
          },
          {
              children: [
                  {
                      text: "向阳区",
                      value: "10808101"
                  },
                  {
                      text: "前进区",
                      value: "10808102"
                  },
                  {
                      text: "东风区",
                      value: "10808103"
                  },
                  {
                      text: "郊区",
                      value: "10808104"
                  },
                  {
                      text: "桦南县",
                      value: "10808201"
                  },
                  {
                      text: "桦川县",
                      value: "10808202"
                  },
                  {
                      text: "汤原县",
                      value: "10808203"
                  },
                  {
                      text: "同江市",
                      value: "10808301"
                  },
                  {
                      text: "富锦市",
                      value: "10808302"
                  },
                  {
                      text: "抚远市",
                      value: "10808303"
                  }
              ],
              text: "佳木斯市",
              value: "10808000"
          },
          {
              children: [
                  {
                      text: "新兴区",
                      value: "10809101"
                  },
                  {
                      text: "桃山区",
                      value: "10809102"
                  },
                  {
                      text: "茄子河区",
                      value: "10809103"
                  },
                  {
                      text: "勃利县",
                      value: "10809201"
                  }
              ],
              text: "七台河市",
              value: "10809000"
          },
          {
              children: [
                  {
                      text: "东安区",
                      value: "10810101"
                  },
                  {
                      text: "阳明区",
                      value: "10810102"
                  },
                  {
                      text: "爱民区",
                      value: "10810103"
                  },
                  {
                      text: "西安区",
                      value: "10810104"
                  },
                  {
                      text: "林口县",
                      value: "10810202"
                  },
                  {
                      text: "绥芬河市",
                      value: "10810301"
                  },
                  {
                      text: "海林市",
                      value: "10810302"
                  },
                  {
                      text: "宁安市",
                      value: "10810303"
                  },
                  {
                      text: "穆棱市",
                      value: "10810304"
                  },
                  {
                      text: "东宁市",
                      value: "10810305"
                  }
              ],
              text: "牡丹江市",
              value: "10810000"
          },
          {
              children: [
                  {
                      text: "爱辉区",
                      value: "10811101"
                  },
                  {
                      text: "嫩江县",
                      value: "10811201"
                  },
                  {
                      text: "逊克县",
                      value: "10811202"
                  },
                  {
                      text: "孙吴县",
                      value: "10811203"
                  },
                  {
                      text: "北安市",
                      value: "10811301"
                  },
                  {
                      text: "五大连池市",
                      value: "10811302"
                  }
              ],
              text: "黑河市",
              value: "10811000"
          },
          {
              children: [
                  {
                      text: "北林区",
                      value: "10812101"
                  },
                  {
                      text: "望奎县",
                      value: "10812201"
                  },
                  {
                      text: "兰西县",
                      value: "10812202"
                  },
                  {
                      text: "青冈县",
                      value: "10812203"
                  },
                  {
                      text: "庆安县",
                      value: "10812204"
                  },
                  {
                      text: "明水县",
                      value: "10812205"
                  },
                  {
                      text: "绥棱县",
                      value: "10812206"
                  },
                  {
                      text: "安达市",
                      value: "10812301"
                  },
                  {
                      text: "肇东市",
                      value: "10812302"
                  },
                  {
                      text: "海伦市",
                      value: "10812303"
                  }
              ],
              text: "绥化市",
              value: "10812000"
          },
          {
              children: [
                  {
                      text: "加格达奇区",
                      value: "10813101"
                  },
                  {
                      text: "呼玛县",
                      value: "10813201"
                  },
                  {
                      text: "塔河县",
                      value: "10813202"
                  },
                  {
                      text: "漠河市",
                      value: "10813203"
                  }
              ],
              text: "大兴安岭地区",
              value: "10813000"
          }
      ],
      text: "黑龙江省",
      value: "10800000"
  },
  {
      children: [
          {
              children: [
                  {
                      text: "芙蓉区",
                      value: "11801101"
                  },
                  {
                      text: "天心区",
                      value: "11801102"
                  },
                  {
                      text: "岳麓区",
                      value: "11801103"
                  },
                  {
                      text: "开福区",
                      value: "11801104"
                  },
                  {
                      text: "雨花区",
                      value: "11801105"
                  },
                  {
                      text: "长沙县",
                      value: "11801201"
                  },
                  {
                      text: "望城区",
                      value: "11801202"
                  },
                  {
                      text: "宁乡县",
                      value: "11801203"
                  },
                  {
                      text: "浏阳市",
                      value: "11801301"
                  }
              ],
              text: "长沙市",
              value: "11801000"
          },
          {
              children: [
                  {
                      text: "荷塘区",
                      value: "11802101"
                  },
                  {
                      text: "芦淞区",
                      value: "11802102"
                  },
                  {
                      text: "石峰区",
                      value: "11802103"
                  },
                  {
                      text: "天元区",
                      value: "11802104"
                  },
                  {
                      text: "株洲县",
                      value: "11802201"
                  },
                  {
                      text: "攸县",
                      value: "11802202"
                  },
                  {
                      text: "茶陵县",
                      value: "11802203"
                  },
                  {
                      text: "炎陵县",
                      value: "11802204"
                  },
                  {
                      text: "醴陵市",
                      value: "11802301"
                  }
              ],
              text: "株洲市",
              value: "11802000"
          },
          {
              children: [
                  {
                      text: "雨湖区",
                      value: "11803101"
                  },
                  {
                      text: "岳塘区",
                      value: "11803102"
                  },
                  {
                      text: "湘潭县",
                      value: "11803201"
                  },
                  {
                      text: "湘乡市",
                      value: "11803301"
                  },
                  {
                      text: "韶山市",
                      value: "11803302"
                  }
              ],
              text: "湘潭市",
              value: "11803000"
          },
          {
              children: [
                  {
                      text: "珠晖区",
                      value: "11804101"
                  },
                  {
                      text: "雁峰区",
                      value: "11804102"
                  },
                  {
                      text: "石鼓区",
                      value: "11804103"
                  },
                  {
                      text: "蒸湘区",
                      value: "11804104"
                  },
                  {
                      text: "南岳区",
                      value: "11804105"
                  },
                  {
                      text: "衡阳县",
                      value: "11804201"
                  },
                  {
                      text: "衡南县",
                      value: "11804202"
                  },
                  {
                      text: "衡山县",
                      value: "11804203"
                  },
                  {
                      text: "衡东县",
                      value: "11804204"
                  },
                  {
                      text: "祁东县",
                      value: "11804205"
                  },
                  {
                      text: "耒阳市",
                      value: "11804301"
                  },
                  {
                      text: "常宁市",
                      value: "11804302"
                  }
              ],
              text: "衡阳市",
              value: "11804000"
          },
          {
              children: [
                  {
                      text: "双清区",
                      value: "11805101"
                  },
                  {
                      text: "大祥区",
                      value: "11805102"
                  },
                  {
                      text: "北塔区",
                      value: "11805103"
                  },
                  {
                      text: "邵东市",
                      value: "11805201"
                  },
                  {
                      text: "新邵县",
                      value: "11805202"
                  },
                  {
                      text: "邵阳县",
                      value: "11805203"
                  },
                  {
                      text: "隆回县",
                      value: "11805204"
                  },
                  {
                      text: "洞口县",
                      value: "11805205"
                  },
                  {
                      text: "绥宁县",
                      value: "11805206"
                  },
                  {
                      text: "新宁县",
                      value: "11805207"
                  },
                  {
                      text: "城步苗族自治县",
                      value: "11805208"
                  },
                  {
                      text: "武冈市",
                      value: "11805301"
                  }
              ],
              text: "邵阳市",
              value: "11805000"
          },
          {
              children: [
                  {
                      text: "岳阳楼区",
                      value: "11806101"
                  },
                  {
                      text: "云溪区",
                      value: "11806102"
                  },
                  {
                      text: "君山区",
                      value: "11806103"
                  },
                  {
                      text: "岳阳县",
                      value: "11806201"
                  },
                  {
                      text: "华容县",
                      value: "11806202"
                  },
                  {
                      text: "湘阴县",
                      value: "11806203"
                  },
                  {
                      text: "平江县",
                      value: "11806204"
                  },
                  {
                      text: "汨罗市",
                      value: "11806301"
                  },
                  {
                      text: "临湘市",
                      value: "11806302"
                  }
              ],
              text: "岳阳市",
              value: "11806000"
          },
          {
              children: [
                  {
                      text: "武陵区",
                      value: "11807101"
                  },
                  {
                      text: "鼎城区",
                      value: "11807102"
                  },
                  {
                      text: "安乡县",
                      value: "11807201"
                  },
                  {
                      text: "汉寿县",
                      value: "11807202"
                  },
                  {
                      text: "澧县",
                      value: "11807203"
                  },
                  {
                      text: "临澧县",
                      value: "11807204"
                  },
                  {
                      text: "桃源县",
                      value: "11807205"
                  },
                  {
                      text: "石门县",
                      value: "11807206"
                  },
                  {
                      text: "津市市",
                      value: "11807301"
                  }
              ],
              text: "常德市",
              value: "11807000"
          },
          {
              children: [
                  {
                      text: "永定区",
                      value: "11808101"
                  },
                  {
                      text: "武陵源区",
                      value: "11808102"
                  },
                  {
                      text: "慈利县",
                      value: "11808201"
                  },
                  {
                      text: "桑植县",
                      value: "11808202"
                  }
              ],
              text: "张家界市",
              value: "11808000"
          },
          {
              children: [
                  {
                      text: "资阳区",
                      value: "11809101"
                  },
                  {
                      text: "赫山区",
                      value: "11809102"
                  },
                  {
                      text: "南县",
                      value: "11809201"
                  },
                  {
                      text: "桃江县",
                      value: "11809202"
                  },
                  {
                      text: "安化县",
                      value: "11809203"
                  },
                  {
                      text: "沅江市",
                      value: "11809301"
                  }
              ],
              text: "益阳市",
              value: "11809000"
          },
          {
              children: [
                  {
                      text: "北湖区",
                      value: "11810101"
                  },
                  {
                      text: "苏仙区",
                      value: "11810102"
                  },
                  {
                      text: "桂阳县",
                      value: "11810201"
                  },
                  {
                      text: "宜章县",
                      value: "11810202"
                  },
                  {
                      text: "永兴县",
                      value: "11810203"
                  },
                  {
                      text: "嘉禾县",
                      value: "11810204"
                  },
                  {
                      text: "临武县",
                      value: "11810205"
                  },
                  {
                      text: "汝城县",
                      value: "11810206"
                  },
                  {
                      text: "桂东县",
                      value: "11810207"
                  },
                  {
                      text: "安仁县",
                      value: "11810208"
                  },
                  {
                      text: "资兴市",
                      value: "11810301"
                  }
              ],
              text: "郴州市",
              value: "11810000"
          },
          {
              children: [
                  {
                      text: "零陵区",
                      value: "11811101"
                  },
                  {
                      text: "冷水滩区",
                      value: "11811102"
                  },
                  {
                      text: "祁阳县",
                      value: "11811201"
                  },
                  {
                      text: "东安县",
                      value: "11811202"
                  },
                  {
                      text: "双牌县",
                      value: "11811203"
                  },
                  {
                      text: "道县",
                      value: "11811204"
                  },
                  {
                      text: "江永县",
                      value: "11811205"
                  },
                  {
                      text: "宁远县",
                      value: "11811206"
                  },
                  {
                      text: "蓝山县",
                      value: "11811207"
                  },
                  {
                      text: "新田县",
                      value: "11811208"
                  },
                  {
                      text: "江华瑶族自治县",
                      value: "11811209"
                  }
              ],
              text: "永州市",
              value: "11811000"
          },
          {
              children: [
                  {
                      text: "鹤城区",
                      value: "11812101"
                  },
                  {
                      text: "中方县",
                      value: "11812201"
                  },
                  {
                      text: "沅陵县",
                      value: "11812202"
                  },
                  {
                      text: "辰溪县",
                      value: "11812203"
                  },
                  {
                      text: "溆浦县",
                      value: "11812204"
                  },
                  {
                      text: "会同县",
                      value: "11812205"
                  },
                  {
                      text: "麻阳苗族自治县",
                      value: "11812206"
                  },
                  {
                      text: "新晃侗族自治县",
                      value: "11812207"
                  },
                  {
                      text: "芷江侗族自治县",
                      value: "11812208"
                  },
                  {
                      text: "靖州苗族侗族自治县",
                      value: "11812209"
                  },
                  {
                      text: "通道侗族自治县",
                      value: "11812210"
                  },
                  {
                      text: "洪江市",
                      value: "11812301"
                  }
              ],
              text: "怀化市",
              value: "11812000"
          },
          {
              children: [
                  {
                      text: "娄星区",
                      value: "11813101"
                  },
                  {
                      text: "双峰县",
                      value: "11813201"
                  },
                  {
                      text: "新化县",
                      value: "11813202"
                  },
                  {
                      text: "冷水江市",
                      value: "11813301"
                  },
                  {
                      text: "涟源市",
                      value: "11813302"
                  }
              ],
              text: "娄底市",
              value: "11813000"
          },
          {
              children: [
                  {
                      text: "泸溪县",
                      value: "11814201"
                  },
                  {
                      text: "凤凰县",
                      value: "11814202"
                  },
                  {
                      text: "花垣县",
                      value: "11814203"
                  },
                  {
                      text: "保靖县",
                      value: "11814204"
                  },
                  {
                      text: "古丈县",
                      value: "11814205"
                  },
                  {
                      text: "永顺县",
                      value: "11814206"
                  },
                  {
                      text: "龙山县",
                      value: "11814207"
                  },
                  {
                      text: "吉首市",
                      value: "11814301"
                  }
              ],
              text: "湘西土家族苗族自治州",
              value: "11814000"
          }
      ],
      text: "湖南省",
      value: "11800000"
  },
  {
      children: [
          {
              children: [
                  {
                      text: "中原区",
                      value: "11601101"
                  },
                  {
                      text: "二七区",
                      value: "11601102"
                  },
                  {
                      text: "管城回族区",
                      value: "11601103"
                  },
                  {
                      text: "金水区",
                      value: "11601104"
                  },
                  {
                      text: "上街区",
                      value: "11601105"
                  },
                  {
                      text: "惠济区",
                      value: "11601106"
                  },
                  {
                      text: "中牟县",
                      value: "11601201"
                  },
                  {
                      text: "巩义市",
                      value: "11601301"
                  },
                  {
                      text: "荥阳市",
                      value: "11601302"
                  },
                  {
                      text: "新密市",
                      value: "11601303"
                  },
                  {
                      text: "新郑市",
                      value: "11601304"
                  },
                  {
                      text: "登封市",
                      value: "11601305"
                  }
              ],
              text: "郑州市",
              value: "11601000"
          },
          {
              children: [
                  {
                      text: "龙亭区",
                      value: "11602101"
                  },
                  {
                      text: "顺河回族区",
                      value: "11602102"
                  },
                  {
                      text: "鼓楼区",
                      value: "11602103"
                  },
                  {
                      text: "禹王台区",
                      value: "11602104"
                  },
                  {
                      text: "杞县",
                      value: "11602201"
                  },
                  {
                      text: "通许县",
                      value: "11602202"
                  },
                  {
                      text: "尉氏县",
                      value: "11602203"
                  },
                  {
                      text: "祥符区",
                      value: "11602204"
                  },
                  {
                      text: "兰考县",
                      value: "11602205"
                  }
              ],
              text: "开封市",
              value: "11602000"
          },
          {
              children: [
                  {
                      text: "老城区",
                      value: "11603101"
                  },
                  {
                      text: "西工区",
                      value: "11603102"
                  },
                  {
                      text: "瀍河回族区",
                      value: "11603103"
                  },
                  {
                      text: "涧西区",
                      value: "11603104"
                  },
                  {
                      text: "吉利区",
                      value: "11603105"
                  },
                  {
                      text: "洛龙区",
                      value: "11603106"
                  },
                  {
                      text: "孟津县",
                      value: "11603201"
                  },
                  {
                      text: "新安县",
                      value: "11603202"
                  },
                  {
                      text: "栾川县",
                      value: "11603203"
                  },
                  {
                      text: "嵩县",
                      value: "11603204"
                  },
                  {
                      text: "汝阳县",
                      value: "11603205"
                  },
                  {
                      text: "宜阳县",
                      value: "11603206"
                  },
                  {
                      text: "洛宁县",
                      value: "11603207"
                  },
                  {
                      text: "伊川县",
                      value: "11603208"
                  },
                  {
                      text: "偃师市",
                      value: "11603301"
                  }
              ],
              text: "洛阳市",
              value: "11603000"
          },
          {
              children: [
                  {
                      text: "新华区",
                      value: "11604101"
                  },
                  {
                      text: "卫东区",
                      value: "11604102"
                  },
                  {
                      text: "石龙区",
                      value: "11604103"
                  },
                  {
                      text: "湛河区",
                      value: "11604104"
                  },
                  {
                      text: "宝丰县",
                      value: "11604201"
                  },
                  {
                      text: "叶县",
                      value: "11604202"
                  },
                  {
                      text: "鲁山县",
                      value: "11604203"
                  },
                  {
                      text: "郏县",
                      value: "11604204"
                  },
                  {
                      text: "舞钢市",
                      value: "11604301"
                  },
                  {
                      text: "汝州市",
                      value: "11604302"
                  }
              ],
              text: "平顶山市",
              value: "11604000"
          },
          {
              children: [
                  {
                      text: "文峰区",
                      value: "11605101"
                  },
                  {
                      text: "北关区",
                      value: "11605102"
                  },
                  {
                      text: "殷都区",
                      value: "11605103"
                  },
                  {
                      text: "龙安区",
                      value: "11605104"
                  },
                  {
                      text: "安阳县",
                      value: "11605201"
                  },
                  {
                      text: "汤阴县",
                      value: "11605202"
                  },
                  {
                      text: "滑县",
                      value: "11605203"
                  },
                  {
                      text: "内黄县",
                      value: "11605204"
                  },
                  {
                      text: "林州市",
                      value: "11605301"
                  }
              ],
              text: "安阳市",
              value: "11605000"
          },
          {
              children: [
                  {
                      text: "鹤山区",
                      value: "11606101"
                  },
                  {
                      text: "山城区",
                      value: "11606102"
                  },
                  {
                      text: "淇滨区",
                      value: "11606103"
                  },
                  {
                      text: "浚县",
                      value: "11606201"
                  },
                  {
                      text: "淇县",
                      value: "11606202"
                  }
              ],
              text: "鹤壁市",
              value: "11606000"
          },
          {
              children: [
                  {
                      text: "红旗区",
                      value: "11607101"
                  },
                  {
                      text: "卫滨区",
                      value: "11607102"
                  },
                  {
                      text: "凤泉区",
                      value: "11607103"
                  },
                  {
                      text: "牧野区",
                      value: "11607104"
                  },
                  {
                      text: "新乡县",
                      value: "11607201"
                  },
                  {
                      text: "获嘉县",
                      value: "11607202"
                  },
                  {
                      text: "原阳县",
                      value: "11607203"
                  },
                  {
                      text: "延津县",
                      value: "11607204"
                  },
                  {
                      text: "封丘县",
                      value: "11607205"
                  },
                  {
                      text: "长垣市",
                      value: "11607206"
                  },
                  {
                      text: "卫辉市",
                      value: "11607301"
                  },
                  {
                      text: "辉县市",
                      value: "11607302"
                  }
              ],
              text: "新乡市",
              value: "11607000"
          },
          {
              children: [
                  {
                      text: "解放区",
                      value: "11608101"
                  },
                  {
                      text: "中站区",
                      value: "11608102"
                  },
                  {
                      text: "马村区",
                      value: "11608103"
                  },
                  {
                      text: "山阳区",
                      value: "11608104"
                  },
                  {
                      text: "修武县",
                      value: "11608201"
                  },
                  {
                      text: "博爱县",
                      value: "11608202"
                  },
                  {
                      text: "武陟县",
                      value: "11608203"
                  },
                  {
                      text: "温县",
                      value: "11608204"
                  },
                  {
                      text: "沁阳市",
                      value: "11608301"
                  },
                  {
                      text: "孟州市",
                      value: "11608302"
                  }
              ],
              text: "焦作市",
              value: "11608000"
          },
          {
              children: [
                  {
                      text: "华龙区",
                      value: "11609101"
                  },
                  {
                      text: "清丰县",
                      value: "11609201"
                  },
                  {
                      text: "南乐县",
                      value: "11609202"
                  },
                  {
                      text: "范县",
                      value: "11609203"
                  },
                  {
                      text: "台前县",
                      value: "11609204"
                  },
                  {
                      text: "濮阳县",
                      value: "11609205"
                  }
              ],
              text: "濮阳市",
              value: "11609000"
          },
          {
              children: [
                  {
                      text: "魏都区",
                      value: "11610101"
                  },
                  {
                      text: "许昌县",
                      value: "11610201"
                  },
                  {
                      text: "鄢陵县",
                      value: "11610202"
                  },
                  {
                      text: "襄城县",
                      value: "11610203"
                  },
                  {
                      text: "建安区",
                      value: "11610204"
                  },
                  {
                      text: "禹州市",
                      value: "11610301"
                  },
                  {
                      text: "长葛市",
                      value: "11610302"
                  }
              ],
              text: "许昌市",
              value: "11610000"
          },
          {
              children: [
                  {
                      text: "源汇区",
                      value: "11611101"
                  },
                  {
                      text: "郾城区",
                      value: "11611102"
                  },
                  {
                      text: "召陵区",
                      value: "11611103"
                  },
                  {
                      text: "舞阳县",
                      value: "11611201"
                  },
                  {
                      text: "临颍县",
                      value: "11611202"
                  }
              ],
              text: "漯河市",
              value: "11611000"
          },
          {
              children: [
                  {
                      text: "湖滨区",
                      value: "11612101"
                  },
                  {
                      text: "渑池县",
                      value: "11612201"
                  },
                  {
                      text: "陕州区",
                      value: "11612202"
                  },
                  {
                      text: "卢氏县",
                      value: "11612203"
                  },
                  {
                      text: "义马市",
                      value: "11612301"
                  },
                  {
                      text: "灵宝市",
                      value: "11612302"
                  }
              ],
              text: "三门峡市",
              value: "11612000"
          },
          {
              children: [
                  {
                      text: "宛城区",
                      value: "11613101"
                  },
                  {
                      text: "卧龙区",
                      value: "11613102"
                  },
                  {
                      text: "南召县",
                      value: "11613201"
                  },
                  {
                      text: "方城县",
                      value: "11613202"
                  },
                  {
                      text: "西峡县",
                      value: "11613203"
                  },
                  {
                      text: "镇平县",
                      value: "11613204"
                  },
                  {
                      text: "内乡县",
                      value: "11613205"
                  },
                  {
                      text: "淅川县",
                      value: "11613206"
                  },
                  {
                      text: "社旗县",
                      value: "11613207"
                  },
                  {
                      text: "唐河县",
                      value: "11613208"
                  },
                  {
                      text: "新野县",
                      value: "11613209"
                  },
                  {
                      text: "桐柏县",
                      value: "11613210"
                  },
                  {
                      text: "邓州市",
                      value: "11613301"
                  }
              ],
              text: "南阳市",
              value: "11613000"
          },
          {
              children: [
                  {
                      text: "梁园区",
                      value: "11614101"
                  },
                  {
                      text: "睢阳区",
                      value: "11614102"
                  },
                  {
                      text: "民权县",
                      value: "11614103"
                  },
                  {
                      text: "睢县",
                      value: "11614201"
                  },
                  {
                      text: "宁陵县",
                      value: "11614202"
                  },
                  {
                      text: "柘城县",
                      value: "11614203"
                  },
                  {
                      text: "虞城县",
                      value: "11614204"
                  },
                  {
                      text: "夏邑县",
                      value: "11614205"
                  },
                  {
                      text: "永城市",
                      value: "11614301"
                  }
              ],
              text: "商丘市",
              value: "11614000"
          },
          {
              children: [
                  {
                      text: "浉河区",
                      value: "11615101"
                  },
                  {
                      text: "平桥区",
                      value: "11615102"
                  },
                  {
                      text: "罗山县",
                      value: "11615201"
                  },
                  {
                      text: "光山县",
                      value: "11615202"
                  },
                  {
                      text: "新县",
                      value: "11615203"
                  },
                  {
                      text: "商城县",
                      value: "11615204"
                  },
                  {
                      text: "固始县",
                      value: "11615205"
                  },
                  {
                      text: "潢川县",
                      value: "11615206"
                  },
                  {
                      text: "淮滨县",
                      value: "11615207"
                  },
                  {
                      text: "息县",
                      value: "11615208"
                  }
              ],
              text: "信阳市",
              value: "11615000"
          },
          {
              children: [
                  {
                      text: "川汇区",
                      value: "11616101"
                  },
                  {
                      text: "扶沟县",
                      value: "11616201"
                  },
                  {
                      text: "西华县",
                      value: "11616202"
                  },
                  {
                      text: "商水县",
                      value: "11616203"
                  },
                  {
                      text: "沈丘县",
                      value: "11616204"
                  },
                  {
                      text: "郸城县",
                      value: "11616205"
                  },
                  {
                      text: "淮阳市",
                      value: "11616206"
                  },
                  {
                      text: "太康县",
                      value: "11616207"
                  },
                  {
                      text: "鹿邑县",
                      value: "11616208"
                  },
                  {
                      text: "项城市",
                      value: "11616301"
                  }
              ],
              text: "周口市",
              value: "11616000"
          },
          {
              children: [
                  {
                      text: "驿城区",
                      value: "11617101"
                  },
                  {
                      text: "西平县",
                      value: "11617201"
                  },
                  {
                      text: "上蔡县",
                      value: "11617202"
                  },
                  {
                      text: "平舆县",
                      value: "11617203"
                  },
                  {
                      text: "正阳县",
                      value: "11617204"
                  },
                  {
                      text: "确山县",
                      value: "11617205"
                  },
                  {
                      text: "泌阳县",
                      value: "11617206"
                  },
                  {
                      text: "汝南县",
                      value: "11617207"
                  },
                  {
                      text: "遂平县",
                      value: "11617208"
                  },
                  {
                      text: "新蔡县",
                      value: "11617209"
                  }
              ],
              text: "驻马店市",
              value: "11617000"
          },
          {
              text: "济源市",
              value: "11618000"
          }
      ],
      text: "河南省",
      value: "11600000"
  },
  {
      children: [
          {
              children: [
                  {
                      text: "秀英区",
                      value: "12101101"
                  },
                  {
                      text: "龙华区",
                      value: "12101102"
                  },
                  {
                      text: "琼山区",
                      value: "12101103"
                  },
                  {
                      text: "美兰区",
                      value: "12101104"
                  }
              ],
              text: "海口市",
              value: "12101000"
          },
          {
              children: [
                  {
                      text: "海棠区",
                      value: "12102101"
                  },
                  {
                      text: "吉阳区",
                      value: "12102102"
                  },
                  {
                      text: "天涯区",
                      value: "12102103"
                  },
                  {
                      text: "崖州区",
                      value: "12102104"
                  },
                  {
                      text: "解放路",
                      value: "12102105"
                  },
                  {
                      text: "迎宾路",
                      value: "12102106"
                  },
                  {
                      text: "河西",
                      value: "12102107"
                  },
                  {
                      text: "河东",
                      value: "12102108"
                  },
                  {
                      text: "亚龙湾",
                      value: "12102109"
                  },
                  {
                      text: "大东海",
                      value: "12102110"
                  },
                  {
                      text: "三亚湾",
                      value: "12102111"
                  }
              ],
              text: "三亚市",
              value: "12102000"
          },
          {
              text: "五指山市",
              value: "12103000"
          },
          {
              text: "琼海市",
              value: "12104000"
          },
          {
              text: "儋州市",
              value: "12105000"
          },
          {
              text: "文昌市",
              value: "12106000"
          },
          {
              text: "万宁市",
              value: "12107000"
          },
          {
              text: "东方市",
              value: "12108000"
          },
          {
              text: "定安县",
              value: "12109000"
          },
          {
              text: "屯昌县",
              value: "12110000"
          },
          {
              text: "澄迈县",
              value: "12111000"
          },
          {
              text: "临高县",
              value: "12112000"
          },
          {
              text: "白沙黎族自治县",
              value: "12113000"
          },
          {
              text: "昌江黎族自治县",
              value: "12114000"
          },
          {
              text: "乐东黎族自治县",
              value: "12115000"
          },
          {
              text: "陵水黎族自治县",
              value: "12116000"
          },
          {
              text: "保亭黎族苗族自治县",
              value: "12117000"
          },
          {
              text: "琼中黎族苗族自治县",
              value: "12118000"
          },
          {
              children: [
                  {
                      text: "西沙群岛",
                      value: "12119101"
                  },
                  {
                      text: "南沙群岛",
                      value: "12119102"
                  },
                  {
                      text: "中沙群岛的岛礁及其海域",
                      value: "12119103"
                  }
              ],
              text: "三沙市",
              value: "12119000"
          }
      ],
      text: "海南省",
      value: "12100000"
  },
  {
      children: [
          {
              children: [
                  {
                      text: "南关区",
                      value: "10701101"
                  },
                  {
                      text: "宽城区",
                      value: "10701102"
                  },
                  {
                      text: "朝阳区",
                      value: "10701103"
                  },
                  {
                      text: "二道区",
                      value: "10701104"
                  },
                  {
                      text: "绿园区",
                      value: "10701105"
                  },
                  {
                      text: "双阳区",
                      value: "10701106"
                  },
                  {
                      text: "农安县",
                      value: "10701201"
                  },
                  {
                      text: "九台区",
                      value: "10701301"
                  },
                  {
                      text: "榆树市",
                      value: "10701302"
                  },
                  {
                      text: "德惠市",
                      value: "10701303"
                  },
                  {
                      text: "公主岭市",
                      value: "10701304"
                  }
              ],
              text: "长春市",
              value: "10701000"
          },
          {
              children: [
                  {
                      text: "昌邑区",
                      value: "10702101"
                  },
                  {
                      text: "龙潭区",
                      value: "10702102"
                  },
                  {
                      text: "船营区",
                      value: "10702103"
                  },
                  {
                      text: "丰满区",
                      value: "10702104"
                  },
                  {
                      text: "永吉县",
                      value: "10702201"
                  },
                  {
                      text: "蛟河市",
                      value: "10702301"
                  },
                  {
                      text: "桦甸市",
                      value: "10702302"
                  },
                  {
                      text: "舒兰市",
                      value: "10702303"
                  },
                  {
                      text: "磐石市",
                      value: "10702304"
                  }
              ],
              text: "吉林市",
              value: "10702000"
          },
          {
              children: [
                  {
                      text: "铁西区",
                      value: "10703101"
                  },
                  {
                      text: "铁东区",
                      value: "10703102"
                  },
                  {
                      text: "梨树县",
                      value: "10703201"
                  },
                  {
                      text: "伊通满族自治县",
                      value: "10703202"
                  },
                  {
                      text: "双辽市",
                      value: "10703302"
                  }
              ],
              text: "四平市",
              value: "10703000"
          },
          {
              children: [
                  {
                      text: "龙山区",
                      value: "10704101"
                  },
                  {
                      text: "西安区",
                      value: "10704102"
                  },
                  {
                      text: "东丰县",
                      value: "10704201"
                  },
                  {
                      text: "东辽县",
                      value: "10704202"
                  }
              ],
              text: "辽源市",
              value: "10704000"
          },
          {
              children: [
                  {
                      text: "东昌区",
                      value: "10705101"
                  },
                  {
                      text: "二道江区",
                      value: "10705102"
                  },
                  {
                      text: "通化县",
                      value: "10705201"
                  },
                  {
                      text: "辉南县",
                      value: "10705202"
                  },
                  {
                      text: "柳河县",
                      value: "10705203"
                  },
                  {
                      text: "梅河口市",
                      value: "10705301"
                  },
                  {
                      text: "集安市",
                      value: "10705302"
                  }
              ],
              text: "通化市",
              value: "10705000"
          },
          {
              children: [
                  {
                      text: "浑江区",
                      value: "10706101"
                  },
                  {
                      text: "江源区",
                      value: "10706201"
                  },
                  {
                      text: "抚松县",
                      value: "10706202"
                  },
                  {
                      text: "靖宇县",
                      value: "10706203"
                  },
                  {
                      text: "长白朝鲜族自治县",
                      value: "10706204"
                  },
                  {
                      text: "临江市",
                      value: "10706301"
                  }
              ],
              text: "白山市",
              value: "10706000"
          },
          {
              children: [
                  {
                      text: "宁江区",
                      value: "10707101"
                  },
                  {
                      text: "前郭尔罗斯蒙古族自治县",
                      value: "10707201"
                  },
                  {
                      text: "长岭县",
                      value: "10707202"
                  },
                  {
                      text: "乾安县",
                      value: "10707203"
                  },
                  {
                      text: "扶余市",
                      value: "10707204"
                  }
              ],
              text: "松原市",
              value: "10707000"
          },
          {
              children: [
                  {
                      text: "洮北区",
                      value: "10708101"
                  },
                  {
                      text: "镇赉县",
                      value: "10708201"
                  },
                  {
                      text: "通榆县",
                      value: "10708202"
                  },
                  {
                      text: "洮南市",
                      value: "10708301"
                  },
                  {
                      text: "大安市",
                      value: "10708302"
                  }
              ],
              text: "白城市",
              value: "10708000"
          },
          {
              children: [
                  {
                      text: "汪清县",
                      value: "10709201"
                  },
                  {
                      text: "安图县",
                      value: "10709202"
                  },
                  {
                      text: "延吉市",
                      value: "10709301"
                  },
                  {
                      text: "图们市",
                      value: "10709302"
                  },
                  {
                      text: "敦化市",
                      value: "10709303"
                  },
                  {
                      text: "珲春市",
                      value: "10709304"
                  },
                  {
                      text: "龙井市",
                      value: "10709305"
                  },
                  {
                      text: "和龙市",
                      value: "10709306"
                  }
              ],
              text: "延边朝鲜族自治州",
              value: "10709000"
          }
      ],
      text: "吉林省",
      value: "10700000"
  },
  {
      children: [
          {
              children: [
                  {
                      text: "玄武区",
                      value: "11001101"
                  },
                  {
                      text: "秦淮区",
                      value: "11001103"
                  },
                  {
                      text: "建邺区",
                      value: "11001104"
                  },
                  {
                      text: "鼓楼区",
                      value: "11001105"
                  },
                  {
                      text: "浦口区",
                      value: "11001107"
                  },
                  {
                      text: "栖霞区",
                      value: "11001108"
                  },
                  {
                      text: "雨花台区",
                      value: "11001109"
                  },
                  {
                      text: "江宁区",
                      value: "11001110"
                  },
                  {
                      text: "六合区",
                      value: "11001111"
                  },
                  {
                      text: "溧水区",
                      value: "11001201"
                  },
                  {
                      text: "高淳区",
                      value: "11001202"
                  }
              ],
              text: "南京市",
              value: "11001000"
          },
          {
              children: [
                  {
                      text: "崇安区",
                      value: "11002101"
                  },
                  {
                      text: "南长区",
                      value: "11002102"
                  },
                  {
                      text: "北塘区",
                      value: "11002103"
                  },
                  {
                      text: "锡山区",
                      value: "11002104"
                  },
                  {
                      text: "惠山区",
                      value: "11002105"
                  },
                  {
                      text: "滨湖区",
                      value: "11002106"
                  },
                  {
                      text: "梁溪区",
                      value: "11002107"
                  },
                  {
                      text: "新吴区",
                      value: "11002108"
                  },
                  {
                      text: "江阴市",
                      value: "11002301"
                  },
                  {
                      text: "宜兴市",
                      value: "11002302"
                  }
              ],
              text: "无锡市",
              value: "11002000"
          },
          {
              children: [
                  {
                      text: "鼓楼区",
                      value: "11003101"
                  },
                  {
                      text: "云龙区",
                      value: "11003102"
                  },
                  {
                      text: "贾汪区",
                      value: "11003104"
                  },
                  {
                      text: "泉山区",
                      value: "11003105"
                  },
                  {
                      text: "丰县",
                      value: "11003201"
                  },
                  {
                      text: "沛县",
                      value: "11003202"
                  },
                  {
                      text: "铜山区",
                      value: "11003203"
                  },
                  {
                      text: "睢宁县",
                      value: "11003204"
                  },
                  {
                      text: "新沂市",
                      value: "11003301"
                  },
                  {
                      text: "邳州市",
                      value: "11003302"
                  }
              ],
              text: "徐州市",
              value: "11003000"
          },
          {
              children: [
                  {
                      text: "天宁区",
                      value: "11004101"
                  },
                  {
                      text: "钟楼区",
                      value: "11004102"
                  },
                  {
                      text: "新北区",
                      value: "11004104"
                  },
                  {
                      text: "武进区",
                      value: "11004105"
                  },
                  {
                      text: "溧阳市",
                      value: "11004301"
                  },
                  {
                      text: "金坛区",
                      value: "11004302"
                  }
              ],
              text: "常州市",
              value: "11004000"
          },
          {
              children: [
                  {
                      text: "姑苏区",
                      value: "11005101"
                  },
                  {
                      text: "虎丘区",
                      value: "11005104"
                  },
                  {
                      text: "吴中区",
                      value: "11005105"
                  },
                  {
                      text: "相城区",
                      value: "11005106"
                  },
                  {
                      text: "常熟市",
                      value: "11005301"
                  },
                  {
                      text: "张家港市",
                      value: "11005302"
                  },
                  {
                      text: "昆山市",
                      value: "11005303"
                  },
                  {
                      text: "吴江区",
                      value: "11005304"
                  },
                  {
                      text: "太仓市",
                      value: "11005305"
                  },
                  {
                      text: "苏州工业园区",
                      value: "11005306"
                  }
              ],
              text: "苏州市",
              value: "11005000"
          },
          {
              children: [
                  {
                      text: "崇川区",
                      value: "11006101"
                  },
                  {
                      text: "港闸区",
                      value: "11006102"
                  },
                  {
                      text: "通州区",
                      value: "11006103"
                  },
                  {
                      text: "海安市",
                      value: "11006201"
                  },
                  {
                      text: "如东县",
                      value: "11006202"
                  },
                  {
                      text: "启东市",
                      value: "11006301"
                  },
                  {
                      text: "如皋市",
                      value: "11006302"
                  },
                  {
                      text: "海门区",
                      value: "11006304"
                  }
              ],
              text: "南通市",
              value: "11006000"
          },
          {
              children: [
                  {
                      text: "连云区",
                      value: "11007101"
                  },
                  {
                      text: "海州区",
                      value: "11007103"
                  },
                  {
                      text: "赣榆区",
                      value: "11007201"
                  },
                  {
                      text: "东海县",
                      value: "11007202"
                  },
                  {
                      text: "灌云县",
                      value: "11007203"
                  },
                  {
                      text: "灌南县",
                      value: "11007204"
                  }
              ],
              text: "连云港市",
              value: "11007000"
          },
          {
              children: [
                  {
                      text: "清河区",
                      value: "11008101"
                  },
                  {
                      text: "淮安区",
                      value: "11008102"
                  },
                  {
                      text: "淮阴区",
                      value: "11008103"
                  },
                  {
                      text: "清浦区",
                      value: "11008104"
                  },
                  {
                      text: "涟水县",
                      value: "11008201"
                  },
                  {
                      text: "洪泽县",
                      value: "11008202"
                  },
                  {
                      text: "盱眙县",
                      value: "11008203"
                  },
                  {
                      text: "金湖县",
                      value: "11008204"
                  }
              ],
              text: "淮安市",
              value: "11008000"
          },
          {
              children: [
                  {
                      text: "亭湖区",
                      value: "11009101"
                  },
                  {
                      text: "盐都区",
                      value: "11009102"
                  },
                  {
                      text: "大丰区",
                      value: "11009103"
                  },
                  {
                      text: "响水县",
                      value: "11009201"
                  },
                  {
                      text: "滨海县",
                      value: "11009202"
                  },
                  {
                      text: "阜宁县",
                      value: "11009203"
                  },
                  {
                      text: "射阳县",
                      value: "11009204"
                  },
                  {
                      text: "建湖县",
                      value: "11009205"
                  },
                  {
                      text: "东台市",
                      value: "11009301"
                  }
              ],
              text: "盐城市",
              value: "11009000"
          },
          {
              children: [
                  {
                      text: "广陵区",
                      value: "11010101"
                  },
                  {
                      text: "邗江区",
                      value: "11010102"
                  },
                  {
                      text: "宝应县",
                      value: "11010201"
                  },
                  {
                      text: "仪征市",
                      value: "11010301"
                  },
                  {
                      text: "高邮市",
                      value: "11010302"
                  },
                  {
                      text: "江都区",
                      value: "11010303"
                  }
              ],
              text: "扬州市",
              value: "11010000"
          },
          {
              children: [
                  {
                      text: "京口区",
                      value: "11011101"
                  },
                  {
                      text: "润州区",
                      value: "11011102"
                  },
                  {
                      text: "丹徒区",
                      value: "11011103"
                  },
                  {
                      text: "丹阳市",
                      value: "11011301"
                  },
                  {
                      text: "扬中市",
                      value: "11011302"
                  },
                  {
                      text: "句容市",
                      value: "11011303"
                  }
              ],
              text: "镇江市",
              value: "11011000"
          },
          {
              children: [
                  {
                      text: "海陵区",
                      value: "11012101"
                  },
                  {
                      text: "高港区",
                      value: "11012102"
                  },
                  {
                      text: "兴化市",
                      value: "11012201"
                  },
                  {
                      text: "靖江市",
                      value: "11012202"
                  },
                  {
                      text: "泰兴市",
                      value: "11012203"
                  },
                  {
                      text: "姜堰区",
                      value: "11012204"
                  }
              ],
              text: "泰州市",
              value: "11012000"
          },
          {
              children: [
                  {
                      text: "宿城区",
                      value: "11013101"
                  },
                  {
                      text: "宿豫区",
                      value: "11013102"
                  },
                  {
                      text: "沭阳县",
                      value: "11013201"
                  },
                  {
                      text: "泗阳县",
                      value: "11013202"
                  },
                  {
                      text: "泗洪县",
                      value: "11013203"
                  }
              ],
              text: "宿迁市",
              value: "11013000"
          }
      ],
      text: "江苏省",
      value: "11000000"
  },
  {
      children: [
          {
              text: "境外",
              value: "13501000"
          }
      ],
      text: "境外",
      value: "13500000"
  },
  {
      children: [
          {
              children: [
                  {
                      text: "东湖区",
                      value: "11401101"
                  },
                  {
                      text: "西湖区",
                      value: "11401102"
                  },
                  {
                      text: "青云谱区",
                      value: "11401103"
                  },
                  {
                      text: "湾里区",
                      value: "11401104"
                  },
                  {
                      text: "青山湖区",
                      value: "11401105"
                  },
                  {
                      text: "新建区",
                      value: "11401106"
                  },
                  {
                      text: "南昌县",
                      value: "11401201"
                  },
                  {
                      text: "安义县",
                      value: "11401203"
                  },
                  {
                      text: "进贤县",
                      value: "11401204"
                  },
                  {
                      text: "红谷滩区",
                      value: "11401205"
                  }
              ],
              text: "南昌市",
              value: "11401000"
          },
          {
              children: [
                  {
                      text: "昌江区",
                      value: "11402101"
                  },
                  {
                      text: "珠山区",
                      value: "11402102"
                  },
                  {
                      text: "浮梁县",
                      value: "11402201"
                  },
                  {
                      text: "乐平市",
                      value: "11402301"
                  }
              ],
              text: "景德镇市",
              value: "11402000"
          },
          {
              children: [
                  {
                      text: "安源区",
                      value: "11403101"
                  },
                  {
                      text: "湘东区",
                      value: "11403102"
                  },
                  {
                      text: "莲花县",
                      value: "11403201"
                  },
                  {
                      text: "上栗县",
                      value: "11403202"
                  },
                  {
                      text: "芦溪县",
                      value: "11403203"
                  }
              ],
              text: "萍乡市",
              value: "11403000"
          },
          {
              children: [
                  {
                      text: "濂溪区",
                      value: "11404101"
                  },
                  {
                      text: "浔阳区",
                      value: "11404102"
                  },
                  {
                      text: "九江县",
                      value: "11404201"
                  },
                  {
                      text: "武宁县",
                      value: "11404202"
                  },
                  {
                      text: "修水县",
                      value: "11404203"
                  },
                  {
                      text: "永修县",
                      value: "11404204"
                  },
                  {
                      text: "德安县",
                      value: "11404205"
                  },
                  {
                      text: "星子县",
                      value: "11404206"
                  },
                  {
                      text: "都昌县",
                      value: "11404207"
                  },
                  {
                      text: "湖口县",
                      value: "11404208"
                  },
                  {
                      text: "彭泽县",
                      value: "11404209"
                  },
                  {
                      text: "瑞昌市",
                      value: "11404301"
                  },
                  {
                      text: "共青城市",
                      value: "11404302"
                  },
                  {
                      text: "庐山市",
                      value: "11404303"
                  }
              ],
              text: "九江市",
              value: "11404000"
          },
          {
              children: [
                  {
                      text: "渝水区",
                      value: "11405101"
                  },
                  {
                      text: "分宜县",
                      value: "11405201"
                  }
              ],
              text: "新余市",
              value: "11405000"
          },
          {
              children: [
                  {
                      text: "月湖区",
                      value: "11406101"
                  },
                  {
                      text: "余江县",
                      value: "11406201"
                  },
                  {
                      text: "贵溪市",
                      value: "11406301"
                  },
                  {
                      text: "余江市",
                      value: "11406302"
                  }
              ],
              text: "鹰潭市",
              value: "11406000"
          },
          {
              children: [
                  {
                      text: "章贡区",
                      value: "11407101"
                  },
                  {
                      text: "赣县区",
                      value: "11407201"
                  },
                  {
                      text: "信丰县",
                      value: "11407202"
                  },
                  {
                      text: "大余县",
                      value: "11407203"
                  },
                  {
                      text: "上犹县",
                      value: "11407204"
                  },
                  {
                      text: "崇义县",
                      value: "11407205"
                  },
                  {
                      text: "安远县",
                      value: "11407206"
                  },
                  {
                      text: "龙南县",
                      value: "11407207"
                  },
                  {
                      text: "定南县",
                      value: "11407208"
                  },
                  {
                      text: "全南县",
                      value: "11407209"
                  },
                  {
                      text: "宁都县",
                      value: "11407210"
                  },
                  {
                      text: "于都县",
                      value: "11407211"
                  },
                  {
                      text: "兴国县",
                      value: "11407212"
                  },
                  {
                      text: "会昌县",
                      value: "11407213"
                  },
                  {
                      text: "寻乌县",
                      value: "11407214"
                  },
                  {
                      text: "石城县",
                      value: "11407215"
                  },
                  {
                      text: "瑞金市",
                      value: "11407301"
                  },
                  {
                      text: "南康区",
                      value: "11407302"
                  }
              ],
              text: "赣州市",
              value: "11407000"
          },
          {
              children: [
                  {
                      text: "吉州区",
                      value: "11408101"
                  },
                  {
                      text: "青原区",
                      value: "11408102"
                  },
                  {
                      text: "吉安县",
                      value: "11408201"
                  },
                  {
                      text: "吉水县",
                      value: "11408202"
                  },
                  {
                      text: "峡江县",
                      value: "11408203"
                  },
                  {
                      text: "新干县",
                      value: "11408204"
                  },
                  {
                      text: "永丰县",
                      value: "11408205"
                  },
                  {
                      text: "泰和县",
                      value: "11408206"
                  },
                  {
                      text: "遂川县",
                      value: "11408207"
                  },
                  {
                      text: "万安县",
                      value: "11408208"
                  },
                  {
                      text: "安福县",
                      value: "11408209"
                  },
                  {
                      text: "永新县",
                      value: "11408210"
                  },
                  {
                      text: "井冈山市",
                      value: "11408301"
                  }
              ],
              text: "吉安市",
              value: "11408000"
          },
          {
              children: [
                  {
                      text: "袁州区",
                      value: "11409101"
                  },
                  {
                      text: "奉新县",
                      value: "11409201"
                  },
                  {
                      text: "万载县",
                      value: "11409202"
                  },
                  {
                      text: "上高县",
                      value: "11409203"
                  },
                  {
                      text: "宜丰县",
                      value: "11409204"
                  },
                  {
                      text: "靖安县",
                      value: "11409205"
                  },
                  {
                      text: "铜鼓县",
                      value: "11409206"
                  },
                  {
                      text: "丰城市",
                      value: "11409301"
                  },
                  {
                      text: "樟树市",
                      value: "11409302"
                  },
                  {
                      text: "高安市",
                      value: "11409303"
                  }
              ],
              text: "宜春市",
              value: "11409000"
          },
          {
              children: [
                  {
                      text: "康巴什区",
                      value: "10506208"
                  },
                  {
                      text: "临川区",
                      value: "11410101"
                  },
                  {
                      text: "南城县",
                      value: "11410201"
                  },
                  {
                      text: "黎川县",
                      value: "11410202"
                  },
                  {
                      text: "南丰县",
                      value: "11410203"
                  },
                  {
                      text: "崇仁县",
                      value: "11410204"
                  },
                  {
                      text: "乐安县",
                      value: "11410205"
                  },
                  {
                      text: "宜黄县",
                      value: "11410206"
                  },
                  {
                      text: "金溪县",
                      value: "11410207"
                  },
                  {
                      text: "资溪县",
                      value: "11410208"
                  },
                  {
                      text: "东乡县",
                      value: "11410209"
                  },
                  {
                      text: "广昌县",
                      value: "11410210"
                  },
                  {
                      text: "东乡区",
                      value: "11410211"
                  }
              ],
              text: "抚州市",
              value: "11410000"
          },
          {
              children: [
                  {
                      text: "信州区",
                      value: "11411101"
                  },
                  {
                      text: "上饶县",
                      value: "11411201"
                  },
                  {
                      text: "广丰区",
                      value: "11411202"
                  },
                  {
                      text: "玉山县",
                      value: "11411203"
                  },
                  {
                      text: "铅山县",
                      value: "11411204"
                  },
                  {
                      text: "横峰县",
                      value: "11411205"
                  },
                  {
                      text: "弋阳县",
                      value: "11411206"
                  },
                  {
                      text: "余干县",
                      value: "11411207"
                  },
                  {
                      text: "鄱阳县",
                      value: "11411208"
                  },
                  {
                      text: "万年县",
                      value: "11411209"
                  },
                  {
                      text: "婺源县",
                      value: "11411210"
                  },
                  {
                      text: "广信区",
                      value: "11411211"
                  },
                  {
                      text: "德兴市",
                      value: "11411301"
                  }
              ],
              text: "上饶市",
              value: "11411000"
          }
      ],
      text: "江西省",
      value: "11400000"
  },
  {
      children: [
          {
              children: [
                  {
                      text: "和平区",
                      value: "10601101"
                  },
                  {
                      text: "沈河区",
                      value: "10601102"
                  },
                  {
                      text: "大东区",
                      value: "10601103"
                  },
                  {
                      text: "皇姑区",
                      value: "10601104"
                  },
                  {
                      text: "铁西区",
                      value: "10601105"
                  },
                  {
                      text: "苏家屯区",
                      value: "10601106"
                  },
                  {
                      text: "浑南区",
                      value: "10601107"
                  },
                  {
                      text: "沈北新区",
                      value: "10601108"
                  },
                  {
                      text: "于洪区",
                      value: "10601109"
                  },
                  {
                      text: "辽中区",
                      value: "10601110"
                  },
                  {
                      text: "康平县",
                      value: "10601202"
                  },
                  {
                      text: "法库县",
                      value: "10601203"
                  },
                  {
                      text: "新民市",
                      value: "10601301"
                  }
              ],
              text: "沈阳市",
              value: "10601000"
          },
          {
              children: [
                  {
                      text: "中山区",
                      value: "10602101"
                  },
                  {
                      text: "西岗区",
                      value: "10602102"
                  },
                  {
                      text: "沙河口区",
                      value: "10602103"
                  },
                  {
                      text: "甘井子区",
                      value: "10602104"
                  },
                  {
                      text: "旅顺口区",
                      value: "10602105"
                  },
                  {
                      text: "金州区",
                      value: "10602106"
                  },
                  {
                      text: "普兰店区",
                      value: "10602107"
                  },
                  {
                      text: "长海县",
                      value: "10602201"
                  },
                  {
                      text: "瓦房店市",
                      value: "10602301"
                  },
                  {
                      text: "庄河市",
                      value: "10602303"
                  }
              ],
              text: "大连市",
              value: "10602000"
          },
          {
              children: [
                  {
                      text: "铁东区",
                      value: "10603101"
                  },
                  {
                      text: "铁西区",
                      value: "10603102"
                  },
                  {
                      text: "立山区",
                      value: "10603103"
                  },
                  {
                      text: "千山区",
                      value: "10603104"
                  },
                  {
                      text: "台安县",
                      value: "10603201"
                  },
                  {
                      text: "岫岩满族自治县",
                      value: "10603202"
                  },
                  {
                      text: "海城市",
                      value: "10603301"
                  }
              ],
              text: "鞍山市",
              value: "10603000"
          },
          {
              children: [
                  {
                      text: "新抚区",
                      value: "10604101"
                  },
                  {
                      text: "东洲区",
                      value: "10604102"
                  },
                  {
                      text: "望花区",
                      value: "10604103"
                  },
                  {
                      text: "顺城区",
                      value: "10604104"
                  },
                  {
                      text: "抚顺县",
                      value: "10604201"
                  },
                  {
                      text: "新宾满族自治县",
                      value: "10604202"
                  },
                  {
                      text: "清原满族自治县",
                      value: "10604203"
                  }
              ],
              text: "抚顺市",
              value: "10604000"
          },
          {
              children: [
                  {
                      text: "平山区",
                      value: "10605101"
                  },
                  {
                      text: "溪湖区",
                      value: "10605102"
                  },
                  {
                      text: "明山区",
                      value: "10605103"
                  },
                  {
                      text: "南芬区",
                      value: "10605104"
                  },
                  {
                      text: "本溪满族自治县",
                      value: "10605201"
                  },
                  {
                      text: "桓仁满族自治县",
                      value: "10605202"
                  }
              ],
              text: "本溪市",
              value: "10605000"
          },
          {
              children: [
                  {
                      text: "元宝区",
                      value: "10606101"
                  },
                  {
                      text: "振兴区",
                      value: "10606102"
                  },
                  {
                      text: "振安区",
                      value: "10606103"
                  },
                  {
                      text: "宽甸满族自治县",
                      value: "10606201"
                  },
                  {
                      text: "东港市",
                      value: "10606301"
                  },
                  {
                      text: "凤城市",
                      value: "10606302"
                  }
              ],
              text: "丹东市",
              value: "10606000"
          },
          {
              children: [
                  {
                      text: "古塔区",
                      value: "10607101"
                  },
                  {
                      text: "凌河区",
                      value: "10607102"
                  },
                  {
                      text: "太和区",
                      value: "10607103"
                  },
                  {
                      text: "黑山县",
                      value: "10607201"
                  },
                  {
                      text: "义县",
                      value: "10607202"
                  },
                  {
                      text: "凌海市",
                      value: "10607301"
                  },
                  {
                      text: "北镇市",
                      value: "10607302"
                  }
              ],
              text: "锦州市",
              value: "10607000"
          },
          {
              children: [
                  {
                      text: "站前区",
                      value: "10608101"
                  },
                  {
                      text: "西市区",
                      value: "10608102"
                  },
                  {
                      text: "鲅鱼圈区",
                      value: "10608103"
                  },
                  {
                      text: "老边区",
                      value: "10608104"
                  },
                  {
                      text: "盖州市",
                      value: "10608301"
                  },
                  {
                      text: "大石桥市",
                      value: "10608302"
                  }
              ],
              text: "营口市",
              value: "10608000"
          },
          {
              children: [
                  {
                      text: "海州区",
                      value: "10609101"
                  },
                  {
                      text: "新邱区",
                      value: "10609102"
                  },
                  {
                      text: "太平区",
                      value: "10609103"
                  },
                  {
                      text: "清河门区",
                      value: "10609104"
                  },
                  {
                      text: "细河区",
                      value: "10609105"
                  },
                  {
                      text: "阜新蒙古族自治县",
                      value: "10609201"
                  },
                  {
                      text: "彰武县",
                      value: "10609202"
                  }
              ],
              text: "阜新市",
              value: "10609000"
          },
          {
              children: [
                  {
                      text: "白塔区",
                      value: "10610101"
                  },
                  {
                      text: "文圣区",
                      value: "10610102"
                  },
                  {
                      text: "宏伟区",
                      value: "10610103"
                  },
                  {
                      text: "弓长岭区",
                      value: "10610104"
                  },
                  {
                      text: "太子河区",
                      value: "10610105"
                  },
                  {
                      text: "辽阳县",
                      value: "10610201"
                  },
                  {
                      text: "灯塔市",
                      value: "10610301"
                  }
              ],
              text: "辽阳市",
              value: "10610000"
          },
          {
              children: [
                  {
                      text: "双台子区",
                      value: "10611101"
                  },
                  {
                      text: "兴隆台区",
                      value: "10611102"
                  },
                  {
                      text: "大洼区",
                      value: "10611103"
                  },
                  {
                      text: "盘山县",
                      value: "10611201"
                  }
              ],
              text: "盘锦市",
              value: "10611000"
          },
          {
              children: [
                  {
                      text: "银州区",
                      value: "10612101"
                  },
                  {
                      text: "清河区",
                      value: "10612102"
                  },
                  {
                      text: "铁岭县",
                      value: "10612201"
                  },
                  {
                      text: "西丰县",
                      value: "10612202"
                  },
                  {
                      text: "昌图县",
                      value: "10612203"
                  },
                  {
                      text: "调兵山市",
                      value: "10612301"
                  },
                  {
                      text: "开原市",
                      value: "10612302"
                  }
              ],
              text: "铁岭市",
              value: "10612000"
          },
          {
              children: [
                  {
                      text: "双塔区",
                      value: "10613101"
                  },
                  {
                      text: "龙城区",
                      value: "10613102"
                  },
                  {
                      text: "朝阳县",
                      value: "10613201"
                  },
                  {
                      text: "建平县",
                      value: "10613202"
                  },
                  {
                      text: "喀喇沁左翼蒙古族自治县",
                      value: "10613203"
                  },
                  {
                      text: "北票市",
                      value: "10613301"
                  },
                  {
                      text: "凌源市",
                      value: "10613302"
                  }
              ],
              text: "朝阳市",
              value: "10613000"
          },
          {
              children: [
                  {
                      text: "连山区",
                      value: "10614101"
                  },
                  {
                      text: "龙港区",
                      value: "10614102"
                  },
                  {
                      text: "南票区",
                      value: "10614103"
                  },
                  {
                      text: "绥中县",
                      value: "10614201"
                  },
                  {
                      text: "建昌县",
                      value: "10614202"
                  },
                  {
                      text: "兴城市",
                      value: "10614301"
                  }
              ],
              text: "葫芦岛市",
              value: "10614000"
          }
      ],
      text: "辽宁省",
      value: "10600000"
  },
  {
      children: [
          {
              children: [
                  {
                      text: "新城区",
                      value: "10501101"
                  },
                  {
                      text: "回民区",
                      value: "10501102"
                  },
                  {
                      text: "玉泉区",
                      value: "10501103"
                  },
                  {
                      text: "赛罕区",
                      value: "10501104"
                  },
                  {
                      text: "土默特左旗",
                      value: "10501201"
                  },
                  {
                      text: "托克托县",
                      value: "10501202"
                  },
                  {
                      text: "和林格尔县",
                      value: "10501203"
                  },
                  {
                      text: "清水河县",
                      value: "10501204"
                  },
                  {
                      text: "武川县",
                      value: "10501205"
                  }
              ],
              text: "呼和浩特市",
              value: "10501000"
          },
          {
              children: [
                  {
                      text: "东河区",
                      value: "10502101"
                  },
                  {
                      text: "昆都仑区",
                      value: "10502102"
                  },
                  {
                      text: "青山区",
                      value: "10502103"
                  },
                  {
                      text: "石拐区",
                      value: "10502104"
                  },
                  {
                      text: "白云鄂博矿区",
                      value: "10502105"
                  },
                  {
                      text: "九原区",
                      value: "10502106"
                  },
                  {
                      text: "土默特右旗",
                      value: "10502201"
                  },
                  {
                      text: "固阳县",
                      value: "10502202"
                  },
                  {
                      text: "达尔罕茂明安联合旗",
                      value: "10502203"
                  }
              ],
              text: "包头市",
              value: "10502000"
          },
          {
              children: [
                  {
                      text: "海勃湾区",
                      value: "10503101"
                  },
                  {
                      text: "海南区",
                      value: "10503102"
                  },
                  {
                      text: "乌达区",
                      value: "10503103"
                  }
              ],
              text: "乌海市",
              value: "10503000"
          },
          {
              children: [
                  {
                      text: "红山区",
                      value: "10504001"
                  },
                  {
                      text: "元宝山区",
                      value: "10504002"
                  },
                  {
                      text: "松山区",
                      value: "10504003"
                  },
                  {
                      text: "阿鲁科尔沁旗",
                      value: "10504201"
                  },
                  {
                      text: "巴林左旗",
                      value: "10504202"
                  },
                  {
                      text: "巴林右旗",
                      value: "10504203"
                  },
                  {
                      text: "林西县",
                      value: "10504204"
                  },
                  {
                      text: "克什克腾旗",
                      value: "10504205"
                  },
                  {
                      text: "翁牛特旗",
                      value: "10504206"
                  },
                  {
                      text: "喀喇沁旗",
                      value: "10504207"
                  },
                  {
                      text: "宁城县",
                      value: "10504208"
                  },
                  {
                      text: "敖汉旗",
                      value: "10504209"
                  }
              ],
              text: "赤峰市",
              value: "10504000"
          },
          {
              children: [
                  {
                      text: "科尔沁区",
                      value: "10505101"
                  },
                  {
                      text: "科尔沁左翼中旗",
                      value: "10505201"
                  },
                  {
                      text: "科尔沁左翼后旗",
                      value: "10505202"
                  },
                  {
                      text: "开鲁县",
                      value: "10505203"
                  },
                  {
                      text: "库伦旗",
                      value: "10505204"
                  },
                  {
                      text: "奈曼旗",
                      value: "10505205"
                  },
                  {
                      text: "扎鲁特旗",
                      value: "10505206"
                  },
                  {
                      text: "霍林郭勒市",
                      value: "10505301"
                  }
              ],
              text: "通辽市",
              value: "10505000"
          },
          {
              children: [
                  {
                      text: "东胜区",
                      value: "10506101"
                  },
                  {
                      text: "达拉特旗",
                      value: "10506201"
                  },
                  {
                      text: "准格尔旗",
                      value: "10506202"
                  },
                  {
                      text: "鄂托克前旗",
                      value: "10506203"
                  },
                  {
                      text: "鄂托克旗",
                      value: "10506204"
                  },
                  {
                      text: "杭锦旗",
                      value: "10506205"
                  },
                  {
                      text: "乌审旗",
                      value: "10506206"
                  },
                  {
                      text: "伊金霍洛旗",
                      value: "10506207"
                  }
              ],
              text: "鄂尔多斯市",
              value: "10506000"
          },
          {
              children: [
                  {
                      text: "海拉尔区",
                      value: "10507101"
                  },
                  {
                      text: "扎赉诺尔区",
                      value: "10507102"
                  },
                  {
                      text: "阿荣旗",
                      value: "10507201"
                  },
                  {
                      text: "莫力达瓦达斡尔族自治旗",
                      value: "10507202"
                  },
                  {
                      text: "鄂伦春自治旗",
                      value: "10507203"
                  },
                  {
                      text: "鄂温克族自治旗",
                      value: "10507204"
                  },
                  {
                      text: "陈巴尔虎旗",
                      value: "10507205"
                  },
                  {
                      text: "新巴尔虎左旗",
                      value: "10507206"
                  },
                  {
                      text: "新巴尔虎右旗",
                      value: "10507207"
                  },
                  {
                      text: "满洲里市",
                      value: "10507301"
                  },
                  {
                      text: "牙克石市",
                      value: "10507302"
                  },
                  {
                      text: "扎兰屯市",
                      value: "10507303"
                  },
                  {
                      text: "额尔古纳市",
                      value: "10507304"
                  },
                  {
                      text: "根河市",
                      value: "10507305"
                  }
              ],
              text: "呼伦贝尔市",
              value: "10507000"
          },
          {
              children: [
                  {
                      text: "临河区",
                      value: "10508101"
                  },
                  {
                      text: "五原县",
                      value: "10508201"
                  },
                  {
                      text: "磴口县",
                      value: "10508202"
                  },
                  {
                      text: "乌拉特前旗",
                      value: "10508203"
                  },
                  {
                      text: "乌拉特中旗",
                      value: "10508204"
                  },
                  {
                      text: "乌拉特后旗",
                      value: "10508205"
                  },
                  {
                      text: "杭锦后旗",
                      value: "10508206"
                  }
              ],
              text: "巴彦淖尔市",
              value: "10508000"
          },
          {
              children: [
                  {
                      text: "集宁区",
                      value: "10509101"
                  },
                  {
                      text: "卓资县",
                      value: "10509201"
                  },
                  {
                      text: "化德县",
                      value: "10509202"
                  },
                  {
                      text: "商都县",
                      value: "10509203"
                  },
                  {
                      text: "兴和县",
                      value: "10509204"
                  },
                  {
                      text: "凉城县",
                      value: "10509205"
                  },
                  {
                      text: "察哈尔右翼前旗",
                      value: "10509206"
                  },
                  {
                      text: "察哈尔右翼中旗",
                      value: "10509207"
                  },
                  {
                      text: "察哈尔右翼后旗",
                      value: "10509208"
                  },
                  {
                      text: "四子王旗",
                      value: "10509209"
                  },
                  {
                      text: "丰镇市",
                      value: "10509301"
                  }
              ],
              text: "乌兰察布市",
              value: "10509000"
          },
          {
              children: [
                  {
                      text: "科尔沁右翼前旗",
                      value: "10510201"
                  },
                  {
                      text: "科尔沁右翼中旗",
                      value: "10510202"
                  },
                  {
                      text: "扎赉特旗",
                      value: "10510203"
                  },
                  {
                      text: "突泉县",
                      value: "10510204"
                  },
                  {
                      text: "乌兰浩特市",
                      value: "10510301"
                  },
                  {
                      text: "阿尔山市",
                      value: "10510302"
                  }
              ],
              text: "兴安盟",
              value: "10510000"
          },
          {
              children: [
                  {
                      text: "阿巴嘎旗",
                      value: "10511201"
                  },
                  {
                      text: "苏尼特左旗",
                      value: "10511202"
                  },
                  {
                      text: "苏尼特右旗",
                      value: "10511203"
                  },
                  {
                      text: "东乌珠穆沁旗",
                      value: "10511204"
                  },
                  {
                      text: "西乌珠穆沁旗",
                      value: "10511205"
                  },
                  {
                      text: "太仆寺旗",
                      value: "10511206"
                  },
                  {
                      text: "镶黄旗",
                      value: "10511207"
                  },
                  {
                      text: "正镶白旗",
                      value: "10511208"
                  },
                  {
                      text: "正蓝旗",
                      value: "10511209"
                  },
                  {
                      text: "多伦县",
                      value: "10511210"
                  },
                  {
                      text: "二连浩特市",
                      value: "10511301"
                  },
                  {
                      text: "锡林浩特市",
                      value: "10511302"
                  }
              ],
              text: "锡林郭勒盟",
              value: "10511000"
          },
          {
              children: [
                  {
                      text: "阿拉善左旗",
                      value: "10512201"
                  },
                  {
                      text: "阿拉善右旗",
                      value: "10512202"
                  },
                  {
                      text: "额济纳旗",
                      value: "10512203"
                  }
              ],
              text: "阿拉善盟",
              value: "10512000"
          }
      ],
      text: "内蒙古自治区",
      value: "10500000"
  },
  {
      children: [
          {
              children: [
                  {
                      text: "兴庆区",
                      value: "13001101"
                  },
                  {
                      text: "西夏区",
                      value: "13001102"
                  },
                  {
                      text: "金凤区",
                      value: "13001103"
                  },
                  {
                      text: "永宁县",
                      value: "13001201"
                  },
                  {
                      text: "贺兰县",
                      value: "13001202"
                  },
                  {
                      text: "灵武市",
                      value: "13001301"
                  }
              ],
              text: "银川市",
              value: "13001000"
          },
          {
              children: [
                  {
                      text: "大武口区",
                      value: "13002101"
                  },
                  {
                      text: "惠农区",
                      value: "13002102"
                  },
                  {
                      text: "平罗县",
                      value: "13002201"
                  }
              ],
              text: "石嘴山市",
              value: "13002000"
          },
          {
              children: [
                  {
                      text: "利通区",
                      value: "13003101"
                  },
                  {
                      text: "红寺堡区",
                      value: "13003102"
                  },
                  {
                      text: "盐池县",
                      value: "13003201"
                  },
                  {
                      text: "同心县",
                      value: "13003202"
                  },
                  {
                      text: "青铜峡市",
                      value: "13003301"
                  }
              ],
              text: "吴忠市",
              value: "13003000"
          },
          {
              children: [
                  {
                      text: "原州区",
                      value: "13004101"
                  },
                  {
                      text: "西吉县",
                      value: "13004201"
                  },
                  {
                      text: "隆德县",
                      value: "13004202"
                  },
                  {
                      text: "泾源县",
                      value: "13004203"
                  },
                  {
                      text: "彭阳县",
                      value: "13004204"
                  }
              ],
              text: "固原市",
              value: "13004000"
          },
          {
              children: [
                  {
                      text: "沙坡头区",
                      value: "13005101"
                  },
                  {
                      text: "中宁县",
                      value: "13005201"
                  },
                  {
                      text: "海原县",
                      value: "13005202"
                  }
              ],
              text: "中卫市",
              value: "13005000"
          }
      ],
      text: "宁夏回族自治区",
      value: "13000000"
  },
  {
      children: [
          {
              children: [
                  {
                      text: "城东区",
                      value: "12901101"
                  },
                  {
                      text: "城中区",
                      value: "12901102"
                  },
                  {
                      text: "城西区",
                      value: "12901103"
                  },
                  {
                      text: "城北区",
                      value: "12901104"
                  },
                  {
                      text: "大通回族土族自治县",
                      value: "12901201"
                  },
                  {
                      text: "湟中区",
                      value: "12901202"
                  },
                  {
                      text: "湟源县",
                      value: "12901203"
                  }
              ],
              text: "西宁市",
              value: "12901000"
          },
          {
              children: [
                  {
                      text: "平安区",
                      value: "12902201"
                  },
                  {
                      text: "民和回族土族自治县",
                      value: "12902202"
                  },
                  {
                      text: "乐都区",
                      value: "12902203"
                  },
                  {
                      text: "互助土族自治县",
                      value: "12902204"
                  },
                  {
                      text: "化隆回族自治县",
                      value: "12902205"
                  },
                  {
                      text: "循化撒拉族自治县",
                      value: "12902206"
                  }
              ],
              text: "海东市",
              value: "12902000"
          },
          {
              children: [
                  {
                      text: "门源回族自治县",
                      value: "12903208"
                  },
                  {
                      text: "祁连县",
                      value: "12903209"
                  },
                  {
                      text: "海晏县",
                      value: "12903210"
                  },
                  {
                      text: "刚察县",
                      value: "12903211"
                  }
              ],
              text: "海北藏族自治州",
              value: "12903000"
          },
          {
              children: [
                  {
                      text: "同仁县",
                      value: "12904201"
                  },
                  {
                      text: "尖扎县",
                      value: "12904202"
                  },
                  {
                      text: "泽库县",
                      value: "12904203"
                  },
                  {
                      text: "河南蒙古族自治县",
                      value: "12904204"
                  }
              ],
              text: "黄南藏族自治州",
              value: "12904000"
          },
          {
              children: [
                  {
                      text: "共和县",
                      value: "12905201"
                  },
                  {
                      text: "同德县",
                      value: "12905202"
                  },
                  {
                      text: "贵德县",
                      value: "12905203"
                  },
                  {
                      text: "兴海县",
                      value: "12905204"
                  },
                  {
                      text: "贵南县",
                      value: "12905205"
                  }
              ],
              text: "海南藏族自治州",
              value: "12905000"
          },
          {
              children: [
                  {
                      text: "玛沁县",
                      value: "12906201"
                  },
                  {
                      text: "班玛县",
                      value: "12906202"
                  },
                  {
                      text: "甘德县",
                      value: "12906203"
                  },
                  {
                      text: "达日县",
                      value: "12906204"
                  },
                  {
                      text: "久治县",
                      value: "12906205"
                  },
                  {
                      text: "玛多县",
                      value: "12906206"
                  }
              ],
              text: "果洛藏族自治州",
              value: "12906000"
          },
          {
              children: [
                  {
                      text: "玉树市",
                      value: "12907201"
                  },
                  {
                      text: "杂多县",
                      value: "12907202"
                  },
                  {
                      text: "称多县",
                      value: "12907203"
                  },
                  {
                      text: "治多县",
                      value: "12907204"
                  },
                  {
                      text: "囊谦县",
                      value: "12907205"
                  },
                  {
                      text: "曲麻莱县",
                      value: "12907206"
                  }
              ],
              text: "玉树藏族自治州",
              value: "12907000"
          },
          {
              children: [
                  {
                      text: "乌兰县",
                      value: "12908201"
                  },
                  {
                      text: "都兰县",
                      value: "12908202"
                  },
                  {
                      text: "天峻县",
                      value: "12908203"
                  },
                  {
                      text: "海西蒙古族藏族自治州直辖",
                      value: "12908204"
                  },
                  {
                      text: "格尔木市",
                      value: "12908301"
                  },
                  {
                      text: "德令哈市",
                      value: "12908302"
                  }
              ],
              text: "海西蒙古族藏族自治州",
              value: "12908000"
          }
      ],
      text: "青海省",
      value: "12900000"
  },
  {
      children: [
          {
              children: [
                  {
                      text: "锦江区",
                      value: "12301101"
                  },
                  {
                      text: "青羊区",
                      value: "12301102"
                  },
                  {
                      text: "金牛区",
                      value: "12301103"
                  },
                  {
                      text: "武侯区",
                      value: "12301104"
                  },
                  {
                      text: "成华区",
                      value: "12301105"
                  },
                  {
                      text: "龙泉驿区",
                      value: "12301106"
                  },
                  {
                      text: "青白江区",
                      value: "12301107"
                  },
                  {
                      text: "新都区",
                      value: "12301108"
                  },
                  {
                      text: "温江区",
                      value: "12301109"
                  },
                  {
                      text: "双流区",
                      value: "12301110"
                  },
                  {
                      text: "金堂县",
                      value: "12301201"
                  },
                  {
                      text: "郫都区",
                      value: "12301203"
                  },
                  {
                      text: "大邑县",
                      value: "12301204"
                  },
                  {
                      text: "蒲江县",
                      value: "12301205"
                  },
                  {
                      text: "新津县",
                      value: "12301206"
                  },
                  {
                      text: "都江堰市",
                      value: "12301301"
                  },
                  {
                      text: "彭州市",
                      value: "12301302"
                  },
                  {
                      text: "邛崃市",
                      value: "12301303"
                  },
                  {
                      text: "崇州市",
                      value: "12301304"
                  },
                  {
                      text: "简阳市",
                      value: "12301305"
                  }
              ],
              text: "成都市",
              value: "12301000"
          },
          {
              children: [
                  {
                      text: "自流井区",
                      value: "12302101"
                  },
                  {
                      text: "贡井区",
                      value: "12302102"
                  },
                  {
                      text: "大安区",
                      value: "12302103"
                  },
                  {
                      text: "沿滩区",
                      value: "12302104"
                  },
                  {
                      text: "荣县",
                      value: "12302201"
                  },
                  {
                      text: "富顺县",
                      value: "12302202"
                  }
              ],
              text: "自贡市",
              value: "12302000"
          },
          {
              children: [
                  {
                      text: "东区",
                      value: "12303101"
                  },
                  {
                      text: "西区",
                      value: "12303102"
                  },
                  {
                      text: "仁和区",
                      value: "12303103"
                  },
                  {
                      text: "米易县",
                      value: "12303201"
                  },
                  {
                      text: "盐边县",
                      value: "12303202"
                  }
              ],
              text: "攀枝花市",
              value: "12303000"
          },
          {
              children: [
                  {
                      text: "江阳区",
                      value: "12304101"
                  },
                  {
                      text: "纳溪区",
                      value: "12304102"
                  },
                  {
                      text: "龙马潭区",
                      value: "12304103"
                  },
                  {
                      text: "泸县",
                      value: "12304104"
                  },
                  {
                      text: "合江县",
                      value: "12304201"
                  },
                  {
                      text: "叙永县",
                      value: "12304202"
                  },
                  {
                      text: "古蔺县",
                      value: "12304203"
                  }
              ],
              text: "泸州市",
              value: "12304000"
          },
          {
              children: [
                  {
                      text: "旌阳区",
                      value: "12305101"
                  },
                  {
                      text: "中江县",
                      value: "12305201"
                  },
                  {
                      text: "罗江县",
                      value: "12305202"
                  },
                  {
                      text: "广汉市",
                      value: "12305301"
                  },
                  {
                      text: "什邡市",
                      value: "12305302"
                  },
                  {
                      text: "绵竹市",
                      value: "12305303"
                  }
              ],
              text: "德阳市",
              value: "12305000"
          },
          {
              children: [
                  {
                      text: "涪城区",
                      value: "12306101"
                  },
                  {
                      text: "游仙区",
                      value: "12306102"
                  },
                  {
                      text: "安州区",
                      value: "12306103"
                  },
                  {
                      text: "三台县",
                      value: "12306201"
                  },
                  {
                      text: "盐亭县",
                      value: "12306202"
                  },
                  {
                      text: "安县",
                      value: "12306203"
                  },
                  {
                      text: "梓潼县",
                      value: "12306204"
                  },
                  {
                      text: "北川羌族自治县",
                      value: "12306205"
                  },
                  {
                      text: "平武县",
                      value: "12306206"
                  },
                  {
                      text: "江油市",
                      value: "12306301"
                  }
              ],
              text: "绵阳市",
              value: "12306000"
          },
          {
              children: [
                  {
                      text: "利州区",
                      value: "12307101"
                  },
                  {
                      text: "昭化区",
                      value: "12307102"
                  },
                  {
                      text: "朝天区",
                      value: "12307103"
                  },
                  {
                      text: "旺苍县",
                      value: "12307201"
                  },
                  {
                      text: "青川县",
                      value: "12307202"
                  },
                  {
                      text: "剑阁县",
                      value: "12307203"
                  },
                  {
                      text: "苍溪县",
                      value: "12307204"
                  }
              ],
              text: "广元市",
              value: "12307000"
          },
          {
              children: [
                  {
                      text: "船山区",
                      value: "12308101"
                  },
                  {
                      text: "安居区",
                      value: "12308102"
                  },
                  {
                      text: "蓬溪县",
                      value: "12308201"
                  },
                  {
                      text: "射洪市",
                      value: "12308202"
                  },
                  {
                      text: "大英县",
                      value: "12308203"
                  }
              ],
              text: "遂宁市",
              value: "12308000"
          },
          {
              children: [
                  {
                      text: "市中区",
                      value: "12309101"
                  },
                  {
                      text: "东兴区",
                      value: "12309102"
                  },
                  {
                      text: "威远县",
                      value: "12309201"
                  },
                  {
                      text: "资中县",
                      value: "12309202"
                  },
                  {
                      text: "隆昌市",
                      value: "12309203"
                  },
                  {
                      text: "隆昌市",
                      value: "12309204"
                  }
              ],
              text: "内江市",
              value: "12309000"
          },
          {
              children: [
                  {
                      text: "市中区",
                      value: "12310101"
                  },
                  {
                      text: "沙湾区",
                      value: "12310102"
                  },
                  {
                      text: "五通桥区",
                      value: "12310103"
                  },
                  {
                      text: "金口河区",
                      value: "12310104"
                  },
                  {
                      text: "犍为县",
                      value: "12310201"
                  },
                  {
                      text: "井研县",
                      value: "12310202"
                  },
                  {
                      text: "夹江县",
                      value: "12310203"
                  },
                  {
                      text: "沐川县",
                      value: "12310204"
                  },
                  {
                      text: "峨边彝族自治县",
                      value: "12310205"
                  },
                  {
                      text: "马边彝族自治县",
                      value: "12310206"
                  },
                  {
                      text: "峨眉山市",
                      value: "12310301"
                  }
              ],
              text: "乐山市",
              value: "12310000"
          },
          {
              children: [
                  {
                      text: "顺庆区",
                      value: "12311101"
                  },
                  {
                      text: "高坪区",
                      value: "12311102"
                  },
                  {
                      text: "嘉陵区",
                      value: "12311103"
                  },
                  {
                      text: "南部县",
                      value: "12311201"
                  },
                  {
                      text: "营山县",
                      value: "12311202"
                  },
                  {
                      text: "蓬安县",
                      value: "12311203"
                  },
                  {
                      text: "仪陇县",
                      value: "12311204"
                  },
                  {
                      text: "西充县",
                      value: "12311205"
                  },
                  {
                      text: "阆中市",
                      value: "12311301"
                  }
              ],
              text: "南充市",
              value: "12311000"
          },
          {
              children: [
                  {
                      text: "东坡区",
                      value: "12312101"
                  },
                  {
                      text: "仁寿县",
                      value: "12312201"
                  },
                  {
                      text: "彭山区",
                      value: "12312202"
                  },
                  {
                      text: "洪雅县",
                      value: "12312203"
                  },
                  {
                      text: "丹棱县",
                      value: "12312204"
                  },
                  {
                      text: "青神县",
                      value: "12312205"
                  }
              ],
              text: "眉山市",
              value: "12312000"
          },
          {
              children: [
                  {
                      text: "翠屏区",
                      value: "12313101"
                  },
                  {
                      text: "宜宾县",
                      value: "12313201"
                  },
                  {
                      text: "南溪区",
                      value: "12313202"
                  },
                  {
                      text: "江安县",
                      value: "12313203"
                  },
                  {
                      text: "长宁县",
                      value: "12313204"
                  },
                  {
                      text: "高县",
                      value: "12313205"
                  },
                  {
                      text: "珙县",
                      value: "12313206"
                  },
                  {
                      text: "筠连县",
                      value: "12313207"
                  },
                  {
                      text: "兴文县",
                      value: "12313208"
                  },
                  {
                      text: "屏山县",
                      value: "12313209"
                  },
                  {
                      text: "叙州区",
                      value: "12313210"
                  }
              ],
              text: "宜宾市",
              value: "12313000"
          },
          {
              children: [
                  {
                      text: "广安区",
                      value: "12314101"
                  },
                  {
                      text: "前锋区",
                      value: "12314102"
                  },
                  {
                      text: "岳池县",
                      value: "12314201"
                  },
                  {
                      text: "武胜县",
                      value: "12314202"
                  },
                  {
                      text: "邻水县",
                      value: "12314203"
                  },
                  {
                      text: "华蓥市",
                      value: "12314301"
                  }
              ],
              text: "广安市",
              value: "12314000"
          },
          {
              children: [
                  {
                      text: "通川区",
                      value: "12315101"
                  },
                  {
                      text: "达川区",
                      value: "12315201"
                  },
                  {
                      text: "宣汉县",
                      value: "12315202"
                  },
                  {
                      text: "开江县",
                      value: "12315203"
                  },
                  {
                      text: "大竹县",
                      value: "12315204"
                  },
                  {
                      text: "渠县",
                      value: "12315205"
                  },
                  {
                      text: "万源市",
                      value: "12315301"
                  }
              ],
              text: "达州市",
              value: "12315000"
          },
          {
              children: [
                  {
                      text: "雨城区",
                      value: "12316101"
                  },
                  {
                      text: "名山区",
                      value: "12316201"
                  },
                  {
                      text: "荥经县",
                      value: "12316202"
                  },
                  {
                      text: "汉源县",
                      value: "12316203"
                  },
                  {
                      text: "石棉县",
                      value: "12316204"
                  },
                  {
                      text: "天全县",
                      value: "12316205"
                  },
                  {
                      text: "芦山县",
                      value: "12316206"
                  },
                  {
                      text: "宝兴县",
                      value: "12316207"
                  }
              ],
              text: "雅安市",
              value: "12316000"
          },
          {
              children: [
                  {
                      text: "巴州区",
                      value: "12317101"
                  },
                  {
                      text: "恩阳区",
                      value: "12317102"
                  },
                  {
                      text: "通江县",
                      value: "12317201"
                  },
                  {
                      text: "南江县",
                      value: "12317202"
                  },
                  {
                      text: "平昌县",
                      value: "12317203"
                  }
              ],
              text: "巴中市",
              value: "12317000"
          },
          {
              children: [
                  {
                      text: "雁江区",
                      value: "12318101"
                  },
                  {
                      text: "安岳县",
                      value: "12318201"
                  },
                  {
                      text: "乐至县",
                      value: "12318202"
                  }
              ],
              text: "资阳市",
              value: "12318000"
          },
          {
              children: [
                  {
                      text: "汶川县",
                      value: "12319101"
                  },
                  {
                      text: "理县",
                      value: "12319102"
                  },
                  {
                      text: "茂县",
                      value: "12319103"
                  },
                  {
                      text: "松潘县",
                      value: "12319104"
                  },
                  {
                      text: "九寨沟县",
                      value: "12319105"
                  },
                  {
                      text: "金川县",
                      value: "12319106"
                  },
                  {
                      text: "小金县",
                      value: "12319107"
                  },
                  {
                      text: "黑水县",
                      value: "12319108"
                  },
                  {
                      text: "马尔康县",
                      value: "12319109"
                  },
                  {
                      text: "壤塘县",
                      value: "12319110"
                  },
                  {
                      text: "阿坝县",
                      value: "12319111"
                  },
                  {
                      text: "若尔盖县",
                      value: "12319112"
                  },
                  {
                      text: "红原县",
                      value: "12319113"
                  },
                  {
                      text: "马尔康市",
                      value: "12319201"
                  }
              ],
              text: "阿坝藏族羌族自治州",
              value: "12319000"
          },
          {
              children: [
                  {
                      text: "康定市",
                      value: "12320101"
                  },
                  {
                      text: "泸定县",
                      value: "12320102"
                  },
                  {
                      text: "丹巴县",
                      value: "12320103"
                  },
                  {
                      text: "九龙县",
                      value: "12320104"
                  },
                  {
                      text: "雅江县",
                      value: "12320105"
                  },
                  {
                      text: "道孚县",
                      value: "12320106"
                  },
                  {
                      text: "炉霍县",
                      value: "12320107"
                  },
                  {
                      text: "甘孜县",
                      value: "12320108"
                  },
                  {
                      text: "新龙县",
                      value: "12320109"
                  },
                  {
                      text: "德格县",
                      value: "12320110"
                  },
                  {
                      text: "白玉县",
                      value: "12320111"
                  },
                  {
                      text: "石渠县",
                      value: "12320112"
                  },
                  {
                      text: "色达县",
                      value: "12320113"
                  },
                  {
                      text: "理塘县",
                      value: "12320114"
                  },
                  {
                      text: "巴塘县",
                      value: "12320115"
                  },
                  {
                      text: "乡城县",
                      value: "12320116"
                  },
                  {
                      text: "稻城县",
                      value: "12320117"
                  },
                  {
                      text: "得荣县",
                      value: "12320118"
                  }
              ],
              text: "甘孜藏族自治州",
              value: "12320000"
          },
          {
              children: [
                  {
                      text: "木里藏族自治县",
                      value: "12321101"
                  },
                  {
                      text: "盐源县",
                      value: "12321102"
                  },
                  {
                      text: "德昌县",
                      value: "12321103"
                  },
                  {
                      text: "会理县",
                      value: "12321104"
                  },
                  {
                      text: "会东县",
                      value: "12321105"
                  },
                  {
                      text: "宁南县",
                      value: "12321106"
                  },
                  {
                      text: "普格县",
                      value: "12321107"
                  },
                  {
                      text: "布拖县",
                      value: "12321108"
                  },
                  {
                      text: "金阳县",
                      value: "12321109"
                  },
                  {
                      text: "昭觉县",
                      value: "12321110"
                  },
                  {
                      text: "喜德县",
                      value: "12321111"
                  },
                  {
                      text: "冕宁县",
                      value: "12321112"
                  },
                  {
                      text: "越西县",
                      value: "12321113"
                  },
                  {
                      text: "甘洛县",
                      value: "12321114"
                  },
                  {
                      text: "美姑县",
                      value: "12321115"
                  },
                  {
                      text: "雷波县",
                      value: "12321116"
                  },
                  {
                      text: "西昌市",
                      value: "12321201"
                  }
              ],
              text: "凉山彝族自治州",
              value: "12321000"
          }
      ],
      text: "四川省",
      value: "12300000"
  },
  {
      children: [
          {
              children: [
                  {
                      text: "历下区",
                      value: "11501101"
                  },
                  {
                      text: "市中区",
                      value: "11501102"
                  },
                  {
                      text: "槐荫区",
                      value: "11501103"
                  },
                  {
                      text: "天桥区",
                      value: "11501104"
                  },
                  {
                      text: "历城区",
                      value: "11501105"
                  },
                  {
                      text: "长清区",
                      value: "11501106"
                  },
                  {
                      text: "平阴县",
                      value: "11501201"
                  },
                  {
                      text: "济阳区",
                      value: "11501202"
                  },
                  {
                      text: "商河县",
                      value: "11501203"
                  },
                  {
                      text: "章丘区",
                      value: "11501301"
                  },
                  {
                      text: "莱芜区",
                      value: "11501302"
                  },
                  {
                      text: "钢城区",
                      value: "11501303"
                  }
              ],
              text: "济南市",
              value: "11501000"
          },
          {
              children: [
                  {
                      text: "市南区",
                      value: "11502101"
                  },
                  {
                      text: "市北区",
                      value: "11502102"
                  },
                  {
                      text: "黄岛区",
                      value: "11502104"
                  },
                  {
                      text: "崂山区",
                      value: "11502105"
                  },
                  {
                      text: "李沧区",
                      value: "11502106"
                  },
                  {
                      text: "城阳区",
                      value: "11502107"
                  },
                  {
                      text: "胶州市",
                      value: "11502301"
                  },
                  {
                      text: "即墨区",
                      value: "11502302"
                  },
                  {
                      text: "平度市",
                      value: "11502303"
                  },
                  {
                      text: "莱西市",
                      value: "11502305"
                  }
              ],
              text: "青岛市",
              value: "11502000"
          },
          {
              children: [
                  {
                      text: "淄川区",
                      value: "11503101"
                  },
                  {
                      text: "张店区",
                      value: "11503102"
                  },
                  {
                      text: "博山区",
                      value: "11503103"
                  },
                  {
                      text: "临淄区",
                      value: "11503104"
                  },
                  {
                      text: "周村区",
                      value: "11503105"
                  },
                  {
                      text: "桓台县",
                      value: "11503201"
                  },
                  {
                      text: "高青县",
                      value: "11503202"
                  },
                  {
                      text: "沂源县",
                      value: "11503203"
                  }
              ],
              text: "淄博市",
              value: "11503000"
          },
          {
              children: [
                  {
                      text: "市中区",
                      value: "11504101"
                  },
                  {
                      text: "薛城区",
                      value: "11504102"
                  },
                  {
                      text: "峄城区",
                      value: "11504103"
                  },
                  {
                      text: "台儿庄区",
                      value: "11504104"
                  },
                  {
                      text: "山亭区",
                      value: "11504105"
                  },
                  {
                      text: "滕州市",
                      value: "11504301"
                  }
              ],
              text: "枣庄市",
              value: "11504000"
          },
          {
              children: [
                  {
                      text: "东营区",
                      value: "11505101"
                  },
                  {
                      text: "河口区",
                      value: "11505102"
                  },
                  {
                      text: "垦利县",
                      value: "11505201"
                  },
                  {
                      text: "利津县",
                      value: "11505202"
                  },
                  {
                      text: "广饶县",
                      value: "11505203"
                  }
              ],
              text: "东营市",
              value: "11505000"
          },
          {
              children: [
                  {
                      text: "芝罘区",
                      value: "11506101"
                  },
                  {
                      text: "福山区",
                      value: "11506102"
                  },
                  {
                      text: "牟平区",
                      value: "11506103"
                  },
                  {
                      text: "莱山区",
                      value: "11506104"
                  },
                  {
                      text: "长岛县",
                      value: "11506201"
                  },
                  {
                      text: "龙口市",
                      value: "11506301"
                  },
                  {
                      text: "莱阳市",
                      value: "11506302"
                  },
                  {
                      text: "莱州市",
                      value: "11506303"
                  },
                  {
                      text: "蓬莱区",
                      value: "11506304"
                  },
                  {
                      text: "招远市",
                      value: "11506305"
                  },
                  {
                      text: "栖霞市",
                      value: "11506306"
                  },
                  {
                      text: "海阳市",
                      value: "11506307"
                  }
              ],
              text: "烟台市",
              value: "11506000"
          },
          {
              children: [
                  {
                      text: "潍城区",
                      value: "11507101"
                  },
                  {
                      text: "寒亭区",
                      value: "11507102"
                  },
                  {
                      text: "坊子区",
                      value: "11507103"
                  },
                  {
                      text: "奎文区",
                      value: "11507104"
                  },
                  {
                      text: "临朐县",
                      value: "11507201"
                  },
                  {
                      text: "昌乐县",
                      value: "11507202"
                  },
                  {
                      text: "青州市",
                      value: "11507301"
                  },
                  {
                      text: "诸城市",
                      value: "11507302"
                  },
                  {
                      text: "寿光市",
                      value: "11507303"
                  },
                  {
                      text: "安丘市",
                      value: "11507304"
                  },
                  {
                      text: "高密市",
                      value: "11507305"
                  },
                  {
                      text: "昌邑市",
                      value: "11507306"
                  }
              ],
              text: "潍坊市",
              value: "11507000"
          },
          {
              children: [
                  {
                      text: "任城区",
                      value: "11508102"
                  },
                  {
                      text: "微山县",
                      value: "11508201"
                  },
                  {
                      text: "鱼台县",
                      value: "11508202"
                  },
                  {
                      text: "金乡县",
                      value: "11508203"
                  },
                  {
                      text: "嘉祥县",
                      value: "11508204"
                  },
                  {
                      text: "汶上县",
                      value: "11508205"
                  },
                  {
                      text: "泗水县",
                      value: "11508206"
                  },
                  {
                      text: "梁山县",
                      value: "11508207"
                  },
                  {
                      text: "曲阜市",
                      value: "11508301"
                  },
                  {
                      text: "兖州区",
                      value: "11508302"
                  },
                  {
                      text: "邹城市",
                      value: "11508303"
                  }
              ],
              text: "济宁市",
              value: "11508000"
          },
          {
              children: [
                  {
                      text: "泰山区",
                      value: "11509101"
                  },
                  {
                      text: "岱岳区",
                      value: "11509102"
                  },
                  {
                      text: "宁阳县",
                      value: "11509201"
                  },
                  {
                      text: "东平县",
                      value: "11509202"
                  },
                  {
                      text: "新泰市",
                      value: "11509301"
                  },
                  {
                      text: "肥城市",
                      value: "11509302"
                  }
              ],
              text: "泰安市",
              value: "11509000"
          },
          {
              children: [
                  {
                      text: "环翠区",
                      value: "11510101"
                  },
                  {
                      text: "文登区",
                      value: "11510301"
                  },
                  {
                      text: "荣成市",
                      value: "11510302"
                  },
                  {
                      text: "乳山市",
                      value: "11510303"
                  }
              ],
              text: "威海市",
              value: "11510000"
          },
          {
              children: [
                  {
                      text: "东港区",
                      value: "11511101"
                  },
                  {
                      text: "岚山区",
                      value: "11511102"
                  },
                  {
                      text: "五莲县",
                      value: "11511201"
                  },
                  {
                      text: "莒县",
                      value: "11511202"
                  }
              ],
              text: "日照市",
              value: "11511000"
          },
          {
              children: [
                  {
                      text: "兰山区",
                      value: "11513101"
                  },
                  {
                      text: "罗庄区",
                      value: "11513102"
                  },
                  {
                      text: "河东区",
                      value: "11513103"
                  },
                  {
                      text: "沂南县",
                      value: "11513201"
                  },
                  {
                      text: "郯城县",
                      value: "11513202"
                  },
                  {
                      text: "沂水县",
                      value: "11513203"
                  },
                  {
                      text: "兰陵县",
                      value: "11513204"
                  },
                  {
                      text: "费县",
                      value: "11513205"
                  },
                  {
                      text: "平邑县",
                      value: "11513206"
                  },
                  {
                      text: "莒南县",
                      value: "11513207"
                  },
                  {
                      text: "蒙阴县",
                      value: "11513208"
                  },
                  {
                      text: "临沭县",
                      value: "11513209"
                  }
              ],
              text: "临沂市",
              value: "11513000"
          },
          {
              children: [
                  {
                      text: "德城区",
                      value: "11514101"
                  },
                  {
                      text: "陵城区",
                      value: "11514102"
                  },
                  {
                      text: "宁津县",
                      value: "11514201"
                  },
                  {
                      text: "庆云县",
                      value: "11514202"
                  },
                  {
                      text: "临邑县",
                      value: "11514203"
                  },
                  {
                      text: "齐河县",
                      value: "11514204"
                  },
                  {
                      text: "平原县",
                      value: "11514205"
                  },
                  {
                      text: "夏津县",
                      value: "11514206"
                  },
                  {
                      text: "武城县",
                      value: "11514207"
                  },
                  {
                      text: "乐陵市",
                      value: "11514301"
                  },
                  {
                      text: "禹城市",
                      value: "11514302"
                  }
              ],
              text: "德州市",
              value: "11514000"
          },
          {
              children: [
                  {
                      text: "东昌府区",
                      value: "11515101"
                  },
                  {
                      text: "阳谷县",
                      value: "11515201"
                  },
                  {
                      text: "莘县",
                      value: "11515202"
                  },
                  {
                      text: "茌平区",
                      value: "11515203"
                  },
                  {
                      text: "东阿县",
                      value: "11515204"
                  },
                  {
                      text: "冠县",
                      value: "11515205"
                  },
                  {
                      text: "高唐县",
                      value: "11515206"
                  },
                  {
                      text: "临清市",
                      value: "11515301"
                  }
              ],
              text: "聊城市",
              value: "11515000"
          },
          {
              children: [
                  {
                      text: "滨城区",
                      value: "11516101"
                  },
                  {
                      text: "惠民县",
                      value: "11516201"
                  },
                  {
                      text: "阳信县",
                      value: "11516202"
                  },
                  {
                      text: "无棣县",
                      value: "11516203"
                  },
                  {
                      text: "沾化区",
                      value: "11516204"
                  },
                  {
                      text: "博兴县",
                      value: "11516205"
                  },
                  {
                      text: "邹平市",
                      value: "11516206"
                  }
              ],
              text: "滨州市",
              value: "11516000"
          },
          {
              children: [
                  {
                      text: "牡丹区",
                      value: "11517101"
                  },
                  {
                      text: "定陶区",
                      value: "11517102"
                  },
                  {
                      text: "曹县",
                      value: "11517201"
                  },
                  {
                      text: "单县",
                      value: "11517202"
                  },
                  {
                      text: "成武县",
                      value: "11517203"
                  },
                  {
                      text: "巨野县",
                      value: "11517204"
                  },
                  {
                      text: "郓城县",
                      value: "11517205"
                  },
                  {
                      text: "鄄城县",
                      value: "11517206"
                  },
                  {
                      text: "东明县",
                      value: "11517208"
                  }
              ],
              text: "菏泽市",
              value: "11517000"
          }
      ],
      text: "山东省",
      value: "11500000"
  },
  {
      children: [
          {
              children: [
                  {
                      text: "黄浦区",
                      value: "10901101"
                  },
                  {
                      text: "徐汇区",
                      value: "10901103"
                  },
                  {
                      text: "长宁区",
                      value: "10901104"
                  },
                  {
                      text: "静安区",
                      value: "10901105"
                  },
                  {
                      text: "普陀区",
                      value: "10901106"
                  },
                  {
                      text: "闸北区",
                      value: "10901107"
                  },
                  {
                      text: "虹口区",
                      value: "10901108"
                  },
                  {
                      text: "杨浦区",
                      value: "10901109"
                  },
                  {
                      text: "闵行区",
                      value: "10901110"
                  },
                  {
                      text: "宝山区",
                      value: "10901111"
                  },
                  {
                      text: "嘉定区",
                      value: "10901112"
                  },
                  {
                      text: "浦东新区",
                      value: "10901113"
                  },
                  {
                      text: "金山区",
                      value: "10901114"
                  },
                  {
                      text: "松江区",
                      value: "10901115"
                  },
                  {
                      text: "青浦区",
                      value: "10901116"
                  },
                  {
                      text: "奉贤区",
                      value: "10901118"
                  },
                  {
                      text: "崇明区",
                      value: "10901201"
                  }
              ],
              text: "上海市",
              value: "10000000"
          }
      ],
      text: "上海市",
      value: "10900000"
  },
  {
      children: [
          {
              children: [
                  {
                      text: "新城区",
                      value: "12701101"
                  },
                  {
                      text: "碑林区",
                      value: "12701102"
                  },
                  {
                      text: "莲湖区",
                      value: "12701103"
                  },
                  {
                      text: "灞桥区",
                      value: "12701104"
                  },
                  {
                      text: "未央区",
                      value: "12701105"
                  },
                  {
                      text: "雁塔区",
                      value: "12701106"
                  },
                  {
                      text: "阎良区",
                      value: "12701107"
                  },
                  {
                      text: "临潼区",
                      value: "12701108"
                  },
                  {
                      text: "长安区",
                      value: "12701109"
                  },
                  {
                      text: "蓝田县",
                      value: "12701201"
                  },
                  {
                      text: "周至县",
                      value: "12701202"
                  },
                  {
                      text: "户县",
                      value: "12701203"
                  },
                  {
                      text: "高陵区",
                      value: "12701204"
                  },
                  {
                      text: "鄠邑区",
                      value: "12701205"
                  }
              ],
              text: "西安市",
              value: "12701000"
          },
          {
              children: [
                  {
                      text: "王益区",
                      value: "12702101"
                  },
                  {
                      text: "印台区",
                      value: "12702102"
                  },
                  {
                      text: "耀州区",
                      value: "12702103"
                  },
                  {
                      text: "宜君县",
                      value: "12702201"
                  }
              ],
              text: "铜川市",
              value: "12702000"
          },
          {
              children: [
                  {
                      text: "渭滨区",
                      value: "12703101"
                  },
                  {
                      text: "金台区",
                      value: "12703102"
                  },
                  {
                      text: "陈仓区",
                      value: "12703103"
                  },
                  {
                      text: "凤翔县",
                      value: "12703201"
                  },
                  {
                      text: "岐山县",
                      value: "12703202"
                  },
                  {
                      text: "扶风县",
                      value: "12703203"
                  },
                  {
                      text: "眉县",
                      value: "12703204"
                  },
                  {
                      text: "陇县",
                      value: "12703205"
                  },
                  {
                      text: "千阳县",
                      value: "12703206"
                  },
                  {
                      text: "麟游县",
                      value: "12703207"
                  },
                  {
                      text: "凤县",
                      value: "12703208"
                  },
                  {
                      text: "太白县",
                      value: "12703209"
                  }
              ],
              text: "宝鸡市",
              value: "12703000"
          },
          {
              children: [
                  {
                      text: "秦都区",
                      value: "12704101"
                  },
                  {
                      text: "杨陵区",
                      value: "12704102"
                  },
                  {
                      text: "渭城区",
                      value: "12704103"
                  },
                  {
                      text: "三原县",
                      value: "12704201"
                  },
                  {
                      text: "泾阳县",
                      value: "12704202"
                  },
                  {
                      text: "乾县",
                      value: "12704203"
                  },
                  {
                      text: "礼泉县",
                      value: "12704204"
                  },
                  {
                      text: "永寿县",
                      value: "12704205"
                  },
                  {
                      text: "彬州市",
                      value: "12704206"
                  },
                  {
                      text: "长武县",
                      value: "12704207"
                  },
                  {
                      text: "旬邑县",
                      value: "12704208"
                  },
                  {
                      text: "淳化县",
                      value: "12704209"
                  },
                  {
                      text: "武功县",
                      value: "12704210"
                  },
                  {
                      text: "兴平市",
                      value: "12704301"
                  },
                  {
                      text: "西咸新区",
                      value: "12704302"
                  }
              ],
              text: "咸阳市",
              value: "12704000"
          },
          {
              children: [
                  {
                      text: "临渭区",
                      value: "12705101"
                  },
                  {
                      text: "华州区",
                      value: "12705102"
                  },
                  {
                      text: "华县",
                      value: "12705201"
                  },
                  {
                      text: "潼关县",
                      value: "12705202"
                  },
                  {
                      text: "大荔县",
                      value: "12705203"
                  },
                  {
                      text: "合阳县",
                      value: "12705204"
                  },
                  {
                      text: "澄城县",
                      value: "12705205"
                  },
                  {
                      text: "蒲城县",
                      value: "12705206"
                  },
                  {
                      text: "白水县",
                      value: "12705207"
                  },
                  {
                      text: "富平县",
                      value: "12705208"
                  },
                  {
                      text: "韩城市",
                      value: "12705301"
                  },
                  {
                      text: "华阴市",
                      value: "12705302"
                  }
              ],
              text: "渭南市",
              value: "12705000"
          },
          {
              children: [
                  {
                      text: "宝塔区",
                      value: "12706101"
                  },
                  {
                      text: "延长县",
                      value: "12706201"
                  },
                  {
                      text: "延川县",
                      value: "12706202"
                  },
                  {
                      text: "子长市",
                      value: "12706203"
                  },
                  {
                      text: "安塞县",
                      value: "12706204"
                  },
                  {
                      text: "志丹县",
                      value: "12706205"
                  },
                  {
                      text: "吴起县",
                      value: "12706206"
                  },
                  {
                      text: "甘泉县",
                      value: "12706207"
                  },
                  {
                      text: "富县",
                      value: "12706208"
                  },
                  {
                      text: "洛川县",
                      value: "12706209"
                  },
                  {
                      text: "宜川县",
                      value: "12706210"
                  },
                  {
                      text: "黄龙县",
                      value: "12706211"
                  },
                  {
                      text: "黄陵县",
                      value: "12706212"
                  }
              ],
              text: "延安市",
              value: "12706000"
          },
          {
              children: [
                  {
                      text: "汉台区",
                      value: "12707101"
                  },
                  {
                      text: "南郑县",
                      value: "12707201"
                  },
                  {
                      text: "城固县",
                      value: "12707202"
                  },
                  {
                      text: "洋县",
                      value: "12707203"
                  },
                  {
                      text: "西乡县",
                      value: "12707204"
                  },
                  {
                      text: "勉县",
                      value: "12707205"
                  },
                  {
                      text: "宁强县",
                      value: "12707206"
                  },
                  {
                      text: "略阳县",
                      value: "12707207"
                  },
                  {
                      text: "镇巴县",
                      value: "12707208"
                  },
                  {
                      text: "留坝县",
                      value: "12707209"
                  },
                  {
                      text: "佛坪县",
                      value: "12707210"
                  }
              ],
              text: "汉中市",
              value: "12707000"
          },
          {
              children: [
                  {
                      text: "榆阳区",
                      value: "12708101"
                  },
                  {
                      text: "横山区",
                      value: "12708102"
                  },
                  {
                      text: "神木市",
                      value: "12708201"
                  },
                  {
                      text: "府谷县",
                      value: "12708202"
                  },
                  {
                      text: "靖边县",
                      value: "12708204"
                  },
                  {
                      text: "定边县",
                      value: "12708205"
                  },
                  {
                      text: "绥德县",
                      value: "12708206"
                  },
                  {
                      text: "米脂县",
                      value: "12708207"
                  },
                  {
                      text: "佳县",
                      value: "12708208"
                  },
                  {
                      text: "吴堡县",
                      value: "12708209"
                  },
                  {
                      text: "清涧县",
                      value: "12708210"
                  },
                  {
                      text: "子洲县",
                      value: "12708211"
                  }
              ],
              text: "榆林市",
              value: "12708000"
          },
          {
              children: [
                  {
                      text: "汉滨区",
                      value: "12709101"
                  },
                  {
                      text: "汉阴县",
                      value: "12709201"
                  },
                  {
                      text: "石泉县",
                      value: "12709202"
                  },
                  {
                      text: "宁陕县",
                      value: "12709203"
                  },
                  {
                      text: "紫阳县",
                      value: "12709204"
                  },
                  {
                      text: "岚皋县",
                      value: "12709205"
                  },
                  {
                      text: "平利县",
                      value: "12709206"
                  },
                  {
                      text: "镇坪县",
                      value: "12709207"
                  },
                  {
                      text: "旬阳县",
                      value: "12709208"
                  },
                  {
                      text: "白河县",
                      value: "12709209"
                  }
              ],
              text: "安康市",
              value: "12709000"
          },
          {
              children: [
                  {
                      text: "商州区",
                      value: "12710101"
                  },
                  {
                      text: "洛南县",
                      value: "12710201"
                  },
                  {
                      text: "丹凤县",
                      value: "12710202"
                  },
                  {
                      text: "商南县",
                      value: "12710203"
                  },
                  {
                      text: "山阳县",
                      value: "12710204"
                  },
                  {
                      text: "镇安县",
                      value: "12710205"
                  },
                  {
                      text: "柞水县",
                      value: "12710206"
                  }
              ],
              text: "商洛市",
              value: "12710000"
          }
      ],
      text: "陕西省",
      value: "12700000"
  },
  {
      children: [
          {
              children: [
                  {
                      text: "小店区",
                      value: "10401101"
                  },
                  {
                      text: "迎泽区",
                      value: "10401102"
                  },
                  {
                      text: "杏花岭区",
                      value: "10401103"
                  },
                  {
                      text: "尖草坪区",
                      value: "10401104"
                  },
                  {
                      text: "万柏林区",
                      value: "10401105"
                  },
                  {
                      text: "晋源区",
                      value: "10401106"
                  },
                  {
                      text: "清徐县",
                      value: "10401201"
                  },
                  {
                      text: "阳曲县",
                      value: "10401202"
                  },
                  {
                      text: "娄烦县",
                      value: "10401203"
                  },
                  {
                      text: "古交市",
                      value: "10401301"
                  }
              ],
              text: "太原市",
              value: "10401000"
          },
          {
              children: [
                  {
                      text: "城区",
                      value: "10402101"
                  },
                  {
                      text: "矿区",
                      value: "10402102"
                  },
                  {
                      text: "南郊区",
                      value: "10402103"
                  },
                  {
                      text: "新荣区",
                      value: "10402104"
                  },
                  {
                      text: "平城区",
                      value: "10402105"
                  },
                  {
                      text: "阳高县",
                      value: "10402201"
                  },
                  {
                      text: "天镇县",
                      value: "10402202"
                  },
                  {
                      text: "广灵县",
                      value: "10402203"
                  },
                  {
                      text: "灵丘县",
                      value: "10402204"
                  },
                  {
                      text: "浑源县",
                      value: "10402205"
                  },
                  {
                      text: "左云县",
                      value: "10402206"
                  },
                  {
                      text: "大同县",
                      value: "10402207"
                  },
                  {
                      text: "云州区",
                      value: "10402208"
                  },
                  {
                      text: "云冈区",
                      value: "10402210"
                  }
              ],
              text: "大同市",
              value: "10402000"
          },
          {
              children: [
                  {
                      text: "城区",
                      value: "10403101"
                  },
                  {
                      text: "矿区",
                      value: "10403102"
                  },
                  {
                      text: "郊区",
                      value: "10403103"
                  },
                  {
                      text: "平定县",
                      value: "10403201"
                  },
                  {
                      text: "盂县",
                      value: "10403202"
                  }
              ],
              text: "阳泉市",
              value: "10403000"
          },
          {
              children: [
                  {
                      text: "城区",
                      value: "10404101"
                  },
                  {
                      text: "郊区",
                      value: "10404102"
                  },
                  {
                      text: "长治县",
                      value: "10404201"
                  },
                  {
                      text: "襄垣县",
                      value: "10404202"
                  },
                  {
                      text: "屯留县",
                      value: "10404203"
                  },
                  {
                      text: "平顺县",
                      value: "10404204"
                  },
                  {
                      text: "黎城县",
                      value: "10404205"
                  },
                  {
                      text: "壶关县",
                      value: "10404206"
                  },
                  {
                      text: "长子县",
                      value: "10404207"
                  },
                  {
                      text: "武乡县",
                      value: "10404208"
                  },
                  {
                      text: "沁县",
                      value: "10404209"
                  },
                  {
                      text: "沁源县",
                      value: "10404210"
                  },
                  {
                      text: "潞城区",
                      value: "10404211"
                  },
                  {
                      text: "上党区",
                      value: "10404212"
                  },
                  {
                      text: "潞州区",
                      value: "10404213"
                  }
              ],
              text: "长治市",
              value: "10404000"
          },
          {
              children: [
                  {
                      text: "城区",
                      value: "10405001"
                  },
                  {
                      text: "沁水县",
                      value: "10405201"
                  },
                  {
                      text: "阳城县",
                      value: "10405202"
                  },
                  {
                      text: "陵川县",
                      value: "10405203"
                  },
                  {
                      text: "泽州县",
                      value: "10405204"
                  },
                  {
                      text: "高平市",
                      value: "10405301"
                  }
              ],
              text: "晋城市",
              value: "10405000"
          },
          {
              children: [
                  {
                      text: "朔城区",
                      value: "10406101"
                  },
                  {
                      text: "平鲁区",
                      value: "10406102"
                  },
                  {
                      text: "山阴县",
                      value: "10406201"
                  },
                  {
                      text: "应县",
                      value: "10406202"
                  },
                  {
                      text: "右玉县",
                      value: "10406203"
                  },
                  {
                      text: "怀仁市",
                      value: "10406204"
                  }
              ],
              text: "朔州市",
              value: "10406000"
          },
          {
              children: [
                  {
                      text: "榆次区",
                      value: "10407101"
                  },
                  {
                      text: "榆社县",
                      value: "10407201"
                  },
                  {
                      text: "左权县",
                      value: "10407202"
                  },
                  {
                      text: "和顺县",
                      value: "10407203"
                  },
                  {
                      text: "昔阳县",
                      value: "10407204"
                  },
                  {
                      text: "寿阳县",
                      value: "10407205"
                  },
                  {
                      text: "太谷县",
                      value: "10407206"
                  },
                  {
                      text: "祁县",
                      value: "10407207"
                  },
                  {
                      text: "平遥县",
                      value: "10407208"
                  },
                  {
                      text: "灵石县",
                      value: "10407209"
                  },
                  {
                      text: "介休市",
                      value: "10407301"
                  }
              ],
              text: "晋中市",
              value: "10407000"
          },
          {
              children: [
                  {
                      text: "盐湖区",
                      value: "10408101"
                  },
                  {
                      text: "临猗县",
                      value: "10408201"
                  },
                  {
                      text: "万荣县",
                      value: "10408202"
                  },
                  {
                      text: "闻喜县",
                      value: "10408203"
                  },
                  {
                      text: "稷山县",
                      value: "10408204"
                  },
                  {
                      text: "新绛县",
                      value: "10408205"
                  },
                  {
                      text: "绛县",
                      value: "10408206"
                  },
                  {
                      text: "垣曲县",
                      value: "10408207"
                  },
                  {
                      text: "夏县",
                      value: "10408208"
                  },
                  {
                      text: "平陆县",
                      value: "10408209"
                  },
                  {
                      text: "芮城县",
                      value: "10408210"
                  },
                  {
                      text: "永济市",
                      value: "10408301"
                  },
                  {
                      text: "河津市",
                      value: "10408302"
                  }
              ],
              text: "运城市",
              value: "10408000"
          },
          {
              children: [
                  {
                      text: "忻府区",
                      value: "10409101"
                  },
                  {
                      text: "定襄县",
                      value: "10409201"
                  },
                  {
                      text: "五台县",
                      value: "10409202"
                  },
                  {
                      text: "代县",
                      value: "10409203"
                  },
                  {
                      text: "繁峙县",
                      value: "10409204"
                  },
                  {
                      text: "宁武县",
                      value: "10409205"
                  },
                  {
                      text: "静乐县",
                      value: "10409206"
                  },
                  {
                      text: "神池县",
                      value: "10409207"
                  },
                  {
                      text: "五寨县",
                      value: "10409208"
                  },
                  {
                      text: "岢岚县",
                      value: "10409209"
                  },
                  {
                      text: "河曲县",
                      value: "10409210"
                  },
                  {
                      text: "保德县",
                      value: "10409211"
                  },
                  {
                      text: "偏关县",
                      value: "10409212"
                  },
                  {
                      text: "原平市",
                      value: "10409301"
                  }
              ],
              text: "忻州市",
              value: "10409000"
          },
          {
              children: [
                  {
                      text: "尧都区",
                      value: "10410101"
                  },
                  {
                      text: "曲沃县",
                      value: "10410201"
                  },
                  {
                      text: "翼城县",
                      value: "10410202"
                  },
                  {
                      text: "襄汾县",
                      value: "10410203"
                  },
                  {
                      text: "洪洞县",
                      value: "10410204"
                  },
                  {
                      text: "古县",
                      value: "10410205"
                  },
                  {
                      text: "安泽县",
                      value: "10410206"
                  },
                  {
                      text: "浮山县",
                      value: "10410207"
                  },
                  {
                      text: "吉县",
                      value: "10410208"
                  },
                  {
                      text: "乡宁县",
                      value: "10410209"
                  },
                  {
                      text: "大宁县",
                      value: "10410210"
                  },
                  {
                      text: "隰县",
                      value: "10410211"
                  },
                  {
                      text: "永和县",
                      value: "10410212"
                  },
                  {
                      text: "蒲县",
                      value: "10410213"
                  },
                  {
                      text: "汾西县",
                      value: "10410214"
                  },
                  {
                      text: "侯马市",
                      value: "10410301"
                  },
                  {
                      text: "霍州市",
                      value: "10410302"
                  }
              ],
              text: "临汾市",
              value: "10410000"
          },
          {
              children: [
                  {
                      text: "离石区",
                      value: "10411101"
                  },
                  {
                      text: "文水县",
                      value: "10411201"
                  },
                  {
                      text: "交城县",
                      value: "10411202"
                  },
                  {
                      text: "兴县",
                      value: "10411203"
                  },
                  {
                      text: "临县",
                      value: "10411204"
                  },
                  {
                      text: "柳林县",
                      value: "10411205"
                  },
                  {
                      text: "石楼县",
                      value: "10411206"
                  },
                  {
                      text: "岚县",
                      value: "10411207"
                  },
                  {
                      text: "方山县",
                      value: "10411208"
                  },
                  {
                      text: "中阳县",
                      value: "10411209"
                  },
                  {
                      text: "交口县",
                      value: "10411210"
                  },
                  {
                      text: "孝义市",
                      value: "10411301"
                  },
                  {
                      text: "汾阳市",
                      value: "10411302"
                  }
              ],
              text: "吕梁市",
              value: "10411000"
          }
      ],
      text: "山西省",
      value: "10400000"
  },
  {
      children: [
          {
              children: [
                  {
                      text: "和平区",
                      value: "10201101"
                  },
                  {
                      text: "河东区",
                      value: "10201102"
                  },
                  {
                      text: "河西区",
                      value: "10201103"
                  },
                  {
                      text: "南开区",
                      value: "10201104"
                  },
                  {
                      text: "河北区",
                      value: "10201105"
                  },
                  {
                      text: "红桥区",
                      value: "10201106"
                  },
                  {
                      text: "滨海新区",
                      value: "10201107"
                  },
                  {
                      text: "东丽区",
                      value: "10201110"
                  },
                  {
                      text: "西青区",
                      value: "10201111"
                  },
                  {
                      text: "津南区",
                      value: "10201112"
                  },
                  {
                      text: "北辰区",
                      value: "10201113"
                  },
                  {
                      text: "武清区",
                      value: "10201114"
                  },
                  {
                      text: "宝坻区",
                      value: "10201115"
                  },
                  {
                      text: "静海区",
                      value: "10201116"
                  },
                  {
                      text: "宁河区",
                      value: "10201117"
                  },
                  {
                      text: "蓟州区",
                      value: "10201203"
                  }
              ],
              text: "天津市",
              value: "10000000"
          }
      ],
      text: "天津市",
      value: "10200000"
  },
  {
      text: "台湾省",
      value: "13200000"
  },
  {
      children: [
          {
              children: [
                  {
                      text: "城关区",
                      value: "12601101"
                  },
                  {
                      text: "堆龙德庆区",
                      value: "12601102"
                  },
                  {
                      text: "林周县",
                      value: "12601201"
                  },
                  {
                      text: "当雄县",
                      value: "12601202"
                  },
                  {
                      text: "尼木县",
                      value: "12601203"
                  },
                  {
                      text: "曲水县",
                      value: "12601204"
                  },
                  {
                      text: "达孜县",
                      value: "12601206"
                  },
                  {
                      text: "墨竹工卡县",
                      value: "12601207"
                  }
              ],
              text: "拉萨市",
              value: "12601000"
          },
          {
              children: [
                  {
                      text: "卡若区",
                      value: "12602201"
                  },
                  {
                      text: "江达县",
                      value: "12602202"
                  },
                  {
                      text: "贡觉县",
                      value: "12602203"
                  },
                  {
                      text: "类乌齐县",
                      value: "12602204"
                  },
                  {
                      text: "丁青县",
                      value: "12602205"
                  },
                  {
                      text: "察雅县",
                      value: "12602206"
                  },
                  {
                      text: "八宿县",
                      value: "12602207"
                  },
                  {
                      text: "左贡县",
                      value: "12602208"
                  },
                  {
                      text: "芒康县",
                      value: "12602209"
                  },
                  {
                      text: "洛隆县",
                      value: "12602210"
                  },
                  {
                      text: "边坝县",
                      value: "12602211"
                  }
              ],
              text: "昌都市",
              value: "12602000"
          },
          {
              children: [
                  {
                      text: "乃东区",
                      value: "12603201"
                  },
                  {
                      text: "扎囊县",
                      value: "12603202"
                  },
                  {
                      text: "贡嘎县",
                      value: "12603203"
                  },
                  {
                      text: "桑日县",
                      value: "12603204"
                  },
                  {
                      text: "琼结县",
                      value: "12603205"
                  },
                  {
                      text: "曲松县",
                      value: "12603206"
                  },
                  {
                      text: "措美县",
                      value: "12603207"
                  },
                  {
                      text: "洛扎县",
                      value: "12603208"
                  },
                  {
                      text: "加查县",
                      value: "12603209"
                  },
                  {
                      text: "隆子县",
                      value: "12603210"
                  },
                  {
                      text: "错那县",
                      value: "12603211"
                  },
                  {
                      text: "浪卡子县",
                      value: "12603212"
                  }
              ],
              text: "山南市",
              value: "12603000"
          },
          {
              children: [
                  {
                      text: "南木林县",
                      value: "12604201"
                  },
                  {
                      text: "江孜县",
                      value: "12604202"
                  },
                  {
                      text: "定日县",
                      value: "12604203"
                  },
                  {
                      text: "萨迦县",
                      value: "12604204"
                  },
                  {
                      text: "拉孜县",
                      value: "12604205"
                  },
                  {
                      text: "昂仁县",
                      value: "12604206"
                  },
                  {
                      text: "谢通门县",
                      value: "12604207"
                  },
                  {
                      text: "白朗县",
                      value: "12604208"
                  },
                  {
                      text: "仁布县",
                      value: "12604209"
                  },
                  {
                      text: "康马县",
                      value: "12604210"
                  },
                  {
                      text: "定结县",
                      value: "12604211"
                  },
                  {
                      text: "仲巴县",
                      value: "12604212"
                  },
                  {
                      text: "亚东县",
                      value: "12604213"
                  },
                  {
                      text: "吉隆县",
                      value: "12604214"
                  },
                  {
                      text: "聂拉木县",
                      value: "12604215"
                  },
                  {
                      text: "萨嘎县",
                      value: "12604216"
                  },
                  {
                      text: "岗巴县",
                      value: "12604217"
                  },
                  {
                      text: "桑珠孜区",
                      value: "12604301"
                  }
              ],
              text: "日喀则市",
              value: "12604000"
          },
          {
              children: [
                  {
                      text: "那曲县",
                      value: "12605201"
                  },
                  {
                      text: "嘉黎县",
                      value: "12605202"
                  },
                  {
                      text: "比如县",
                      value: "12605203"
                  },
                  {
                      text: "聂荣县",
                      value: "12605204"
                  },
                  {
                      text: "安多县",
                      value: "12605205"
                  },
                  {
                      text: "申扎县",
                      value: "12605206"
                  },
                  {
                      text: "索县",
                      value: "12605207"
                  },
                  {
                      text: "班戈县",
                      value: "12605208"
                  },
                  {
                      text: "巴青县",
                      value: "12605209"
                  },
                  {
                      text: "尼玛县",
                      value: "12605210"
                  },
                  {
                      text: "双湖县",
                      value: "12605211"
                  },
                  {
                      text: "色尼区",
                      value: "12605212"
                  }
              ],
              text: "那曲市",
              value: "12605000"
          },
          {
              children: [
                  {
                      text: "普兰县",
                      value: "12606201"
                  },
                  {
                      text: "札达县",
                      value: "12606202"
                  },
                  {
                      text: "噶尔县",
                      value: "12606203"
                  },
                  {
                      text: "日土县",
                      value: "12606204"
                  },
                  {
                      text: "革吉县",
                      value: "12606205"
                  },
                  {
                      text: "改则县",
                      value: "12606206"
                  },
                  {
                      text: "措勤县",
                      value: "12606207"
                  }
              ],
              text: "阿里地区",
              value: "12606000"
          },
          {
              children: [
                  {
                      text: "巴宜区",
                      value: "12607201"
                  },
                  {
                      text: "工布江达县",
                      value: "12607202"
                  },
                  {
                      text: "米林县",
                      value: "12607203"
                  },
                  {
                      text: "墨脱县",
                      value: "12607204"
                  },
                  {
                      text: "波密县",
                      value: "12607205"
                  },
                  {
                      text: "察隅县",
                      value: "12607206"
                  },
                  {
                      text: "朗县",
                      value: "12607207"
                  }
              ],
              text: "林芝市",
              value: "12607000"
          }
      ],
      text: "西藏自治区",
      value: "12600000"
  },
  {
      children: [
          {
              text: "北区",
              value: "13301000"
          },
          {
              text: "大埔区",
              value: "13302000"
          },
          {
              text: "东区",
              value: "13303000"
          },
          {
              text: "观塘区",
              value: "13304000"
          },
          {
              text: "黄大仙区",
              value: "13305000"
          },
          {
              text: "九龙城区",
              value: "13306000"
          },
          {
              text: "葵青区",
              value: "13307000"
          },
          {
              text: "离岛区",
              value: "13308000"
          },
          {
              text: "南区",
              value: "13309000"
          },
          {
              text: "沙田区",
              value: "13310000"
          },
          {
              text: "深水埗区",
              value: "13311000"
          },
          {
              text: "屯门区",
              value: "13312000"
          },
          {
              text: "湾仔区",
              value: "13313000"
          },
          {
              text: "西贡区",
              value: "13314000"
          },
          {
              text: "油尖旺区",
              value: "13315000"
          },
          {
              text: "元朗区",
              value: "13316000"
          },
          {
              text: "中西区",
              value: "13317000"
          },
          {
              text: "荃湾区",
              value: "13318000"
          }
      ],
      text: "香港特别行政区",
      value: "13300000"
  },
  {
      children: [
          {
              children: [
                  {
                      text: "天山区",
                      value: "13101101"
                  },
                  {
                      text: "沙依巴克区",
                      value: "13101102"
                  },
                  {
                      text: "新市区",
                      value: "13101103"
                  },
                  {
                      text: "水磨沟区",
                      value: "13101104"
                  },
                  {
                      text: "头屯河区",
                      value: "13101105"
                  },
                  {
                      text: "达坂城区",
                      value: "13101106"
                  },
                  {
                      text: "米东区",
                      value: "13101107"
                  },
                  {
                      text: "乌鲁木齐县",
                      value: "13101201"
                  }
              ],
              text: "乌鲁木齐市",
              value: "13101000"
          },
          {
              children: [
                  {
                      text: "独山子区",
                      value: "13102101"
                  },
                  {
                      text: "克拉玛依区",
                      value: "13102102"
                  },
                  {
                      text: "白碱滩区",
                      value: "13102103"
                  },
                  {
                      text: "乌尔禾区",
                      value: "13102104"
                  }
              ],
              text: "克拉玛依市",
              value: "13102000"
          },
          {
              children: [
                  {
                      text: "鄯善县",
                      value: "13103201"
                  },
                  {
                      text: "托克逊县",
                      value: "13103202"
                  },
                  {
                      text: "高昌区",
                      value: "13103301"
                  }
              ],
              text: "吐鲁番市",
              value: "13103000"
          },
          {
              children: [
                  {
                      text: "巴里坤哈萨克自治县",
                      value: "13104201"
                  },
                  {
                      text: "伊吾县",
                      value: "13104202"
                  },
                  {
                      text: "伊州区",
                      value: "13104401"
                  }
              ],
              text: "哈密市",
              value: "13104000"
          },
          {
              children: [
                  {
                      text: "呼图壁县",
                      value: "13105201"
                  },
                  {
                      text: "玛纳斯县",
                      value: "13105202"
                  },
                  {
                      text: "奇台县",
                      value: "13105203"
                  },
                  {
                      text: "吉木萨尔县",
                      value: "13105204"
                  },
                  {
                      text: "木垒哈萨克自治县",
                      value: "13105205"
                  },
                  {
                      text: "昌吉市",
                      value: "13105301"
                  },
                  {
                      text: "阜康市",
                      value: "13105302"
                  }
              ],
              text: "昌吉回族自治州",
              value: "13105000"
          },
          {
              children: [
                  {
                      text: "精河县",
                      value: "13106201"
                  },
                  {
                      text: "温泉县",
                      value: "13106202"
                  },
                  {
                      text: "博乐市",
                      value: "13106301"
                  },
                  {
                      text: "阿拉山口市",
                      value: "13106302"
                  }
              ],
              text: "博尔塔拉蒙古自治州",
              value: "13106000"
          },
          {
              children: [
                  {
                      text: "轮台县",
                      value: "13107201"
                  },
                  {
                      text: "尉犁县",
                      value: "13107202"
                  },
                  {
                      text: "若羌县",
                      value: "13107203"
                  },
                  {
                      text: "且末县",
                      value: "13107204"
                  },
                  {
                      text: "焉耆回族自治县",
                      value: "13107205"
                  },
                  {
                      text: "和静县",
                      value: "13107206"
                  },
                  {
                      text: "和硕县",
                      value: "13107207"
                  },
                  {
                      text: "博湖县",
                      value: "13107208"
                  },
                  {
                      text: "库尔勒市",
                      value: "13107301"
                  }
              ],
              text: "巴音郭楞蒙古自治州",
              value: "13107000"
          },
          {
              children: [
                  {
                      text: "温宿县",
                      value: "13108201"
                  },
                  {
                      text: "库车市",
                      value: "13108202"
                  },
                  {
                      text: "沙雅县",
                      value: "13108203"
                  },
                  {
                      text: "新和县",
                      value: "13108204"
                  },
                  {
                      text: "拜城县",
                      value: "13108205"
                  },
                  {
                      text: "乌什县",
                      value: "13108206"
                  },
                  {
                      text: "阿瓦提县",
                      value: "13108207"
                  },
                  {
                      text: "柯坪县",
                      value: "13108208"
                  },
                  {
                      text: "阿克苏市",
                      value: "13108301"
                  }
              ],
              text: "阿克苏地区",
              value: "13108000"
          },
          {
              children: [
                  {
                      text: "阿克陶县",
                      value: "13109201"
                  },
                  {
                      text: "阿合奇县",
                      value: "13109202"
                  },
                  {
                      text: "乌恰县",
                      value: "13109203"
                  },
                  {
                      text: "阿图什市",
                      value: "13109301"
                  }
              ],
              text: "克孜勒苏柯尔克孜自治州",
              value: "13109000"
          },
          {
              children: [
                  {
                      text: "疏附县",
                      value: "13110201"
                  },
                  {
                      text: "疏勒县",
                      value: "13110202"
                  },
                  {
                      text: "英吉沙县",
                      value: "13110203"
                  },
                  {
                      text: "泽普县",
                      value: "13110204"
                  },
                  {
                      text: "莎车县",
                      value: "13110205"
                  },
                  {
                      text: "叶城县",
                      value: "13110206"
                  },
                  {
                      text: "麦盖提县",
                      value: "13110207"
                  },
                  {
                      text: "岳普湖县",
                      value: "13110208"
                  },
                  {
                      text: "伽师县",
                      value: "13110209"
                  },
                  {
                      text: "巴楚县",
                      value: "13110210"
                  },
                  {
                      text: "塔什库尔干塔吉克自治县",
                      value: "13110211"
                  },
                  {
                      text: "喀什市",
                      value: "13110301"
                  }
              ],
              text: "喀什地区",
              value: "13110000"
          },
          {
              children: [
                  {
                      text: "和田县",
                      value: "13111201"
                  },
                  {
                      text: "墨玉县",
                      value: "13111202"
                  },
                  {
                      text: "皮山县",
                      value: "13111203"
                  },
                  {
                      text: "洛浦县",
                      value: "13111204"
                  },
                  {
                      text: "策勒县",
                      value: "13111205"
                  },
                  {
                      text: "于田县",
                      value: "13111206"
                  },
                  {
                      text: "民丰县",
                      value: "13111207"
                  },
                  {
                      text: "和田市",
                      value: "13111301"
                  }
              ],
              text: "和田地区",
              value: "13111000"
          },
          {
              children: [
                  {
                      text: "伊宁县",
                      value: "13112201"
                  },
                  {
                      text: "察布查尔锡伯自治县",
                      value: "13112202"
                  },
                  {
                      text: "霍城县",
                      value: "13112203"
                  },
                  {
                      text: "巩留县",
                      value: "13112204"
                  },
                  {
                      text: "新源县",
                      value: "13112205"
                  },
                  {
                      text: "昭苏县",
                      value: "13112206"
                  },
                  {
                      text: "特克斯县",
                      value: "13112207"
                  },
                  {
                      text: "尼勒克县",
                      value: "13112208"
                  },
                  {
                      text: "伊宁市",
                      value: "13112301"
                  },
                  {
                      text: "奎屯市",
                      value: "13112302"
                  },
                  {
                      text: "霍尔果斯市",
                      value: "13112303"
                  }
              ],
              text: "伊犁哈萨克自治州",
              value: "13112000"
          },
          {
              children: [
                  {
                      text: "额敏县",
                      value: "13113201"
                  },
                  {
                      text: "沙湾县",
                      value: "13113202"
                  },
                  {
                      text: "托里县",
                      value: "13113203"
                  },
                  {
                      text: "裕民县",
                      value: "13113204"
                  },
                  {
                      text: "和布克赛尔蒙古自治县",
                      value: "13113205"
                  },
                  {
                      text: "塔城市",
                      value: "13113301"
                  },
                  {
                      text: "乌苏市",
                      value: "13113302"
                  }
              ],
              text: "塔城地区",
              value: "13113000"
          },
          {
              children: [
                  {
                      text: "布尔津县",
                      value: "13114201"
                  },
                  {
                      text: "富蕴县",
                      value: "13114202"
                  },
                  {
                      text: "福海县",
                      value: "13114203"
                  },
                  {
                      text: "哈巴河县",
                      value: "13114204"
                  },
                  {
                      text: "青河县",
                      value: "13114205"
                  },
                  {
                      text: "吉木乃县",
                      value: "13114206"
                  },
                  {
                      text: "阿勒泰市",
                      value: "13114301"
                  }
              ],
              text: "阿勒泰地区",
              value: "13114000"
          },
          {
              text: "石河子市",
              value: "13115000"
          },
          {
              text: "阿拉尔市",
              value: "13116000"
          },
          {
              text: "图木舒克市",
              value: "13117000"
          },
          {
              text: "五家渠市",
              value: "13118000"
          },
          {
              text: "北屯市",
              value: "13119000"
          },
          {
              text: "铁门关市",
              value: "13120000"
          },
          {
              text: "双河市",
              value: "13121000"
          },
          {
              text: "可克达拉市",
              value: "13122000"
          },
          {
              text: "昆玉市",
              value: "13123000"
          }
      ],
      text: "新疆维吾尔自治区",
      value: "13100000"
  },
  {
      children: [
          {
              children: [
                  {
                      text: "五华区",
                      value: "12501101"
                  },
                  {
                      text: "盘龙区",
                      value: "12501102"
                  },
                  {
                      text: "官渡区",
                      value: "12501103"
                  },
                  {
                      text: "西山区",
                      value: "12501104"
                  },
                  {
                      text: "东川区",
                      value: "12501105"
                  },
                  {
                      text: "呈贡区",
                      value: "12501201"
                  },
                  {
                      text: "晋宁区",
                      value: "12501202"
                  },
                  {
                      text: "富民县",
                      value: "12501203"
                  },
                  {
                      text: "宜良县",
                      value: "12501204"
                  },
                  {
                      text: "石林彝族自治县",
                      value: "12501205"
                  },
                  {
                      text: "嵩明县",
                      value: "12501206"
                  },
                  {
                      text: "禄劝彝族苗族自治县",
                      value: "12501207"
                  },
                  {
                      text: "寻甸回族彝族自治县",
                      value: "12501208"
                  },
                  {
                      text: "安宁市",
                      value: "12501301"
                  }
              ],
              text: "昆明市",
              value: "12501000"
          },
          {
              children: [
                  {
                      text: "麒麟区",
                      value: "12502101"
                  },
                  {
                      text: "沾益区",
                      value: "12502102"
                  },
                  {
                      text: "马龙县",
                      value: "12502201"
                  },
                  {
                      text: "陆良县",
                      value: "12502202"
                  },
                  {
                      text: "师宗县",
                      value: "12502203"
                  },
                  {
                      text: "罗平县",
                      value: "12502204"
                  },
                  {
                      text: "富源县",
                      value: "12502205"
                  },
                  {
                      text: "会泽县",
                      value: "12502206"
                  },
                  {
                      text: "宣威市",
                      value: "12502301"
                  }
              ],
              text: "曲靖市",
              value: "12502000"
          },
          {
              children: [
                  {
                      text: "红塔区",
                      value: "12503101"
                  },
                  {
                      text: "江川区",
                      value: "12503102"
                  },
                  {
                      text: "江川县",
                      value: "12503201"
                  },
                  {
                      text: "澄江市",
                      value: "12503202"
                  },
                  {
                      text: "通海县",
                      value: "12503203"
                  },
                  {
                      text: "华宁县",
                      value: "12503204"
                  },
                  {
                      text: "易门县",
                      value: "12503205"
                  },
                  {
                      text: "峨山彝族自治县",
                      value: "12503206"
                  },
                  {
                      text: "新平彝族傣族自治县",
                      value: "12503207"
                  },
                  {
                      text: "元江哈尼族彝族傣族自治县",
                      value: "12503208"
                  }
              ],
              text: "玉溪市",
              value: "12503000"
          },
          {
              children: [
                  {
                      text: "隆阳区",
                      value: "12504101"
                  },
                  {
                      text: "施甸县",
                      value: "12504201"
                  },
                  {
                      text: "腾冲市",
                      value: "12504202"
                  },
                  {
                      text: "龙陵县",
                      value: "12504203"
                  },
                  {
                      text: "昌宁县",
                      value: "12504204"
                  }
              ],
              text: "保山市",
              value: "12504000"
          },
          {
              children: [
                  {
                      text: "昭阳区",
                      value: "12505101"
                  },
                  {
                      text: "鲁甸县",
                      value: "12505201"
                  },
                  {
                      text: "巧家县",
                      value: "12505202"
                  },
                  {
                      text: "盐津县",
                      value: "12505203"
                  },
                  {
                      text: "大关县",
                      value: "12505204"
                  },
                  {
                      text: "永善县",
                      value: "12505205"
                  },
                  {
                      text: "绥江县",
                      value: "12505206"
                  },
                  {
                      text: "镇雄县",
                      value: "12505207"
                  },
                  {
                      text: "彝良县",
                      value: "12505208"
                  },
                  {
                      text: "威信县",
                      value: "12505209"
                  },
                  {
                      text: "水富市",
                      value: "12505210"
                  }
              ],
              text: "昭通市",
              value: "12505000"
          },
          {
              children: [
                  {
                      text: "古城区",
                      value: "12506101"
                  },
                  {
                      text: "玉龙纳西族自治县",
                      value: "12506201"
                  },
                  {
                      text: "永胜县",
                      value: "12506202"
                  },
                  {
                      text: "华坪县",
                      value: "12506203"
                  },
                  {
                      text: "宁蒗彝族自治县",
                      value: "12506204"
                  }
              ],
              text: "丽江市",
              value: "12506000"
          },
          {
              children: [
                  {
                      text: "思茅区",
                      value: "12507101"
                  },
                  {
                      text: "宁洱哈尼族彝族自治县",
                      value: "12507201"
                  },
                  {
                      text: "墨江哈尼族自治县",
                      value: "12507202"
                  },
                  {
                      text: "景东彝族自治县",
                      value: "12507203"
                  },
                  {
                      text: "景谷傣族彝族自治县",
                      value: "12507204"
                  },
                  {
                      text: "镇沅彝族哈尼族拉祜族自治县",
                      value: "12507205"
                  },
                  {
                      text: "江城哈尼族彝族自治县",
                      value: "12507206"
                  },
                  {
                      text: "孟连傣族拉祜族佤族自治县",
                      value: "12507207"
                  },
                  {
                      text: "澜沧拉祜族自治县",
                      value: "12507208"
                  },
                  {
                      text: "西盟佤族自治县",
                      value: "12507209"
                  }
              ],
              text: "普洱市",
              value: "12507000"
          },
          {
              children: [
                  {
                      text: "临翔区",
                      value: "12508101"
                  },
                  {
                      text: "凤庆县",
                      value: "12508201"
                  },
                  {
                      text: "云县",
                      value: "12508202"
                  },
                  {
                      text: "永德县",
                      value: "12508203"
                  },
                  {
                      text: "镇康县",
                      value: "12508204"
                  },
                  {
                      text: "双江拉祜族佤族布朗族傣族自治县",
                      value: "12508205"
                  },
                  {
                      text: "耿马傣族佤族自治县",
                      value: "12508206"
                  },
                  {
                      text: "沧源佤族自治县",
                      value: "12508207"
                  }
              ],
              text: "临沧市",
              value: "12508000"
          },
          {
              children: [
                  {
                      text: "双柏县",
                      value: "12509201"
                  },
                  {
                      text: "牟定县",
                      value: "12509202"
                  },
                  {
                      text: "南华县",
                      value: "12509203"
                  },
                  {
                      text: "姚安县",
                      value: "12509204"
                  },
                  {
                      text: "大姚县",
                      value: "12509205"
                  },
                  {
                      text: "永仁县",
                      value: "12509206"
                  },
                  {
                      text: "元谋县",
                      value: "12509207"
                  },
                  {
                      text: "武定县",
                      value: "12509208"
                  },
                  {
                      text: "禄丰县",
                      value: "12509209"
                  },
                  {
                      text: "楚雄市",
                      value: "12509301"
                  }
              ],
              text: "楚雄彝族自治州",
              value: "12509000"
          },
          {
              children: [
                  {
                      text: "蒙自市",
                      value: "12510201"
                  },
                  {
                      text: "屏边苗族自治县",
                      value: "12510202"
                  },
                  {
                      text: "建水县",
                      value: "12510203"
                  },
                  {
                      text: "石屏县",
                      value: "12510204"
                  },
                  {
                      text: "弥勒市",
                      value: "12510205"
                  },
                  {
                      text: "泸西县",
                      value: "12510206"
                  },
                  {
                      text: "元阳县",
                      value: "12510207"
                  },
                  {
                      text: "红河县",
                      value: "12510208"
                  },
                  {
                      text: "金平苗族瑶族傣族自治县",
                      value: "12510209"
                  },
                  {
                      text: "绿春县",
                      value: "12510210"
                  },
                  {
                      text: "河口瑶族自治县",
                      value: "12510211"
                  },
                  {
                      text: "个旧市",
                      value: "12510301"
                  },
                  {
                      text: "开远市",
                      value: "12510302"
                  }
              ],
              text: "红河哈尼族彝族自治州",
              value: "12510000"
          },
          {
              children: [
                  {
                      text: "文山市",
                      value: "12511201"
                  },
                  {
                      text: "砚山县",
                      value: "12511202"
                  },
                  {
                      text: "西畴县",
                      value: "12511203"
                  },
                  {
                      text: "麻栗坡县",
                      value: "12511204"
                  },
                  {
                      text: "马关县",
                      value: "12511205"
                  },
                  {
                      text: "丘北县",
                      value: "12511206"
                  },
                  {
                      text: "广南县",
                      value: "12511207"
                  },
                  {
                      text: "富宁县",
                      value: "12511208"
                  }
              ],
              text: "文山壮族苗族自治州",
              value: "12511000"
          },
          {
              children: [
                  {
                      text: "勐海县",
                      value: "12512201"
                  },
                  {
                      text: "勐腊县",
                      value: "12512202"
                  },
                  {
                      text: "景洪市",
                      value: "12512301"
                  }
              ],
              text: "西双版纳傣族自治州",
              value: "12512000"
          },
          {
              children: [
                  {
                      text: "漾濞彝族自治县",
                      value: "12513201"
                  },
                  {
                      text: "祥云县",
                      value: "12513202"
                  },
                  {
                      text: "宾川县",
                      value: "12513203"
                  },
                  {
                      text: "弥渡县",
                      value: "12513204"
                  },
                  {
                      text: "南涧彝族自治县",
                      value: "12513205"
                  },
                  {
                      text: "巍山彝族回族自治县",
                      value: "12513206"
                  },
                  {
                      text: "永平县",
                      value: "12513207"
                  },
                  {
                      text: "云龙县",
                      value: "12513208"
                  },
                  {
                      text: "洱源县",
                      value: "12513209"
                  },
                  {
                      text: "剑川县",
                      value: "12513210"
                  },
                  {
                      text: "鹤庆县",
                      value: "12513211"
                  },
                  {
                      text: "大理市",
                      value: "12513301"
                  }
              ],
              text: "大理白族自治州",
              value: "12513000"
          },
          {
              children: [
                  {
                      text: "梁河县",
                      value: "12514201"
                  },
                  {
                      text: "盈江县",
                      value: "12514202"
                  },
                  {
                      text: "陇川县",
                      value: "12514203"
                  },
                  {
                      text: "瑞丽市",
                      value: "12514301"
                  },
                  {
                      text: "芒市",
                      value: "12514302"
                  }
              ],
              text: "德宏傣族景颇族自治州",
              value: "12514000"
          },
          {
              children: [
                  {
                      text: "泸水市",
                      value: "12515201"
                  },
                  {
                      text: "福贡县",
                      value: "12515202"
                  },
                  {
                      text: "贡山独龙族怒族自治县",
                      value: "12515203"
                  },
                  {
                      text: "兰坪白族普米族自治县",
                      value: "12515204"
                  }
              ],
              text: "怒江傈僳族自治州",
              value: "12515000"
          },
          {
              children: [
                  {
                      text: "香格里拉市",
                      value: "12516201"
                  },
                  {
                      text: "德钦县",
                      value: "12516202"
                  },
                  {
                      text: "维西傈僳族自治县",
                      value: "12516203"
                  }
              ],
              text: "迪庆藏族自治州",
              value: "12516000"
          }
      ],
      text: "云南省",
      value: "12500000"
  },
  {
      children: [
          {
              children: [
                  {
                      text: "上城区",
                      value: "11101101"
                  },
                  {
                      text: "下城区",
                      value: "11101102"
                  },
                  {
                      text: "江干区",
                      value: "11101103"
                  },
                  {
                      text: "拱墅区",
                      value: "11101104"
                  },
                  {
                      text: "西湖区",
                      value: "11101105"
                  },
                  {
                      text: "滨江区",
                      value: "11101106"
                  },
                  {
                      text: "萧山区",
                      value: "11101107"
                  },
                  {
                      text: "余杭区",
                      value: "11101108"
                  },
                  {
                      text: "桐庐县",
                      value: "11101201"
                  },
                  {
                      text: "淳安县",
                      value: "11101202"
                  },
                  {
                      text: "建德市",
                      value: "11101301"
                  },
                  {
                      text: "富阳区",
                      value: "11101302"
                  },
                  {
                      text: "临安区",
                      value: "11101303"
                  }
              ],
              text: "杭州市",
              value: "11101000"
          },
          {
              children: [
                  {
                      text: "海曙区",
                      value: "11102101"
                  },
                  {
                      text: "江东区",
                      value: "11102102"
                  },
                  {
                      text: "江北区",
                      value: "11102103"
                  },
                  {
                      text: "北仑区",
                      value: "11102104"
                  },
                  {
                      text: "镇海区",
                      value: "11102105"
                  },
                  {
                      text: "鄞州区",
                      value: "11102106"
                  },
                  {
                      text: "象山县",
                      value: "11102201"
                  },
                  {
                      text: "宁海县",
                      value: "11102202"
                  },
                  {
                      text: "余姚市",
                      value: "11102301"
                  },
                  {
                      text: "慈溪市",
                      value: "11102302"
                  },
                  {
                      text: "奉化市",
                      value: "11102303"
                  }
              ],
              text: "宁波市",
              value: "11102000"
          },
          {
              children: [
                  {
                      text: "鹿城区",
                      value: "11103101"
                  },
                  {
                      text: "龙湾区",
                      value: "11103102"
                  },
                  {
                      text: "瓯海区",
                      value: "11103103"
                  },
                  {
                      text: "洞头区",
                      value: "11103104"
                  },
                  {
                      text: "永嘉县",
                      value: "11103202"
                  },
                  {
                      text: "平阳县",
                      value: "11103203"
                  },
                  {
                      text: "苍南县",
                      value: "11103204"
                  },
                  {
                      text: "文成县",
                      value: "11103205"
                  },
                  {
                      text: "泰顺县",
                      value: "11103206"
                  },
                  {
                      text: "瑞安市",
                      value: "11103301"
                  },
                  {
                      text: "乐清市",
                      value: "11103302"
                  },
                  {
                      text: "龙港市",
                      value: "11103303"
                  }
              ],
              text: "温州市",
              value: "11103000"
          },
          {
              children: [
                  {
                      text: "南湖区",
                      value: "11104101"
                  },
                  {
                      text: "秀洲区",
                      value: "11104102"
                  },
                  {
                      text: "嘉善县",
                      value: "11104201"
                  },
                  {
                      text: "海盐县",
                      value: "11104202"
                  },
                  {
                      text: "海宁市",
                      value: "11104301"
                  },
                  {
                      text: "平湖市",
                      value: "11104302"
                  },
                  {
                      text: "桐乡市",
                      value: "11104303"
                  }
              ],
              text: "嘉兴市",
              value: "11104000"
          },
          {
              children: [
                  {
                      text: "吴兴区",
                      value: "11105101"
                  },
                  {
                      text: "南浔区",
                      value: "11105102"
                  },
                  {
                      text: "德清县",
                      value: "11105201"
                  },
                  {
                      text: "长兴县",
                      value: "11105202"
                  },
                  {
                      text: "安吉县",
                      value: "11105203"
                  }
              ],
              text: "湖州市",
              value: "11105000"
          },
          {
              children: [
                  {
                      text: "越城区",
                      value: "11106101"
                  },
                  {
                      text: "柯桥区",
                      value: "11106201"
                  },
                  {
                      text: "新昌县",
                      value: "11106202"
                  },
                  {
                      text: "诸暨市",
                      value: "11106301"
                  },
                  {
                      text: "上虞区",
                      value: "11106302"
                  },
                  {
                      text: "嵊州市",
                      value: "11106303"
                  }
              ],
              text: "绍兴市",
              value: "11106000"
          },
          {
              children: [
                  {
                      text: "婺城区",
                      value: "11107101"
                  },
                  {
                      text: "金东区",
                      value: "11107102"
                  },
                  {
                      text: "武义县",
                      value: "11107201"
                  },
                  {
                      text: "浦江县",
                      value: "11107202"
                  },
                  {
                      text: "磐安县",
                      value: "11107203"
                  },
                  {
                      text: "兰溪市",
                      value: "11107301"
                  },
                  {
                      text: "义乌市",
                      value: "11107302"
                  },
                  {
                      text: "东阳市",
                      value: "11107303"
                  },
                  {
                      text: "永康市",
                      value: "11107304"
                  }
              ],
              text: "金华市",
              value: "11107000"
          },
          {
              children: [
                  {
                      text: "柯城区",
                      value: "11108101"
                  },
                  {
                      text: "衢江区",
                      value: "11108102"
                  },
                  {
                      text: "常山县",
                      value: "11108201"
                  },
                  {
                      text: "开化县",
                      value: "11108202"
                  },
                  {
                      text: "龙游县",
                      value: "11108203"
                  },
                  {
                      text: "江山市",
                      value: "11108301"
                  }
              ],
              text: "衢州市",
              value: "11108000"
          },
          {
              children: [
                  {
                      text: "定海区",
                      value: "11109101"
                  },
                  {
                      text: "普陀区",
                      value: "11109102"
                  },
                  {
                      text: "岱山县",
                      value: "11109201"
                  },
                  {
                      text: "嵊泗县",
                      value: "11109202"
                  }
              ],
              text: "舟山市",
              value: "11109000"
          },
          {
              children: [
                  {
                      text: "椒江区",
                      value: "11110101"
                  },
                  {
                      text: "黄岩区",
                      value: "11110102"
                  },
                  {
                      text: "路桥区",
                      value: "11110103"
                  },
                  {
                      text: "玉环市",
                      value: "11110201"
                  },
                  {
                      text: "三门县",
                      value: "11110202"
                  },
                  {
                      text: "天台县",
                      value: "11110203"
                  },
                  {
                      text: "仙居县",
                      value: "11110204"
                  },
                  {
                      text: "温岭市",
                      value: "11110301"
                  },
                  {
                      text: "临海市",
                      value: "11110302"
                  }
              ],
              text: "台州市",
              value: "11110000"
          },
          {
              children: [
                  {
                      text: "莲都区",
                      value: "11112101"
                  },
                  {
                      text: "青田县",
                      value: "11112201"
                  },
                  {
                      text: "缙云县",
                      value: "11112202"
                  },
                  {
                      text: "遂昌县",
                      value: "11112203"
                  },
                  {
                      text: "松阳县",
                      value: "11112204"
                  },
                  {
                      text: "云和县",
                      value: "11112205"
                  },
                  {
                      text: "庆元县",
                      value: "11112206"
                  },
                  {
                      text: "景宁畲族自治县",
                      value: "11112207"
                  },
                  {
                      text: "龙泉市",
                      value: "11112301"
                  }
              ],
              text: "丽水市",
              value: "11112000"
          }
      ],
      text: "浙江省",
      value: "11100000"
  }
]
module.exports = thearea

